import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
//import * as configRefApi from '../api/configRef-api';

class HomeView extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){
      //configRefApi.getConfigRefList()
    }

    render(){
      return(
      <div></div>
      )
    }
}

export default HomeView;
