import React from 'react'

class Footer extends React.Component {

    render() {
    return (
      <div>
          <p className="font-footer-weight-color text-center">{"© 2017-2018 Boston Children's Hospital"}</p>
      </div>
      );
    }
}

export default Footer


// <nav className="nav navbar-nav navbar-collapse navbar-fixed-bottom">
//   <p className="font-footer-weight-color">{"© 2017-2018 Boston Children's Hospital v1.0"}</p>
// </nav>
