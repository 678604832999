import React from 'react'
import { Link, browserHistory, hashHistory } from 'react-router'
import BaseComponent from '../../components/common/BaseComponent';
import * as userApi from '../../api/user-api'


class Logout extends BaseComponent {

  componentDidMount(){
    userApi.logout()
    .then(()=>{
      showAlert("Logout Successful", "success");
      hashHistory.push('login')
    })
    .catch(()=>{
      showAlert("Logout Unsuccessful", "failure");
      hashHistory.push('login')
    })
  }

  render () {
      return (
        <div></div>
      )
  }

}

export default Logout
