import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import BasicField from '../components/common/BasicField';
import BasicFieldOnlyValidation from '../components/common/BasicFieldOnlyValidation';
import BasicMultiSelectField from '../components/common/BasicMultiSelectField';
import BasicSelectField from '../components/common/BasicSelectField';
import * as UserActions from '../actions/user-actions';
import * as configRefApi from '../api/configRef-api';
import UserListComponent from '../components/UserListComponent';

class UserView extends BaseComponent {

    constructor(props) {
        super(props)
        this.state = {
          showPermissions: false
         };
        this.cancelClicked = this.cancelClicked.bind(this);
        this.addClicked = this.addClicked.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.onEditSetState = this.onEditSetState.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
      //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
      //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.userAction_binded.getUserList()
    }

    onSubmit (formProps) {
      //console.log(formProps)
      this.props.userAction_binded.addOrUpdateUser(formProps,this.props.userPermissionsList)
      .then(()=>{
        hideAddForm()
        this.props.reset();
        this.props.userAction_binded.getUserList()
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
        console.log("Error submitting user!!", error);
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

    cancelClicked(e) {
      this.props.reset(); //VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
      hideAddForm();
    }

    addClicked(e) {
      this.setState({showPermissions: false})
      //this.props.reset();
      this.props.userAction_binded.resetUserInfo()
    }

    onEditSetState(user){
      if(user.permissions.length > 0){
        this.setState({showPermissions: true})
      }else{
        this.setState({showPermissions: false})
      }
    }

    createRoleListDropdown(roleList,type){
      var buffer =[];
      buffer.push(<option key={"rec"+type} value="">Select a Role</option>)
       roleList.map((rl,index) =>{
          buffer.push (<option key={index} value={rl.code}>{rl.typeName}</option>)
      })
      return buffer;
    }

    handleRoleChange(event) {
      if(event.target.value === "EDITOR"){
        this.setState({showPermissions: true})
      }else{
        this.setState({showPermissions: false})
      }
    }

    // createPermissionsMultiSelectDropdown(upList){
    //   var buffer =[];
    //    upList.map((upl,index) =>{
    //      var temp={}
    //      temp.typeName = upl.typeName
    //      temp.code = upl.code
    //      buffer.push (temp)
    //   })
    //   return buffer;
    // }

    createPermissionsCheckbox(upList){
        // return upList.map((upl,index) =>{
        //     return(
        //         <div className="checkbox" key={index}>
        //           <label>
        //               <Field name={upl.code} type="checkbox" component="input" disabled/> {upl.typeName}
        //           </label>
        //         </div>
        //     )
        // })

        var buffer =[];
        for(var i=0; i<upList.length; i++){
            var isDisabled = false
            //var isChecked = false
            if(i==0){
              isDisabled = true
              //isChecked = true
            }
            buffer.push(
                <div className="checkbox" key={i}>
                   <label>
                       <Field name={upList[i].code} type="checkbox" component="input" disabled={isDisabled}/> {upList[i].typeName}
                   </label>
                 </div>
            )
        }
        return buffer
    }

    render() {
      const myUserList = this.props.userList_binded;
      const handleSubmit = this.props.handleSubmit; //injected by reduxform

      const permissionsFields = true //this.state.showPermissions //hidden, Not Needed for
      ? <div className="form-group">
            <label className="col-md-3 control-label">Permissions</label>
            <div className="col-md-6">
                <div className="section">
                    {/*<Field name="permissions" component={BasicMultiSelectField} valueField="code" textField="typeName" placeholder="Select Permission(s)"
                    data={this.createPermissionsMultiSelectDropdown(this.props.userPermissionsList)}/>*/}
                     {this.createPermissionsCheckbox(this.props.userPermissionsList)}
                </div>
            </div>
        </div>
        : null

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                          User <small></small>
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm*/}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           User Information
                           <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                      </div>
                      <div className="panel-body">{/* panel-body*/}
                        <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                          <input type="hidden" name="userId" value=""/>
                          <Field name="fullName" type="text" component={BasicField} placeholder="Full Name" label="Full Name*" />
                          <Field name="email" type="text" component={BasicField} placeholder="example@example.com" label="Email*" />
                          <Field name="enterpriseId" type="text" component={BasicField} placeholder="CHxxxxx" label="EnterpriseId*" />

                          <div className="form-group form-bottom-padding hidden"> {/*hidden, Not Needed for*/}
                              <label className="col-md-3 control-label">Role*</label>
                              <div className="col-md-3">
                              <Field name="role" component={BasicSelectField} className="form-control" onChange={ this.handleRoleChange }>
                               {this.createRoleListDropdown(this.props.userRoleList,"rld1")}
                              </Field>
                              </div>
                          </div>

                          {permissionsFields}

                          <div className="form-group">
                             <div className="col-md-offset-3 col-md-9">
                               <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                               {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                               <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                             </div>
                           </div>
                        </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>
           </div>{/* AddForm*/}
          {/*ROW 2*/}

          {/*ROW 3*/}
          <UserListComponent  userList={myUserList}  userRoleList={this.props.userRoleList} userPermissionsList={this.props.userPermissionsList}
                addClicked={this.addClicked} onEditSetState={this.onEditSetState}/>
          {/*ROW 3*/}

      </div>
      )
    }
}

function validateUserForm(values){
  const errors = {};

    if(!values.fullName){
      errors.fullName = 'Please enter a name';
    }

    if(!values.email){
      errors.email = 'Please enter a email address';
    }
    else{
      var emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!emailRegEx.test(values.email)){
        errors.email = 'Please enter a valid email address';
      }
    }

    if(!values.enterpriseId){
      errors.enterpriseId = 'Please enter a valid Id';
    }

    // if(!values.role){
    //   errors.role = 'Please select a role';
    // }else if(values.role ==="B"){
    //   if(values.permissions && values.permissions.length === 0){
    //     errors.permissions = 'Please select a permission(s)';
    //   }
    // }

     if(!values.permissions){
       errors.permissions = 'Please select a permission(s)';
     }
     else if(values.permissions.length ===0){
       errors.permissions = 'Please select a permission(s)';
     }

    return errors;
}

function mapStateToProps(state){

    var user_binded = state.userState.user
    var initialValues={};
    var permList=[];

    var userRole = {}, userRoleList = [],userPermissions = {}, userPermissionsList = []
    if(state.configRefState.configRef != null){
      userRole = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "User Roles";
      });
      userRoleList = userRole[0].list;
    }

    if(state.configRefState.configRef != null){
      userPermissions = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "User Permissions";
      });
      userPermissionsList = userPermissions[0].list;
    }

    if(user_binded != null){

      initialValues.userId=user_binded._id
      initialValues.fullName=user_binded.fullName
      initialValues.enterpriseId=user_binded.enterpriseId
      initialValues.email=user_binded.email
      initialValues.role=user_binded.role
      if(user_binded.permissions != null && user_binded.permissions.length > 0){
        user_binded.permissions.map((up,index)=>{
              initialValues[up] = true
        })
      }

      // if(user_binded.permissions){
      //   user_binded.permissions.map((perm,index)=>{//[{typName: 'Grapes', code: '5' }]
      //     var userPerm={}
      //     var permName = userPermissionsList.filter(function( obj ) {
      //       return obj.code === perm;
      //     });
      //     userPerm.typName=permName[0].typeName;
      //     userPerm.code=perm
      //     permList.push(userPerm)
      //   })
      //   initialValues.permissions = permList
      // }
    }

    return{
      userList_binded: state.userState.userList,
      userRoleList : userRoleList,
      userPermissionsList:userPermissionsList,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function mapDispatchToProps(dispatch){
  return {
    userAction_binded: bindActionCreators(UserActions, dispatch)
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(BacteriaView);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'userForm',  // a unique name for the form
    validate: validateUserForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(UserView));
