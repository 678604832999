import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import { Link, browserHistory, hashHistory } from 'react-router';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import * as SMLineItemsActions from '../actions/smlineitems-actions';
import * as SMActions from '../actions/suscpetibilityMatrix-actions';
import _ from 'lodash';

class SMLineItemsListComponent extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){

    }

    backClicked(e) {
      //this.props.reset();
      hashHistory.push('susceptibilitymatrix')
    }

    populateForm (e,lineItem) {
      this.props.onEditSetState(lineItem)
      this.props.SMLineItemsActions_binded.loadLineItemInfo(lineItem)
    }

    deleteRecord(e,lineItem) {
      if(confirm('Are you sure you want to delete?')){
        this.props.SMLineItemsActions_binded.deleteSMLineItem(lineItem)
        .then(()=>{
          this.props.SMActions_binded.getSMById(this.props.SusMa._id)
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
          console.log("Error deleting susceptibility matrix line item!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    getResistanceValueName(resistanceValueCode, resistanceValueList){
      if(isNaN(resistanceValueCode)){
        var resVal = resistanceValueList.filter(function( obj ) {
          return obj.code === resistanceValueCode;
        });
        if(resVal[0]){
          return resVal[0].typeName;
        }else{
          console.log(resistanceValueCode)
          return '-';
        }
      }else{
        return resistanceValueCode;
      }
    }

    getCultureSourceName(cultureSource,cultureSourceList){
      var culSrc = cultureSourceList.filter(function( obj ) {
        return obj.code === cultureSource;
      });
      return culSrc[0].typeName;
    }

    getRecommendedName(recommendation,recommendedCategoryList){
      var recCat = recommendedCategoryList.filter(function( obj ) {
        return obj.code === recommendation;
      });
      return recCat[0].typeName;
    }

    createLineItemRows(myLineItemList,lineItemListName,editPermission){
      return myLineItemList && myLineItemList.map((lineItem,index) => {
        var buffer = []
        var header = <tr className="text-center smtable-separator"><td colSpan="9"><b>{lineItemListName}</b></td></tr>
        var lineItemData =   <tr key={index}>
           <td>{lineItem.bacteria.name}</td>
           <td>{lineItem.drug.name}</td>
           <td>{this.getCultureSourceName(lineItem.cultureSource,this.props.cultureSourceList)}</td>
           <td>{this.getResistanceValueName(lineItem.resistanceValue,this.props.resistanceCategoryList)}</td>
           <td>{this.getRecommendedName(lineItem.recommended,this.props.recommendedCategoryList)}</td>
           <td className="user-status">{this.getLineItemEditorInfo(lineItem)}</td>
           {editPermission
             ? <td className="col-md-2">
                  <input type="button" className="btn btn-Warning btn-space btn-xs" id="Edit" value="Edit" onClick={()=>{this.populateForm(this,lineItem)}}/>
                  <input type="button" className="btn btn-Danger btn-space btn-xs" id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,lineItem)}}/>
               </td>
             : null
           }
         </tr>

         if(index ===0){
           buffer.push(header)
         }
         buffer.push(lineItemData)

        return (buffer)

      })
    }

    getLineItemEditorInfo(lineItem){
      if(lineItem.updatedBy){
            return "Last edited by: " + lineItem.updatedBy.fullName +" on " + moment.utc(lineItem.updatedDt).local().format("MMM DD, YYYY H:mm A");
      }else{
            return "Created by: " + lineItem.createdBy.fullName+ " on " + moment.utc(lineItem.createdDt).local().format("MMM DD, YYYY H:mm A");
      }
    }

    render() {
      var lineItemList_positive = _.filter(this.props.SusMa.lineItems, {bacteria : {category : 'AEROBIC_GRAM_POS'}})
      var lineItemListName_positive =_.find(this.props.bacteriaCategoryList, {code : 'AEROBIC_GRAM_POS'});
      if(lineItemListName_positive){
        lineItemListName_positive=lineItemListName_positive.typeName
      }
      //This will sort first ascending by bacteria.name, and for objects that have the same value for property bacteria.name, will sort them descending by cultureSopurce.
      lineItemList_positive = _.orderBy(lineItemList_positive, [
          function (item) { return item.bacteria.name; },
          function (item) { return item.cultureSource; }
        ], ["asc", "desc"]);

      var lineItemList_negative= _.filter(this.props.SusMa.lineItems, {bacteria : {category : 'AEROBIC_GRAM_NEG'}})
      var lineItemListName_negative =_.find(this.props.bacteriaCategoryList, {code : 'AEROBIC_GRAM_NEG'});
      if(lineItemListName_negative){
        lineItemListName_negative=lineItemListName_negative.typeName
      }
      //This will sort first ascending by bacteria.name, and for objects that have the same value for property bacteria.name, will sort them descending by cultureSopurce.
      lineItemList_negative = _.orderBy(lineItemList_negative, [
          function (item) { return item.bacteria.name; },
          function (item) { return item.cultureSource; }
        ], ["asc", "desc"]);

        var lineItemList_anerobic= _.filter(this.props.SusMa.lineItems, {bacteria : {category : 'ANAEROBIC'}})
        var lineItemListName_anerobic =_.find(this.props.bacteriaCategoryList, {code : 'ANAEROBIC'});
        if(lineItemListName_anerobic){
          lineItemListName_anerobic=lineItemListName_anerobic.typeName
        }
        //This will sort first ascending by bacteria.name, and for objects that have the same value for property bacteria.name, will sort them descending by cultureSopurce.
        lineItemList_anerobic = _.orderBy(lineItemList_anerobic, [
            function (item) { return item.bacteria.name; },
            function (item) { return item.cultureSource; }
          ], ["asc", "desc"]);


      var editPermission = this.currentUserHasEditPermissions();

      var buttonAddBugDrugCombo = editPermission
            ? <input type="button" id="Add" className="btn btn-Info btn-space" value="Add Bug-Drug Combo" onClick={()=>{this.props.addClicked(this)}}/>
            : null

      return(

          <div>
          {/*ROW 3*/}
          <div className="row">
              <div className="col-md-12">

              <div className="panel panel-default"> {/* panel-default*/}
                  <div className="panel-heading">
                       Susceptibility Matrix Bug-Drug Combo List{buttonAddBugDrugCombo}
                       <input type="button" id="BackToSM" className="btn btn-Info btn-space" value="Back to Susceptibility Matrix" onClick={()=>{this.backClicked(this)}}/>
                       <input type="button" id="SMPreview" className="btn btn-Info btn-space" value="Preview Matrix"/>
                  </div>

                  <div className="panel-body">{/* panel-body*/}
                      <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover" id="dataTables-example">
                              <thead>
                                <tr>
                                  <th className="col-md-2">Bacteria</th>
                                  <th className="col-md-2">Drug</th>
                                  <th className="col-md-2">Culture Source</th>
                                  <th className="col-md-1">Resistance Value</th>
                                  <th className="col-md-1">Recommended</th>
                                  <th className="col-md-2">Editor</th>
                                  {editPermission
                                    ? <th></th>
                                    : null
                                  }
                                </tr>
                              </thead>
                              <tbody>
                              {this.createLineItemRows(lineItemList_negative,lineItemListName_negative,editPermission)}
                              {this.createLineItemRows(lineItemList_positive,lineItemListName_positive,editPermission)}
                              {this.createLineItemRows(lineItemList_anerobic,lineItemListName_anerobic,editPermission)}
                              </tbody>
                            </table>
                        </div>
                    </div>{/* panel-body*/}
                </div> {/* panel-default*/}
              </div>
          </div>
          {/*ROW 3*/}


      </div>
      )
    }
}

function mapDispatchToProps(dispatch){
  return {
    SMActions_binded: bindActionCreators(SMActions, dispatch),
    SMLineItemsActions_binded: bindActionCreators(SMLineItemsActions, dispatch),

  }
}

export default connect(null, mapDispatchToProps)(SMLineItemsListComponent);
