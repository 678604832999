import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import { Link, browserHistory, hashHistory } from 'react-router';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import * as DrugActions from '../actions/drug-actions';
import * as DrugDosageActions from '../actions/drugdosage-actions';
import { createDosingString } from '../components/common/Utility'
import _ from 'lodash';

class DrugDosageListComponent extends BaseComponent {

    constructor(props) {
        super(props)
        this.insertAndShiftUp = this.insertAndShiftUp.bind(this);
        this.insertAndShiftDown = this.insertAndShiftDown.bind(this);
    }

    componentDidMount(){

    }

    backClicked(e) {
      //this.props.reset();
      hashHistory.push('drugs')
    }

    publishClicked(e) {
      if(confirm('Are you sure you want to publish?')){
        this.props.drugDosageAction_binded.publishDosing(this.props.drugDosing._id)
        .then(()=>{
          hashHistory.push('drugs')
        })
      }
    }

    editPublishedDosing(e){
      if(confirm('Are you sure you want to edit? Editing will create a copy of published version for edits')){
        this.props.drugDosageAction_binded.editPublishedDosing(this.props.drugDosing._id)
        .then(()=>{
          this.props.drugDosageAction_binded.getDosingByDrugById(this.props.drug._id)
        })
      }
      hideAddForm();
    }

    populateForm (e,drugDosage) {
      this.props.onEditSetState(drugDosage)
      this.props.drugDosageAction_binded.loadDrugDosageInfo(drugDosage)
    }

    deleteRecord(e,drugDosage) {
      if(confirm('Are you sure you want to delete?')){
        this.props.drugDosageAction_binded.deleteDrugDosingItem(this.props.drugDosing._id,drugDosage)
        .then(()=>{
          this.props.drugDosageAction_binded.getDosingByDrugById(this.props.drug._id)
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
            console.log("Error deleting drug dosage!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    getAdminRouteName(adminRouteCode,adminRouteList){
      var adminRoute = adminRouteList.filter(function( obj ) {
        return obj.code === adminRouteCode;
      });
      return adminRoute[0].typeName;
    }

    getCostName(costLevelCode,costLevelList){
      var cost = costLevelList.filter(function( obj ) {
        return obj.code === costLevelCode;
      });
      return cost[0].typeName;
    }

    formatLastPublishedDateColumn(publishedDoc){
      if(publishedDoc.updatedDt){
        var dateTime = moment.utc(publishedDoc.updatedDt).local().format("MMM DD, YYYY H:mm A");
        return dateTime;
      }
      else{
        return "---"
      }
    }

    openLastPublished(){
      var url = '#/drugdosage/' + String(this.props.drug._id) + '?previewLastPublished=true'
      var win = window.open(url,'LastPublished','height=500,width=1250')
      win.location.reload()
    }

    getDosingEditorInfo(drugDosage){
      if(drugDosage.updatedBy){
            return "Last edited by: " + drugDosage.updatedBy.fullName +" on " + moment.utc(drugDosage.updatedDt).local().format("MMM DD, YYYY H:mm A");
      }else{
            return "Created by: " + drugDosage.createdBy.fullName+ " on " + moment.utc(drugDosage.createdDt).local().format("MMM DD, YYYY H:mm A");
      }
    }

    //shifts element in array to one position up
    insertAndShiftUp(dosingItemArr,dosingId,fromIndex) {
        var arrLen = parseInt(dosingItemArr.length);
        if(fromIndex > 0 && fromIndex <= arrLen-1){
          let toIndex = fromIndex-1;
          this.props.drugDosageAction_binded.saveDosingSortOrder(dosingId,fromIndex,toIndex)
          .then(()=>{
            this.props.drugDosageAction_binded.getDosingByDrugById(this.props.drug._id)
          })
          .catch((error)=>{
              console.log("Error ordering drug dosage!", error);
          })
        }
    }

    //shifts element in array to one position down
    insertAndShiftDown(dosingItemArr,dosingId,fromIndex) {
        var arrLen = parseInt(dosingItemArr.length);
        if(fromIndex >= 0 && fromIndex < arrLen-1){
          let toIndex = fromIndex+1;
          this.props.drugDosageAction_binded.saveDosingSortOrder(dosingId,fromIndex,toIndex)
          .then(()=>{
            this.props.drugDosageAction_binded.getDosingByDrugById(this.props.drug._id)
          })
          .catch((error)=>{
              console.log("Error ordering drug dosage!", error);
          })
        }
    }

    // //shifts element in array to one position up
    // insertAndShiftUp(dosingItemArr,dosingId,fromIndex) {
    //     var arrLen = parseInt(dosingItemArr.length);
    //     if(fromIndex > 0 && fromIndex <= arrLen-1){
    //       let toIndex = fromIndex-1;
    //       let cutOut = dosingItemArr.splice(fromIndex, 1) [0]; // cut the element at index 'fromIndex'
    //       dosingItemArr.splice(toIndex, 0, cutOut);       // insert it at index 'toIndex'
    //       this.props.drugDosageAction_binded.getDosingByNewSortOrder(this.props.drugDosing,dosingItemArr)
    // }
    //
    // //shifts element in array to one position down
    // insertAndShiftDown(dosingItemArr,dosingId,fromIndex) {
    //     var arrLen = parseInt(dosingItemArr.length);
    //     if(fromIndex >= 0 && fromIndex < arrLen-1){
    //       let toIndex = fromIndex+1;
    //       let cutOut = dosingItemArr.splice(fromIndex, 1) [0]; // cut the element at index 'fromIndex'
    //       dosingItemArr.splice(toIndex, 0, cutOut);       // insert it at index 'toIndex'
    //       this.props.drugDosageAction_binded.getDosingByNewSortOrder(this.props.drugDosing,dosingItemArr)
    //     }
    // }


    render() {
      if(!this.props.drugDosing){
        return(<div></div>)
      }
      var header=""
      var rowLevelButtons=" disable-dosing-elements"
      var myDrugDosageList = []
      if(this.props.previewLastPublished === 'true'){
          //myDrugDosageList = _.sortBy(this.props.lastPublishedDrugDosing.dosingItems, "routeOfAdministration")
          myDrugDosageList = this.props.lastPublishedDrugDosing.dosingItems
          header = <div>
                      <span>Dosing List</span>
                      <span className="pull-right bg-success">Published Date: {this.formatLastPublishedDateColumn(this.props.lastPublishedDrugDosing)}</span>
                  </div>
      }
      else
      {
          //myDrugDosageList = _.sortBy(this.props.drugDosing.dosingItems, "routeOfAdministration")
          myDrugDosageList = this.props.drugDosing.dosingItems

          var buttonPublish = this.currentUserHasPublishPermissions()
                ? <input type="button" id="Publish" className="btn btn-Success btn-space" value="Publish" onClick={()=>{this.publishClicked()}}/>
                : null

          var editPermission = this.currentUserHasEditPermissions();

          var buttonCreateNewDraft = editPermission
                ? <input type="button" id="EditPublished" className="btn btn-Info btn-space" value="Create New Draft" onClick={()=>{this.editPublishedDosing(this)}}/>
                : null

          var buttonAddDosing = editPermission
                ? <input type="button" id="Add" className="btn btn-Info btn-space" value="Add Dosing" onClick={()=>{this.props.addClicked(this)}}/>
                : null

          header = this.props.drugDosing.published
          ? <div>
              <span>Dosing List</span>
              {buttonCreateNewDraft}
              <input type="button" id="BackToDrugs" className="btn btn-Primary btn-space" value="Back to Drugs" onClick={()=>{this.backClicked(this)}}/>
              <span className="pull-right bg-success">Last Published: {this.formatLastPublishedDateColumn(this.props.lastPublishedDrugDosing)}</span>
            </div>
          : <div>
              <span>Dosing List</span>
              {buttonAddDosing}
              {buttonPublish}
              <input type="button" id="BackToDrugs" className="btn btn-Primary btn-space" value="Back to Drugs" onClick={()=>{this.backClicked(this)}}/>
              <div className="pull-right">
                <span className="bg-success">Last Published: {this.formatLastPublishedDateColumn(this.props.lastPublishedDrugDosing)}</span>
                <input type="button" id="View" className="btn btn-warning btn-space btn-xs" value="View" onClick={()=>{this.openLastPublished(this)}}/>
              </div>
           </div>

         rowLevelButtons = this.props.drugDosing.published
         ? " disable-dosing-elements"
         : ""
     }

      return(

          <div>
          {/*ROW 3*/}
          <div className="row">
              <div className="col-md-12">

              <div className="panel panel-default"> {/* panel-default*/}
                  <div className="panel-heading">
                       {header}
                  </div>

                  <div className="panel-body">{/* panel-body*/}
                      <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover" id="dataTables-example">
                              <thead>
                                <tr>
                                  <th className="col-md-1">Administration Route</th>
                                  <th className="col-md-1">Cost</th>
                                  <th className="col-md-5">Dosing</th>
                                  <th className="col-md-2">Dosing Editor</th>
                                  {
                                    editPermission
                                    ?  <th></th>
                                    : null
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    myDrugDosageList && myDrugDosageList.map((drugDosage,index) => {
                                    return(
                                      <tr key={index}>
                                        <td>{this.getAdminRouteName(drugDosage.routeOfAdministration,this.props.adminRouteList)}</td>
                                        <td>{this.getCostName(drugDosage.costLevel,this.props.costLevelList)}</td>
                                        <td>{createDosingString(drugDosage,'false',this.props.costLevelList,this.props.doseUnitList,
                                              this.props.ageUnitList,this.props.frequencyUnitList,this.props.durationList,this.props.durationUnitList,
                                              this.props.maxDoseUnitList)}</td>
                                        <td className="user-status">{this.getDosingEditorInfo(drugDosage)}</td>
                                        {
                                          editPermission
                                          ? <td className="col-md-2">
                                              {/*//Do JSON.parse(JSON.stringify()) so we dont mutate the data and state change will be reflected back on UI parent/child component*/}
                                              <a className={"updown-arrow" + rowLevelButtons} onClick={()=>{this.insertAndShiftUp(JSON.parse(JSON.stringify(myDrugDosageList)),this.props.drugDosing._id,index)}}><i className="fa fa-long-arrow-up"></i></a>
                                              <a className={"updown-arrow" + rowLevelButtons} onClick={()=>{this.insertAndShiftDown(JSON.parse(JSON.stringify(myDrugDosageList)),this.props.drugDosing._id,index)}}><i className="fa fa-long-arrow-down"></i></a>
                                              <input type="button" className={"btn btn-Warning btn-space btn-xs" + rowLevelButtons} id="Edit" value="Edit" onClick={()=>{this.populateForm(this,drugDosage)}}/>
                                              <input type="button" className={"btn btn-Danger btn-space btn-xs" + rowLevelButtons} id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,drugDosage)}}/>
                                            </td>
                                          : null
                                        }
                                      </tr>
                                      )
                                    })
                                  }
                              </tbody>
                            </table>
                        </div>
                    </div>{/* panel-body*/}
                </div> {/* panel-default*/}
              </div>
          </div>
          {/*ROW 3*/}


      </div>
      )
    }
}

function mapDispatchToProps(dispatch){
  return {
    drugAction_binded: bindActionCreators(DrugActions, dispatch),
    drugDosageAction_binded: bindActionCreators(DrugDosageActions, dispatch),

  }
}

export default connect(null, mapDispatchToProps)(DrugDosageListComponent);
