import * as ActionTypes from './action-types';
import * as alertApi from '../api/alert-api';

export function getAlertList() {
  return function(dispatch) {
      return alertApi.getAlertList()
      .then((alertList) => {
        dispatch({type: ActionTypes.GET_ALERTLIST_SUCCESS, alertList});
      })
      .catch(error => {
        throw(error);
      })
  }
}


export function loadAlertInfo(alert) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_ALERT_SUCCESS, alert});
  }
}

export function resetAlertInfo(faceupAlertCategoryList) {
  var resetSelectedAlert = {faceup:faceupAlertCategoryList[0].code}
  return function(dispatch){
    dispatch({type: ActionTypes.GET_ALERT_SUCCESS, alert:resetSelectedAlert});
  }
}

export function addOrUpdateAlert(formProps) {

    var alertInfo = {
      alertText : formProps.alertText,faceup:formProps.faceup,activationDate:formProps.activationDate, expirationDate:formProps.expirationDate, drug:formProps.drug
    }

    if(formProps.alertId != null){ //Update
        return function(dispatch) {
          return alertApi.updateAlert(alertInfo,formProps.alertId)
          .then(updatedAlert => {
            dispatch({type: ActionTypes.UPDATE_ALERT_SUCCESS, updatedAlert});
            showAlert("Alert updated successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
          })
        }
    }
    else //Add
    {
      return function(dispatch) {
        return alertApi.addAlert(alertInfo)
        .then(alert => {
          dispatch({type: ActionTypes.ADD_ALERT_SUCCESS, alert});
          showAlert("Alert created successfully!", "success");
        }).catch(error => {
          showAlert(error.error, "error");
          throw(error);
          //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
        })
      }
   }
}

export function deleteAlert(alert) {
  return function(dispatch) {
    return alertApi.deleteAlert(alert._id).then(res => {
      //      dispatch({type: ActionTypes.DELETE_TASKLIST_SUCCESS, res, taskListId});
      dispatch({type: ActionTypes.DELETE_ALERT_SUCCESS, res});
      showAlert("Alert deleted successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}
