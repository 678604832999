import * as ActionTypes from './action-types';
import * as diagnoseApi from '../api/diagnose-api';


export function getDiagnoseList() {
  return function(dispatch) {
      return diagnoseApi.getDiagnoseList()
      .then((diagnoseList) => {
        dispatch({type: ActionTypes.GET_DIAGNOSESLIST_SUCCESS, diagnoseList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getDiagnoseById(diagnoseId) {
  return function(dispatch) {
      return diagnoseApi.getDiagnoseById(diagnoseId)
      .then((diagnose) => {
        dispatch({type: ActionTypes.GET_DIAGNOSEBYID_SUCCESS, diagnose});
      })
      .catch(error => {
        throw(error);
      })
  }
}


export function loadDiagnoseInfo(diagnose) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_DIAGNOSE_SUCCESS, diagnose});
  }
}

export function resetDiagnoseInfo(groupNames) {
  var resetSelectedDiagnose = {groupName: groupNames[0].code }
  return function(dispatch){
    dispatch({type: ActionTypes.GET_DIAGNOSE_SUCCESS, diagnose:resetSelectedDiagnose});
  }
}

export function addOrUpdateDiagnose(formProps) {
    if(formProps.diagnoseId != null){ //Update
        var arrSynonym = [];
        if(formProps.synonyms != null){
          arrSynonym = formProps.synonyms.split(',');
          // formProps.synonyms.map((synonym) =>{
          //   arrSynonym.push(synonym);
          // })
        }
        var diagnoseInfo = {
          name : formProps.diagnoseName, synonyms:arrSynonym, groupName: formProps.groupName
        }
        return function(dispatch) {
          return diagnoseApi.updateDiagnose(diagnoseInfo,formProps.diagnoseId)
          .then(updatedDiagnose => {
            dispatch({type: ActionTypes.UPDATE_DIAGNOSE_SUCCESS, updatedDiagnose});
            showAlert("Diagnosis updated successfully!", "success");
            //throw (error);
          }).catch(error => {
            throw(error);
          })
        }
    }
    else //Add
    {
      var arrSynonym = [];
      if(formProps.synonyms != null){
        arrSynonym = formProps.synonyms.split(',');
      }
      var diagnoseInfo = {
        name : formProps.diagnoseName, synonyms:arrSynonym, groupName: formProps.groupName
      }

      return function(dispatch) {
        return diagnoseApi.addDiagnose(diagnoseInfo)
        .then(diagnose => {
          dispatch({type: ActionTypes.ADD_DIAGNOSE_SUCCESS, diagnose});
          showAlert("Diagnosis created successfully!", "success");
        }).catch(error => {
          //console.log(error.message);
          throw(error);
          //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
        })
      }
   }
}

export function deleteDiagnose(diagnose) {
  return function(dispatch) {
    return diagnoseApi.deleteDiagnose(diagnose._id).then(res => {
      //      dispatch({type: ActionTypes.DELETE_TASKLIST_SUCCESS, res, taskListId});
      dispatch({type: ActionTypes.DELETE_DIAGNOSE_SUCCESS, res});
      showAlert("Diagnosis deleted successfully!", "success");
    }).catch(error => {
      throw(error);
    });
  };
}
