import axios from 'axios';

export function getDosingByDrugById(drugId) {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/drug/'+ drugId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getlastPublishedDosingByDrugId(drugId) {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/lastPublishedDosing/'+ drugId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function addDrugDosingItem(drugDosage,dosingId) {
  return axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/addDosingItem/'+ dosingId, drugDosage)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function updateDrugDosingItem(drugDosage, dosingItemId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/updateDosingItem/' + dosingItemId, drugDosage)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function deleteDrugDosingItem(dosingId,dosingItemId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/deleteDosingItem/' + dosingItemId + '/dosing/' + dosingId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function publishDosing(dosingId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/publish/' + dosingId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function editPublishedDosing(dosingId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/editPublishedDosing/' + dosingId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function saveDosingSortOrder(dosingId,orderingObj) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'dosing/saveDosingSortOrder/' + dosingId,orderingObj)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}
