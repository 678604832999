import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import * as AlertActions from '../actions/alert-actions';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

class AlertListComponent extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){

    }

    populateForm (e,alert) {
      this.props.alertAction_binded.loadAlertInfo(alert)
    }

    deleteRecord(e,alert) {
      if(confirm('Are you sure you want to delete?')){
        this.props.alertAction_binded.deleteAlert(alert)
        .then(()=>{
          this.props.alertAction_binded.getAlertList()
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
          console.log("Error deleting alert!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    getfaceupAlertCategoryName(faceupCatCode,faceupAlertCategoryList){
      var faceupCategory = faceupAlertCategoryList.filter(function( obj ) {
        return obj.code === faceupCatCode;
      });
      return faceupCategory[0].typeName;

    }

    render() {
      const myAlertList = _.orderBy(this.props.alertList, [function (item) { return item.expirationDate; }], ["desc"]);;

      var editPermission = this.currentUserHasEditPermissions();
      var buttonAdd = editPermission
            ? <input type="button" id="Add" className="btn btn-Info btn-space" value="Add" onClick={()=>{this.props.addClicked(this)}}/>
            : null

      return(

          <div>
          {/*ROW 3*/}
          <div className="row">
              <div className="col-md-12">

              <div className="panel panel-default"> {/* panel-default*/}
                  <div className="panel-heading">
                       Alert List{buttonAdd}
                  </div>

                  <div className="panel-body">{/* panel-body*/}
                      <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>Text</th>
                                  <th>Activation Date</th>
                                  <th>Expiration Date</th>
                                  <th>Faceup</th>
                                  {
                                    editPermission
                                    ? <th></th>
                                    : null
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    myAlertList && myAlertList.map((alert,index) => {
                                    return(
                                      <tr key={index}>
                                        <td className="col-md-4">{alert.alertText}</td>
                                        <td className="col-md-1">{moment.utc(alert.activationDate).local().format("MMM DD, YYYY")}</td>
                                        <td className="col-md-1">{moment.utc(alert.expirationDate).local().format("MMM DD, YYYY")}</td>
                                        <td className="col-md-1">{this.getfaceupAlertCategoryName(alert.faceup,this.props.faceupAlertCategoryList)}</td>
                                        {
                                          editPermission
                                          ?  <td className="col-md-2">
                                              <input type="button" className="btn btn-Warning btn-space btn-xs" id="Edit" value="Edit" onClick={()=>{this.populateForm(this,alert)}}/>
                                              <input type="button" className="btn btn-Danger btn-space btn-xs" id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,alert)}}/>
                                            </td>
                                          : null
                                        }
                                      </tr>
                                      )
                                    })
                                  }
                              </tbody>
                            </table>
                        </div>
                    </div>{/* panel-body*/}
                </div> {/* panel-default*/}
              </div>
          </div>
          {/*ROW 3*/}

      </div>
      )
    }
}

function mapDispatchToProps(dispatch){
  return {
    alertAction_binded: bindActionCreators(AlertActions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(AlertListComponent)
