import React from 'react';
import Multiselect from 'react-widgets/lib/Multiselect';

const BasicMultiSelectField = ({ input, data, valueField, textField, placeholder, meta: { touched, error } }) => (
  <div>
    <Multiselect {...input}
    onBlur={() => input.onBlur()}
    value={input.value || []} // requires value to be an array
    data={data}
    valueField={valueField}
    textField={textField}
    placeholder={placeholder}
    />
    {touched && error && <span className="text-danger">{error}</span>}
  </div>
  )

export default BasicMultiSelectField
