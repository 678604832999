import * as ActionTypes from './action-types';
import * as SMApi from '../api/susceptibiltyMatrix-api';

export function getSMList() {
  return function(dispatch) {
      return SMApi.getSMList()
      .then((SMList) => {
        dispatch({type: ActionTypes.GET_SMLIST_SUCCESS, SMList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getRetiredSMList() {
  return function(dispatch) {
      return SMApi.getRetiredSMList()
      .then((SMListRetired) => {
        dispatch({type: ActionTypes.GET_SMLISTRETIRED_SUCCESS, SMListRetired});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getSMById(SMId) {
  return function(dispatch) {
      return SMApi.getSMById(SMId)
      .then((SM) => {
        dispatch({type: ActionTypes.GET_SMBYID_SUCCESS, SM});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function loadSMInfo(SMObject,SMList) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_SM_SUCCESS, SM:SMObject});
  }
}

export function resetSMInfo() {
  var resetSM = {}
  return function(dispatch){
    dispatch({type: ActionTypes.GET_SM_SUCCESS, resetSM});
  }
}

export function addOrUpdateSM(formProps) {
    if(formProps.SMId != null){ //Update
        var SMInfo = {
          name : formProps.SMName
        }
        return function(dispatch) {
          return SMApi.updateSM(SMInfo,formProps.SMId)
          .then(updatedSM => {
            dispatch({type: ActionTypes.UPDATE_SM_SUCCESS, updatedSM});
            showAlert("Susceptibility matrix updated successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
          })
        }
    }
    else //Add
    {
      var SMInfo = {
        name : formProps.SMName
      }
      return function(dispatch) {
        return SMApi.addSM(SMInfo)
        .then(SM => {
          dispatch({type: ActionTypes.ADD_SM_SUCCESS, SM});
          showAlert("Susceptibility matrix created successfully!", "success");
        }).catch(error => {
          showAlert(error.error, "error");
          throw(error);
          //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
        })
      }
   }
}

export function deleteSM(SM) {
  return function(dispatch) {
    return SMApi.deleteSM(SM._id).then(res => {
      dispatch({type: ActionTypes.DELETE_SM_SUCCESS, res});
      showAlert("Susceptibility matrix deleted successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}

export function publishSM(SM) {
  return function(dispatch) {
    return SMApi.publishSM(SM._id).then(res => {
      dispatch({type: ActionTypes.PUBLISH_SM_SUCCESS, res});
      showAlert("Susceptibility matrix published successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}
