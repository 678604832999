import * as ActionTypes from './action-types';
import * as diagnosiscardApi from '../api/diagnosiscard-api';

  export function getDiagnosisCards() {
    return function(dispatch) {
        return diagnosiscardApi.getDiagnosisCards()
        .then((diagnosisCards) => {
          dispatch({type: ActionTypes.GET_ALL_DIAGNOSISCARDS_SUCCESS, diagnosisCards});
        })
        .catch(error => {
          throw(error);
        })
    }
  }

 export function getCurrentDiagnosisCard(diagnoseId) {
    return function(dispatch) {
        return diagnosiscardApi.getCurrentDiagnosisCard(diagnoseId)
        .then((diagnosisCard) => {
          dispatch({type: ActionTypes.GET_CURRENT_DIAGNOSISCARD_SUCCESS, diagnosisCard});
        })
        .catch(error => {
          throw(error);
        })
    }
  }

  export function getLastPublishedDiagnosisCard(diagnoseId) {
     return function(dispatch) {
         return diagnosiscardApi.getLastPublishedDiagnosisCard(diagnoseId)
         .then((diagnosisCard) => {
           dispatch({type: ActionTypes.GET_LASTPUBLISHED_DIAGNOSISCARD_SUCCESS, diagnosisCard});
         })
         .catch(error => {
           throw(error);
         })
     }
   }

 export function addOrUpdateDiagnosisCard(formProps,diagnoseId) {
    if(formProps.diagnosisCardId != null){//update
        var diagnosisCardInfo = createDiagnosisCardInfoObject(formProps,diagnoseId)
        return function(dispatch) {
          return diagnosiscardApi.updateDiagnosisCard(diagnosisCardInfo,formProps.diagnosisCardId)
          .then(updatedDiagnosisCard => {
            dispatch({type: ActionTypes.UPDATE_DIAGNOSISCARD_SUCCESS, updatedDiagnosisCard});
            showAlert("Diagnosis Card updated successfully!", "success");
          }).catch(error => {
            throw(error);
          })
        }
    }
    else //Add
    {
          var diagnosisCardInfo = createDiagnosisCardInfoObject(formProps,diagnoseId)
          return function(dispatch) {
            return diagnosiscardApi.addDiagnosisCard(diagnosisCardInfo)
            .then(updatedDiagnosisCard => {
              dispatch({type: ActionTypes.ADD_DIAGNOSISCARD_SUCCESS, updatedDiagnosisCard});
              showAlert("Diagnosis Card updated successfully!", "success");
            }).catch(error => {
              throw(error);
            })
          }
     }

  }

  function createDiagnosisCardInfoObject(formProps,diagnosisId){
        var diagnosisCardInfo = {}

        //Diagnosis id
        diagnosisCardInfo.diagnosis = diagnosisId

        //bacterias
        var bacterias = []
        formProps.bacterias.map((bacteria,index)=>{
          bacterias.push(bacteria.value)
        })
        diagnosisCardInfo.bacterias=bacterias

        //comment
        diagnosisCardInfo.comment=formProps.generalComments

        //recommendation
        var recommendations = []
        var recommendation1 = {}
        var recommendation2 = {}
        var recommendation3 = {}

        //1st line
        if(formProps.drug1){
            if(formProps.drug1 != "Other"){
              recommendation1.drug=formProps.drug1
            }else{
              recommendation1.customDrug=formProps.otherDrug1
            }
            recommendation1.priority="1"
            if(formProps.duration1){
              recommendation1.duration = formProps.duration1
            }
            if(formProps.comment1){
              recommendation1.comment = formProps.comment1
            }
            recommendations.push(recommendation1)
        }

        //2nd line
        if(formProps.drug2){
            if(formProps.drug2 != "Other"){
              recommendation2.drug=formProps.drug2
            }else{
              recommendation2.customDrug=formProps.otherDrug2
            }
            recommendation2.priority="2"
            if(formProps.duration2){
              recommendation2.duration = formProps.duration2
            }
            if(formProps.comment2){
              recommendation2.comment = formProps.comment2
            }
            recommendations.push(recommendation2)
        }

        //3rd line
        if(formProps.drug3){
            if(formProps.drug3 != "Other"){
              recommendation3.drug=formProps.drug3
            }else{
              recommendation3.customDrug=formProps.otherDrug3
            }
            recommendation3.priority="3"
            if(formProps.duration3){
              recommendation3.duration = formProps.duration3
            }
            if(formProps.comment3){
              recommendation3.comment = formProps.comment3
            }
            recommendations.push(recommendation3)
        }

        diagnosisCardInfo.recommendations=recommendations
        return diagnosisCardInfo;
  }

  export function publishDiagnosisCard(diagnosisCardId) {
    return function(dispatch) {
      return diagnosiscardApi.publishDiagnosisCard(diagnosisCardId).then(res => {
        dispatch({type: ActionTypes.PUBLISH_DIAGNOSISCARD_SUCCESS, res});
        showAlert("Diagnosis Card published successfully!", "success");
      }).catch(error => {
        throw(error);
      });
    };
  }

  export function editPublishedDiagnosisCard(diagnosisCardId) {
    return function(dispatch) {
      return diagnosiscardApi.editPublishedDiagnosisCard(diagnosisCardId).then(res => {
        dispatch({type: ActionTypes.EDITPUBLISHED_DIAGNOSISCARD_SUCCESS, res});
        showAlert("Diagnosis Card can be edited now!", "success");
      }).catch(error => {
        throw(error);
      });
    };
  }
