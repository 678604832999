import * as ActionTypes from './action-types';
import * as drugdosageApi from '../api/drugdosage-api';
import _ from 'lodash';

export function loadDrugDosageInfo(drugDosage) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_DRUGDOSAGE_SUCCESS, drugDosage});
  }
}

export function resetDrugDosageInfo(adminRouteList,costList,doseUnitList,ageUnitList,
    frequencyUnitList,durationList,durationUnitList,maxDoseUnitList) {

    var resetSelectedDrugDosage = {routeOfAdministration:adminRouteList[0].code,
          //neoNatal:{unit:""},//neoNatal:{unit:ageUnitList[0].code}
          //ageRange:{from:{unit:""},to:{unit:""}},//ageRange:{from:{unit:ageUnitList[0].code},to:{unit:ageUnitList[0].code}},
          dosage:{unit:""},//dosage:{unit:doseUnitList[0].code}
          frequency:{unit:""},//frequency:{unit:frequencyUnitList[0].code}
          duration:{time:"",unit:""},//duration:{time:durationList[0].code,unit:durationUnitList[0].code}
          maxDose:{unit:""},//maxDose:{unit:maxDoseUnitList[0].code}
          costLevel:costList[0].code}

    return function(dispatch){
      dispatch({type: ActionTypes.GET_DRUGDOSAGE_SUCCESS, drugDosage:resetSelectedDrugDosage});
    }
}

export function getDosingByDrugById(drugId) {
  return function(dispatch) {
      return drugdosageApi.getDosingByDrugById(drugId)
      .then((drugDosing) => {
        dispatch({type: ActionTypes.GET_DRUGDOSING_SUCCESS, drugDosing});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function addOrUpdateDrugDosingItem(formProps,drugId,dosingId) {
    if(formProps.drugDosageId != null){ //Update

        var drugDosageInfo = createDosingInfoObject(formProps)
        //console.log(drugDosageInfo)
        return function(dispatch) {
          return drugdosageApi.updateDrugDosingItem(drugDosageInfo,formProps.drugDosageId)
          .then(updatedDrugDosage => {
            dispatch({type: ActionTypes.UPDATE_DRUGDOSAGE_SUCCESS, updatedDrugDosage});
            showAlert("Dosing updated successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
          })
        }
    }
    else //Add
    {
        var drugDosageInfo = createDosingInfoObject(formProps)
        //console.log(drugDosageInfo)
        return function(dispatch) {
          return drugdosageApi.addDrugDosingItem(drugDosageInfo,dosingId)
          .then(drugDosage => {
            dispatch({type: ActionTypes.ADD_DRUGDOSAGE_SUCCESS, drugDosage});
            showAlert("Dosing created successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
            //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
          })
        }
   }
}

function createDosingInfoObject(formProps){
      var drugDosageInfo = {}
      //Administration Route
      drugDosageInfo.routeOfAdministration=formProps.adminRoute

      //Neonatal Fields
      var neoNatal={}
      if(formProps.neonatalAge){
        neoNatal.age = parseInt(formProps.neonatalAge)
        neoNatal.unit = formProps.neonatalAgeUnit
        drugDosageInfo.neoNatal = neoNatal
      }

      //NeonatalAge Range Fields
      var ageRangeNeo ={}
      var ageRangeFromNeo ={}
      var ageRangeToNeo ={}
      if(formProps.ageInclusionNeo){
        if(formProps.ageInclusionNeo == "<"){
          ageRangeToNeo.age = parseInt(formProps.age1Neo)
          ageRangeToNeo.unit = formProps.age1UnitNeo
          ageRangeToNeo.inclusion = "<"
          ageRangeNeo.to = ageRangeToNeo
          drugDosageInfo.neoNatal = ageRangeNeo
        }else if(formProps.ageInclusionNeo == ">="){
          ageRangeFromNeo.age = parseInt(formProps.age1Neo)
          ageRangeFromNeo.unit = formProps.age1UnitNeo
          ageRangeFromNeo.inclusion = ">="
          ageRangeNeo.from= ageRangeFromNeo
          drugDosageInfo.neoNatal = ageRangeNeo
        }else{
          ageRangeFromNeo.age = parseInt(formProps.age1Neo)
          ageRangeFromNeo.unit = formProps.age1UnitNeo
          ageRangeFromNeo.inclusion = ">="
          ageRangeNeo.from= ageRangeFromNeo

          ageRangeToNeo.age = parseInt(formProps.age2Neo)
          ageRangeToNeo.unit = formProps.age2UnitNeo
          ageRangeToNeo.inclusion = "<"
          ageRangeNeo.to= ageRangeToNeo

          drugDosageInfo.neoNatal = ageRangeNeo
        }
      }

      //Age Range Fields
      var ageRange ={}
      var ageRangeFrom ={}
      var ageRangeTo ={}
      if(formProps.ageInclusion){
        if(formProps.ageInclusion == "<"){
          ageRangeTo.age = parseInt(formProps.age1)
          ageRangeTo.unit = formProps.age1Unit
          ageRangeTo.inclusion = "<"
          ageRange.to = ageRangeTo
          drugDosageInfo.ageRange = ageRange
        }else if(formProps.ageInclusion == ">="){
          ageRangeFrom.age = parseInt(formProps.age1)
          ageRangeFrom.unit = formProps.age1Unit
          ageRangeFrom.inclusion = ">="
          ageRange.from= ageRangeFrom
          drugDosageInfo.ageRange = ageRange
        }else{
          ageRangeFrom.age = parseInt(formProps.age1)
          ageRangeFrom.unit = formProps.age1Unit
          ageRangeFrom.inclusion = ">="
          ageRange.from= ageRangeFrom

          ageRangeTo.age = parseInt(formProps.age2)
          ageRangeTo.unit = formProps.age2Unit
          ageRangeTo.inclusion = "<"
          ageRange.to= ageRangeTo

          drugDosageInfo.ageRange = ageRange
        }
      }

      //Dose Fields
      var dosage={}
      dosage.dose = formProps.dose
      if(formProps.doseUnit =="OTHER"){
        dosage.unit = formProps.otherDoseUnit1
      }else{
        dosage.unit = formProps.doseUnit
      }
      drugDosageInfo.dosage = dosage

      //Frequency Fields
      var frequency={}
      if(formProps.frequencyUnit){
      //if(formProps.frequency){
        //frequency.amount=parseInt(formProps.frequency)
        if(formProps.frequencyUnit =="OTHER"){
          frequency.unit = formProps.otherFrequencyUnit1
        }else{
          frequency.unit = formProps.frequencyUnit
        }
        drugDosageInfo.frequency = frequency
      }

      //Duration Fields
      var duration ={};
      if(formProps.duration){
        if(formProps.duration =="OTHER"){
          duration.time = formProps.otherDuration1
        }else{
          duration.time = formProps.duration
        }
        if(formProps.durationUnit =="OTHER"){
          duration.unit = formProps.otherDurationUnit1
        }else{
          duration.unit = formProps.durationUnit
        }
        drugDosageInfo.duration = duration
      }

      //Max Dose Fields
      var maxDose={}
      if(formProps.maxDose){
        maxDose.dose=formProps.maxDose
        if(formProps.maxDoseUnit =="OTHER"){
          maxDose.unit = formProps.otherMaxDoseUnit1
        }else{
          maxDose.unit = formProps.maxDoseUnit
        }
        drugDosageInfo.maxDose = maxDose
      }

      //Cost level
      drugDosageInfo.costLevel = formProps.cost

      //diagnosis
      if(formProps.diagnosis){
        drugDosageInfo.diagnosis = formProps.diagnosis
      }

      //notes
      drugDosageInfo.notes = formProps.notes

      //prefix
      drugDosageInfo.prefix = formProps.prefix

      return drugDosageInfo;
}

export function deleteDrugDosingItem(dosingId,drugDosage) {
  return function(dispatch) {
    return drugdosageApi.deleteDrugDosingItem(dosingId,drugDosage._id).then(res => {
      //      dispatch({type: ActionTypes.DELETE_TASKLIST_SUCCESS, res, taskListId});
      dispatch({type: ActionTypes.DELETE_DRUGDOSAGE_SUCCESS, res});
      showAlert("Dosing deleted successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}


export function publishDosing(dosingId) {
  return function(dispatch) {
    return drugdosageApi.publishDosing(dosingId).then(res => {
      dispatch({type: ActionTypes.PUBLISH_DRUGDOSING_SUCCESS, res});
      showAlert("Dosing published successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}

export function editPublishedDosing(dosingId) {
  return function(dispatch) {
    return drugdosageApi.editPublishedDosing(dosingId).then(res => {
      dispatch({type: ActionTypes.EDITPUBLISHED_DRUGDOSING_SUCCESS, res});
      showAlert("Dosing can be edited now!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}

export function getlastPublishedDosingDrugById(drugId) {
  return function(dispatch) {
      return drugdosageApi.getlastPublishedDosingByDrugId(drugId)
      .then((lastPublishedDrugDosing) => {
        dispatch({type: ActionTypes.GETLASTPUBLISHED_DRUGDOSING_SUCCESS, lastPublishedDrugDosing});
      })
      .catch(error => {
        throw(error);
      })
  }
}

// export function getDosingByNewSortOrder(drugDosing,newDosingItemArr) {
//   return function(dispatch) {
//       const deepCloneOfDrugDosing = JSON.parse(JSON.stringify(drugDosing)) //Do this so we dont mutate the data and state change will be reflected back on UI parent/child component
//       deepCloneOfDrugDosing.dosingItems = [];
//       deepCloneOfDrugDosing.dosingItems = newDosingItemArr;
//       dispatch({type: ActionTypes.GET_DRUGDOSING_SUCCESS, drugDosing:deepCloneOfDrugDosing});
//   }
// }

export function saveDosingSortOrder(dosingId,fromIndex,toIndex) {
  var orderingObj ={fromIndex:fromIndex,toIndex:toIndex}
  return function(dispatch) {
    return drugdosageApi.saveDosingSortOrder(dosingId,orderingObj).then(res => {
      dispatch({type: ActionTypes.SAVE_SORTEDDRUGDOSAGE_SUCCESS, res});
      showAlert("Dosing sort saved successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}
