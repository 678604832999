import React from 'react';

const BasicFieldOnlyValidation = ({input, label, type, placeholder, cssClass, meta: {touched, error}}) => (
  <div>
  {/* If type=textarea, show textarea else shoe input field*/}
    {type=='textarea' ?
      <textarea {...input} placeholder={placeholder} type={type} className={"form-control " + cssClass}/>
      : <input {...input} placeholder={placeholder} type={type} className="form-control"/>}
    {touched && error && <span className="text-danger">{error}</span>}
  </div>
)

export default BasicFieldOnlyValidation;
