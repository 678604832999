import React from 'react'
import { Route, IndexRoute, IndexRedirect,NotFoundRoute } from 'react-router';
import App from './views/App'
import PageNotFound from './views/PageNotFound'
import BacteriaView from './views/BacteriaView'
import DrugsView from './views/DrugsView'
import DrugDosageView from './views/DrugDosageView'
import DiagnosisView from './views/DiagnosisView'
import DiagnosisCardView from './views/DiagnosisCardView'
import SusceptibilityMatrixView from './views/SusceptibilityMatrixView'
import SMLineItemsView from './views/SMLineItemsView'
import SMOnlyView from './views/SMOnlyView'
import UserView from './views/UserView'
import AlertView from './views/AlertView'
import HomeView from './views/Home';
import TemplateCore from './views/TemplateCore';
import Login from './views/auth/Login'
import Logout from './views/auth/Logout'

export const Routes = () => {

  return (
    <Route path="/" component={App}>
        <Route component={TemplateCore} >
              <IndexRoute component={HomeView}/>
              <IndexRedirect to="/bacteria" />
              <Route path="/bacteria" component={BacteriaView}/>
              <Route path="/drugs" component={DrugsView}/>
              <Route path="/drugdosage/:drugId" component={DrugDosageView} />
              <Route path="/diagnosis" component={DiagnosisView}/>
              <Route path="/diagnosiscard/:diagnoseId" component={DiagnosisCardView}/>
              <Route path="/susceptibilitymatrix" component={SusceptibilityMatrixView}/>
              <Route path="/smlineitem/:SMId" component={SMLineItemsView}/>
              <Route path="/smonlyview/:SMId" component={SMOnlyView}/>
              <Route path="/user" component={UserView}/>
              <Route path="/alerts" component={AlertView}/>
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
    </Route>
  )
}

export default Routes
