import axios from 'axios';

export function getBacteriaList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'bacterias')
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}


export function addBacteria(bacteria) {
  return axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'bacteria', bacteria)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function updateBacteria(bacteria, bacteriaId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'bacteria/' + bacteriaId, bacteria)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function deleteBacteria(bacteriaId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'bacteria/delete/' + bacteriaId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}
