import * as types from '../actions/action-types';
import initialState from './initialState';

const alertReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_ALERTLIST_SUCCESS:
      return {...state, alertList:action.alertList};  //whatever our current state is, add on "alertList"

    case types.GET_ALERT_SUCCESS:
      return {...state, alert:action.alert};  //whatever our current state is, add on "alert"

  }
  return state;
}

export default alertReducer
