import * as ActionTypes from './action-types';
import * as bacteriaApi from '../api/bacteria-api';

export function getBacteriaList() {
  return function(dispatch) {
      return bacteriaApi.getBacteriaList()
      .then((bacteriaList) => {
        dispatch({type: ActionTypes.GET_BACTERIALIST_SUCCESS, bacteriaList});
      })
      .catch(error => {
        throw(error);
      })
  }
}


export function loadBacteriaInfo(bacteria) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_BACTERIA_SUCCESS, bacteria});
  }
}

export function resetBacteriaInfo(bacteriaCategoryList) {
  var resetSelectedBacteria = {category:bacteriaCategoryList[0].code}
  return function(dispatch){
    dispatch({type: ActionTypes.GET_BACTERIA_SUCCESS, bacteria:resetSelectedBacteria});
  }
}

export function addOrUpdateBacteria(formProps) {
    if(formProps.bacteriaId != null){ //Update
        var arrSynonym = [];
        if(formProps.synonyms != null){
          arrSynonym = formProps.synonyms.split(',');
          // formProps.synonyms.map((synonym) =>{
          //   arrSynonym.push(synonym);
          // })
        }
        var bacteriaInfo = {
          name : formProps.bacteriaName,category:formProps.category, synonyms:arrSynonym,notes:formProps.notes
        }
        return function(dispatch) {
          return bacteriaApi.updateBacteria(bacteriaInfo,formProps.bacteriaId)
          .then(updatedBacteria => {
            dispatch({type: ActionTypes.UPDATE_BACTERIA_SUCCESS, updatedBacteria});
            showAlert("Bacteria updated successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
          })
        }
    }
    else //Add
    {
      var arrSynonym = [];
      if(formProps.synonyms != null){
        arrSynonym = formProps.synonyms.split(',');
      }
      var bacteriaInfo = {
        name : formProps.bacteriaName, category:formProps.category, synonyms:arrSynonym,notes:formProps.notes
      }

      return function(dispatch) {
        return bacteriaApi.addBacteria(bacteriaInfo)
        .then(bacteria => {
          dispatch({type: ActionTypes.ADD_BACTERIA_SUCCESS, bacteria});
          showAlert("Bacteria created successfully!", "success");
        }).catch(error => {
          showAlert(error.error, "error");
          throw(error);
          //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
        })
      }
   }
}

export function deleteBacteria(bacteria) {
  return function(dispatch) {
    return bacteriaApi.deleteBacteria(bacteria._id).then(res => {
      //      dispatch({type: ActionTypes.DELETE_TASKLIST_SUCCESS, res, taskListId});
      dispatch({type: ActionTypes.DELETE_BACTERIA_SUCCESS, res});
      showAlert("Bacteria deleted successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}
