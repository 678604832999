import axios from 'axios';

export function getSMList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'nonRetiredSusceptibilityMatrixes')
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getRetiredSMList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'retiredSusceptibilityMatrixes')
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getSMById(SMId) {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix/'+ SMId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}


export function addSM(SMObject) {
  return axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix', SMObject)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw(error.response.data)
    });
}


export function updateSM(SMInfo, SMId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix/' + SMId, SMInfo)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw(error.response.data)
    });
}

export function deleteSM(SMId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix/delete/' + SMId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw(error.response.data)
    });
}

export function publishSM(SMId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix/publish/' + SMId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw(error.response.data)
    });
}
