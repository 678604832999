import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

const BasicDateTimeField = ({ input: { onChange, value }, showTime, placeholder, meta: { touched, error } }) => (
  <div>
    <DateTimePicker
      onChange={onChange}
      format="MMM DD, YYYY"
      time={showTime}
      value={!value ? null : new Date(value)}
      placeholder={placeholder}
    />
    {touched && error && <span className="text-danger">{error}</span>}
  </div>
  )

export default BasicDateTimeField
