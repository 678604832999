import * as ActionTypes from './action-types';
import * as smLineItemsApi from '../api/smlineitems-api';


export function loadLineItemInfo(smLineItem) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_SMLINEITEM_SUCCESS, smLineItem});
  }
}

export function resetLineItemInfo(cultureSourceList, bacteriaList,drugList,recommendedList,resistanceCategoryList) {
  var resetSelectedLineItem = {cultureSource:cultureSourceList[0].code,
                  bacteria:bacteriaList[0],drug:drugList[0],
                  recommended:recommendedList[1].code,
                  resistanceValue:""}
  return function(dispatch){
    dispatch({type: ActionTypes.GET_SMLINEITEM_SUCCESS, smLineItem:resetSelectedLineItem});
  }
}

export function addOrUpdateSMLineItems(formProps,SMId) {
    if(formProps.SMLineItemId != null){ //Update

        var SMLineItemInfo = {
          bacteria: formProps.bacteria,
          drug: formProps.drug,
          isolates: formProps.isolates,
          resistanceCategory: formProps.resistanceCategory,
          cultureSource: formProps.cultureSource,
          patientType:formProps.patientType,
          recommended:formProps.recommended
        }

        if(formProps.resistanceValue){
          if(formProps.resistanceValue =="%SUSCEPTIBLE"){
            SMLineItemInfo.resistanceValue = formProps.percentSusceptible1
          }else{
            SMLineItemInfo.resistanceValue = formProps.resistanceValue
          }
        }

        return function(dispatch) {
          return smLineItemsApi.updateSMLineItem(SMLineItemInfo,formProps.SMLineItemId,SMId)
          .then(updatedSMLineItem => {
            dispatch({type: ActionTypes.UPDATE_SMLINEITEM_SUCCESS, updatedSMLineItem});
            showAlert("Susceptibility matrix line item updated successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
          })
        }
    }
    else //Add
    {
      var SMLineItemInfo = {
        bacteria: formProps.bacteria,
        drug: formProps.drug,
        isolates: formProps.isolates,
        resistanceCategory: formProps.resistanceCategory,
        cultureSource: formProps.cultureSource,
        patientType:formProps.patientType,
        recommended:formProps.recommended
      }
      if(formProps.resistanceValue){
        if(formProps.resistanceValue =="%SUSCEPTIBLE"){
          SMLineItemInfo.resistanceValue = formProps.percentSusceptible1
        }else{
          SMLineItemInfo.resistanceValue = formProps.resistanceValue
        }
      }

      return function(dispatch) {
        return smLineItemsApi.addSMLineItem(SMLineItemInfo,SMId)
        .then(SMLineItem => {
          dispatch({type: ActionTypes.ADD_SMLINEITEM_SUCCESS, SMLineItem});
          showAlert("Susceptibility matrix line item added successfully!", "success");
        }).catch(error => {
          showAlert(error.error, "error");
          throw(error);
          //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
        })
      }
   }
}

export function deleteSMLineItem(lineItem) {
  return function(dispatch) {
    return smLineItemsApi.deleteSMLineItem(lineItem._id).then(res => {
      dispatch({type: ActionTypes.DELETE_SMLINEITEM_SUCCESS, res});
      showAlert("Susceptibility matrix line item deleted successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}
