import React from 'react'
import { Link, browserHistory, hashHistory } from 'react-router'
import { connect } from 'react-redux'
import * as userApi from '../api/user-api'

class Header extends React.Component {

  constructor(props) {
      super(props)
  }

  onLogoutClicked(){
    hashHistory.push('logout')
  }

    render() {
      var enterpriseId =""
      if(this.props.loggedInUser){
        enterpriseId = this.props.loggedInUser.enterpriseId
      }

      return (
        <div>
            {/*/. NAV TOP*/}
            <nav  className="navbar navbar-default top-navbar" role="navigation">
                <div  className="navbar-header">
                    <button type="button"  className="navbar-toggle" data-toggle="collapse" data-target=".sidebar-collapse">
                        <span  className="sr-only">Toggle navigation</span>
                        <span  className="icon-bar"></span>
                        <span  className="icon-bar"></span>
                        <span  className="icon-bar"></span>
                    </button>
                    <span  className="navbar-brand" href="index.html"><i  className="fa"></i> <strong>BCH Antibiogram</strong></span>
                </div>
                <ul  className="nav navbar-top-links navbar-right">
                    {/* /.dropdown-alerts
                    <li  className="dropdown">
                         <a  className="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                             <i  className="fa fa-bell fa-fw"></i> <i  className="fa fa-caret-down"></i>
                         </a>
                         <ul  className="dropdown-menu dropdown-alerts">
                             <li>
                                 <a href="#">
                                     <div>
                                         <i  className="fa fa-comment fa-fw"></i> New Comment
                                         <span  className="pull-right text-muted small">4 min</span>
                                     </div>
                                 </a>
                             </li>
                             <li  className="divider"></li>
                             <li>
                                 <a href="#">
                                     <div>
                                         <i  className="fa fa-twitter fa-fw"></i> 3 New Followers
                                         <span  className="pull-right text-muted small">12 min</span>
                                     </div>
                                 </a>
                             </li>
                         </ul>
                         {/* /.dropdown-alerts
                      </li>*/}

                       {/* /.dropdown-user */}
                       <li  className="dropdown">
                           <a  className="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                               {/*<i  className="fa fa-user fa-fw"></i>*/} <b>{enterpriseId}</b> <i  className="fa fa-caret-down"></i>
                           </a>
                           <ul  className="dropdown-menu dropdown-user">
                               {/*<li><a href="#"><i  className="fa fa-user fa-fw"></i> User Profile</a>
                               </li>
                               <li><a href="#"><i  className="fa fa-gear fa-fw"></i> Settings</a>
                               </li>
                               <li  className="divider"></li>*/}
                               <li onClick={this.onLogoutClicked}><a href="#"><i  className="fa fa-sign-out fa-fw"></i> Logout</a>
                               </li>
                           </ul>
                           {/* /.dropdown-user */}
                       </li>
                </ul>
            </nav>
            {/*/. NAV TOP  */}
            {/*<img className="img-responsive" src="assets/img/BCH_Logo_EBG.png"/>*/}
        </div>
        );
    }
}

const mapStateToProps = function (store) {
  return {
    loggedInUser: store.userState.loggedInUser
  }
}


export default connect(mapStateToProps, null)(Header)
