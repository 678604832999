import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import {bindActionCreators} from 'redux';
import { Link, browserHistory, hashHistory } from 'react-router';
import BaseComponent from '../components/common/BaseComponent';
import BasicField from '../components/common/BasicField';
import BasicFieldOnlyValidation from '../components/common/BasicFieldOnlyValidation';
import * as DrugActions from '../actions/drug-actions';
import * as configRefApi from '../api/configRef-api';
import _ from 'lodash';
import DrugsListComponent from '../components/DrugsListComponent';

class DrugsView extends BaseComponent {

    constructor(props) {
        super(props)
        this.addClicked = this.addClicked.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
        this.state = {
          otherSpecialConsideration:false
         }
        this.handleSpecialConsiderationChange = this.handleSpecialConsiderationChange.bind(this);
        this.onEditSetState = this.onEditSetState.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
      //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
      //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.drugAction_binded.getDrugList()
    }

    onSubmit (formProps) {
      //console.log(formProps)
      this.props.drugAction_binded.addOrUpdateDrug(formProps,this.props.specialConsiderationList)
      .then(()=>{
        hideAddForm()
        this.props.reset();
        this.props.drugAction_binded.getDrugList()
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
        console.log("Error submitting drug!!", error);
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

    addClicked(e) {
      //this.props.reset();
      this.state = {otherSpecialConsideration:false}
      this.props.drugAction_binded.resetDrugInfo(this.props.drugTypeList)
    }

    cancelClicked(e) {
      this.props.reset();//VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
      hideAddForm();
    }

    onEditSetState(drug){
      if(drug.specialConsiderations.length > 0){
        drug.specialConsiderations.map((scl,index)=>{
          if(isNonOtherSelection(scl,this.props.specialConsiderationList)){
            this.setState({otherSpecialConsideration: false})
          }else{
            this.setState({otherSpecialConsideration: true})
          }
        })
      }else{
        this.setState({otherSpecialConsideration: false})
      }
    }

    createDrugTypeDropdown(drugTypeList){
      return drugTypeList.map((drgType,index) =>{
          return(
              <option key={index} value={drgType.code}>{drgType.typeName}</option>
          )
      })
    }

    // createSpecialConsidertionCheckbox(specialConsiderationList){
    //     return specialConsiderationList.map((scl,index) =>{
    //         return(
    //             <div className="checkbox" key={index}>
    //               <label>
    //                   <Field name={scl.code} onChange={this.handleSpecialConsiderationChange} type="checkbox" component="input"/> {scl.typeName}
    //               </label>
    //             </div>
    //         )
    //     })
    // }

    // createSpecialConsidertionCheckbox(specialConsiderationList){
    //     return specialConsiderationList.map((scl,index) =>{
    //         return(
    //             <div className="form-group form-bottom-padding checkbox " key={index}>
    //               <label className="control-label col-md-4">
    //                   <Field name={scl.code} onChange={this.handleSpecialConsiderationChange} type="checkbox" component="input"/> {scl.typeName}
    //               </label>
    //               <div className="col-md-4">
    //               <Field name="otherSpecialConsideration1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Consideration"></Field>
    //               </div>
    //             </div>
    //         )
    //     })
    // }

    createSpecialConsidertionCheckbox(specialConsiderationList){
      var buffer =[];
      for(var i=0; i<specialConsiderationList.length; i++){

        var mainLabel = ""
        var colWidth = "col-md-4"

        if(this.state.otherSpecialConsideration && specialConsiderationList[i].code =="OTHER"){
          var otherSpecialConsideration = <div className="col-md-4"><Field className="form-control" name="otherSpecialConsideration1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Consideration"></Field></div>
        }

        if(i==0){
          mainLabel = "Special Considerations";
        }

        if(specialConsiderationList[i].code =="OTHER"){
          colWidth = "col-md-1"
        }

        buffer.push(
          <div className="form-group form-reduce-margin-bottom" key={i}>
                  <label className="col-md-3 control-label">{mainLabel}</label>
                  <label className={colWidth}>
                      <Field name={specialConsiderationList[i].code} onChange={this.handleSpecialConsiderationChange} type="checkbox" component="input"/> {specialConsiderationList[i].typeName}
                  </label>
                  {otherSpecialConsideration}
            </div>
        )
      }
      return buffer
    }

    handleSpecialConsiderationChange(event) {
        if(event.target.name == "OTHER"){
            var isChecked = event.target.value=='true'? false : true
            if(isChecked){
              this.setState({otherSpecialConsideration: true})
            }else{
              this.setState({otherSpecialConsideration: false})
            }
        }
    }

    render() {
      const myDrugList = this.props.drugList_binded;
      const handleSubmit = this.props.handleSubmit; //injected by reduxform

      {/*      const otherSpecialConsideration = this.state.otherSpecialConsideration
      ? <div className="form-no-left-padding form-right-padding"><Field name="otherSpecialConsideration1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Consideration"></Field></div>
      : null;*/}

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                          Drugs <small></small>
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm*/}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           Drug Information
                           <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                      </div>
                      <div className="panel-body">{/* panel-body*/}
                        <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                          <input type="hidden" name="drugId" value=""/>
                          <Field name="drugName" type="text" component={BasicField} placeholder="Name" label="Name*" />
                          <Field name="synonyms" type="text" component={BasicField} placeholder="Comma separated list of synonyms" label="Synonyms" />
                          <div className="form-group">
                              <label className="col-md-3 control-label">Type*</label>
                              <div className="col-md-6">
                                <Field name="drugType" component="select" className="form-control">
                                  {this.createDrugTypeDropdown(this.props.drugTypeList)}
                                </Field>
                              </div>
                          </div>

                           {this.createSpecialConsidertionCheckbox(this.props.specialConsiderationList)}

                           <div className="form-group"></div>

                          <div className="form-group">
                             <div className="col-md-offset-3 col-md-9">
                               <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                               {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                               <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                             </div>
                           </div>
                        </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>
           </div>{/* AddForm*/}
          {/*ROW 2*/}

          {/*ROW 3*/}
          <DrugsListComponent drugList={myDrugList}  drugTypeList={this.props.drugTypeList} adminRouteList={this.props.adminRouteList}
              costLevelList={this.props.costLevelList} doseUnitList={this.props.doseUnitList} ageUnitList={this.props.ageUnitList}
              frequencyUnitList={this.props.frequencyUnitList}  durationList={this.props.durationList}
              durationUnitList={this.props.durationUnitList} maxDoseUnitList={this.props.maxDoseUnitList}
              specialConsiderationList={this.props.specialConsiderationList} addClicked={this.addClicked} onEditSetState={this.onEditSetState}/>
          {/*ROW 3*/}

      </div>
      )
    }
}

function validateDrugForm(values){
  const errors = {};

    if(!values.drugName){
      errors.drugName = 'Please enter a name for drug';
    }

    if(values.OTHER == true){
      if(!values.otherSpecialConsideration1){
        errors.otherSpecialConsideration1 = 'Please enter other SpecialConsideration';
      }
      else if(values.otherSpecialConsideration1.length <2){
        errors.otherSpecialConsideration1 = 'Please enter more than one chaaracter SpecialConsideration';
      }
    }
    return errors;
}

function isNonOtherSelection(str,list) {
    var ret = false;

    if(str.length === 0){//first option value will be empty string
      ret= true;
    }else{
      for(var i=0; i<list.length -1; i++){//skip the last item as "Other" is ALWAYS the last item in reference service
        if(list[i].code === str){
          ret = true;
          break;
        }
      }
    }

    return ret;
}

function mapStateToProps(state){

    var drug_binded = state.drugState.drug
    var initialValues={};
    var stringyfiedSynonyms="";

    var drugType = {}, drugTypeList = [], adminRoute = {}, adminRouteList = [], costLevel = {}, costLevelList = [], doseUnit = {}, doseUnitList = [],
        ageUnit = {},ageUnitList = [],frequencyUnit = {},frequencyUnitList = [],duration = {},durationList = [],
        durationUnit = {},durationUnitList = [],maxDoseUnit = {},maxDoseUnitList = [],specialConsideration = {},specialConsiderationList = []
    if(state.configRefState.configRef != null){
      drugType = state.configRefState.configRef.filter(function( obj ) { return obj.name === "Drug Category";});
      drugTypeList = drugType[0].list;
      adminRoute = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Administration Route";});
      adminRouteList = adminRoute[0].list;
      costLevel = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Drug Cost Level";});
      costLevelList = costLevel[0].list;
      doseUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Dose Unit";});
      doseUnitList = doseUnit[0].list;
      ageUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Age Unit";});
      ageUnitList = ageUnit[0].list;
      frequencyUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Frequency Units";});
      frequencyUnitList = frequencyUnit[0].list;
      duration = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Dosage Duration";});
      durationList = duration[0].list;
      durationUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Duration Unit";});
      durationUnitList = durationUnit[0].list;
      maxDoseUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Max Dose Unit";});
      maxDoseUnitList = maxDoseUnit[0].list;
      specialConsideration = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Special Considerations";});
      specialConsiderationList = specialConsideration[0].list;
    }

    if(drug_binded != null){
        if(drug_binded.synonyms != null){
          stringyfiedSynonyms = drug_binded.synonyms.toString();
        }
        var initialValues = {
          drugId:drug_binded._id,
          drugName:drug_binded.name,
          drugType:drug_binded.drugType,
          synonyms: stringyfiedSynonyms,
        }

        if(drug_binded.specialConsiderations != null && drug_binded.specialConsiderations.length > 0){
            drug_binded.specialConsiderations.map((sc,index)=>{
                if(isNonOtherSelection(sc,specialConsiderationList)){
                  initialValues[sc] = true
                }else{
                  initialValues.OTHER = true
                  initialValues.otherSpecialConsideration1=sc
                }
            })
        }
    }

    return{
      drugList_binded: state.drugState.drugList,
      drugTypeList : drugTypeList,
      adminRouteList : adminRouteList,
      costLevelList: costLevelList,
      doseUnitList:doseUnitList,
      ageUnitList: ageUnitList,
      frequencyUnitList:frequencyUnitList,
      durationList:durationList,
      durationUnitList:durationUnitList,
      maxDoseUnitList:maxDoseUnitList,
      specialConsiderationList:specialConsiderationList,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function mapDispatchToProps(dispatch){
  return {
    drugAction_binded: bindActionCreators(DrugActions, dispatch)
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(DrugsView);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'drugForm',  // a unique name for the form
    validate: validateDrugForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(DrugsView));
