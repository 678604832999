import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import BasicField from '../components/common/BasicField';
import * as BacteriaActions from '../actions/bacteria-actions';
import * as configRefApi from '../api/configRef-api';
import BacteriaListComponent from '../components/BacteriaListComponent';

class BacteriaView extends BaseComponent {

    constructor(props) {
        super(props)
        this.cancelClicked = this.cancelClicked.bind(this);
        this.addClicked = this.addClicked.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
      //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
      //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.bacteriaAction_binded.getBacteriaList()
    }

    onSubmit (formProps) {
      // //console.log(formProps)
      this.props.bacteriaAction_binded.addOrUpdateBacteria(formProps)
      .then(()=>{
        hideAddForm()
        this.props.reset();
        this.props.bacteriaAction_binded.getBacteriaList()
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
        console.log("Error submitting bacteria!!", error);
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

    cancelClicked(e) {
      this.props.reset(); //VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
      hideAddForm();
    }

    addClicked(e) {
      //this.props.reset();
      this.props.bacteriaAction_binded.resetBacteriaInfo(this.props.bacteriaCategoryList)
    }

    createBacteriaCategoryRadio(bacteriaCategoryList){
        return bacteriaCategoryList.map((bactCat,index) =>{
            return(
                <div className="radio" key={index}>
                  <label>
                      <Field name="category" type="radio" component="input" value={bactCat.code}/> {bactCat.typeName}
                  </label>
                </div>
            )
        })
    }

    render() {
      const myBacteriaList = this.props.bacteriaList_binded;
      const handleSubmit = this.props.handleSubmit; //injected by reduxform

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                          Bacteria <small></small>
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm*/}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           Bacteria Information
                           <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                      </div>
                      <div className="panel-body">{/* panel-body*/}
                        <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                          <input type="hidden" name="bacteriaId" value=""/>
                          <Field name="bacteriaName" type="text" component={BasicField} placeholder="Name" label="Name*" />
                          <Field name="synonyms" type="text" component={BasicField} placeholder="Comma separated list of synonyms" label="Synonyms" />

                          <div className="form-group">
                              <label className="col-md-3 control-label">Type*</label>
                              <div className="col-md-6">
                                {this.createBacteriaCategoryRadio(this.props.bacteriaCategoryList)}
                              </div>
                          </div>

                          <Field name="notes" type="textarea" component={BasicField} placeholder="Notes" label="Notes" />

                          <div className="form-group">
                             <div className="col-md-offset-3 col-md-9">
                               <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                               {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                               <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                             </div>
                           </div>
                        </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>
           </div>{/* AddForm*/}
          {/*ROW 2*/}

          {/*ROW 3*/}
          <BacteriaListComponent  bactList={myBacteriaList}  bacteriaCategoryList={this.props.bacteriaCategoryList} addClicked={this.addClicked}/>
          {/*ROW 3*/}

      </div>
      )
    }
}

function validateBacteriaForm(values){
  const errors = {};
    if(!values.bacteriaName){
      errors.bacteriaName = 'Please enter a name for bacteria';
    }

    return errors;
}

function mapStateToProps(state){

    var bacteria_binded = state.bacteriaState.bacteria
    var initialValues={};
    var stringyfiedSynonyms="";

    var bacteriaCategory = {};
    var bactCategoryList = [];
    if(state.configRefState.configRef != null){
      bacteriaCategory = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Bacteria Category";
      });
      bactCategoryList = bacteriaCategory[0].list;
    }

    if(bacteria_binded != null){
      if(bacteria_binded.synonyms != null){
        stringyfiedSynonyms = bacteria_binded.synonyms.toString();
      }

      initialValues = {
        bacteriaId:bacteria_binded._id,
        bacteriaName:bacteria_binded.name,
        synonyms: stringyfiedSynonyms,
        category:bacteria_binded.category,
        notes:bacteria_binded.notes
      }
    }

    return{
      bacteriaList_binded: state.bacteriaState.bacteriaList,
      bacteriaCategoryList : bactCategoryList,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function mapDispatchToProps(dispatch){
  return {
    bacteriaAction_binded: bindActionCreators(BacteriaActions, dispatch)
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(BacteriaView);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'bacteriaForm',  // a unique name for the form
    validate: validateBacteriaForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(BacteriaView));
