import React from 'react'
import BaseComponent from '../components/common/BaseComponent'
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import { Link, browserHistory, hashHistory } from 'react-router';
import {MultiList} from 'react-multi-dropdown';
import { formValueSelector } from 'redux-form';
import {bindActionCreators} from 'redux';
import BasicField from '../components/common/BasicField';
import BasicFieldOnlyValidation from '../components/common/BasicFieldOnlyValidation';
import BasicMultiSelectField from '../components/common/BasicMultiSelectField';
import BasicSelectField from '../components/common/BasicSelectField';
import * as DiagnoseActions from '../actions/diagnose-actions';
import * as DiagnosisCardActions from '../actions/diagnosiscard-actions';
import * as BacteriaActions from '../actions/bacteria-actions';
import * as DrugActions from '../actions/drug-actions';
import * as DrugDosageActions from '../actions/drugdosage-actions';
import * as configRefApi from '../api/configRef-api';
import * as diagnosiscardApi from '../api/diagnosiscard-api';

class DiagnosisCardView extends BaseComponent {

  constructor(props) {
      super(props)
      this.cancelClicked = this.cancelClicked.bind(this);
      this.state = {
        otherDrug1: false,
        otherDrug2: false,
        otherDrug3: false,
        published:false
       };
      this.handleDrug1Change = this.handleDrug1Change.bind(this);
      this.handleDrug2Change = this.handleDrug2Change.bind(this);
      this.handleDrug3Change = this.handleDrug3Change.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
    //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
    configRefApi.getConfigRefListFromSessionStorage()
    this.props.diagnoseAction_binded.getDiagnoseById(this.props.params.diagnoseId)
    this.props.bacteriaAction_binded.getBacteriaList()
    this.props.drugAction_binded.getDrugList()
    //this.props.diagnosisCardAction_binded.getDiagnosisCards()
    this.props.diagnosisCardAction_binded.getCurrentDiagnosisCard(this.props.params.diagnoseId)
    this.props.diagnosisCardAction_binded.getLastPublishedDiagnosisCard(this.props.params.diagnoseId)
  }

  componentWillReceiveProps(nextProps){

        if(nextProps.diagnosiscard_binded._id != null){
            if(nextProps.dirty==false){ //dont do the logic if dropdowns change which apparently causes componentWillReceiveProps to be called
                //reset to initial state
                this.setState({otherDrug1: false})
                this.setState({otherDrug2: false})
                this.setState({otherDrug3: false})
                this.setState({published: false})

                if(nextProps.diagnosiscard_binded.published){
                  this.setState({published: true})
                  disableOrEnableDiagnosisCard(nextProps.diagnosiscard_binded.published)
                }else{
                  disableOrEnableDiagnosisCard(nextProps.diagnosiscard_binded.published)
                }

                var recommendations = nextProps.diagnosiscard_binded.recommendations
                  recommendations.map((rec,index)=>{
                    if(rec.priority==="1" && rec.customDrug!=null){
                          this.setState({otherDrug1: true})
                    }
                    if(rec.priority==="2" && rec.customDrug!=null){
                          this.setState({otherDrug2: true})
                    }
                    if(rec.priority==="3" && rec.customDrug!=null){
                          this.setState({otherDrug3: true})
                    }
                })
              }
            }
            else{
              if(nextProps.dirty==false){ //dont do the logic if dropdowns change which apparently causes componentWillReceiveProps to be called
                this.setState({otherDrug1: false})
                this.setState({otherDrug2: false})
                this.setState({otherDrug3: false})
                this.setState({published: false})
                disableOrEnableDiagnosisCard(false)
              }
            }

            //finally , if loggedin user has no edit permissions disable the fieldset
            var editPermission = this.currentUserHasEditPermissions();
            if(!editPermission){
              disableOrEnableDiagnosisCard(true)
            }

  }

  cancelClicked(e) {
    hashHistory.push('diagnosis')
  }

  onSubmit(dcObject) {
    var formProps = dcObject.values
    var buttonType= dcObject.buttonType
    //console.log(formProps)
    //console.log(buttonType)
    this.props.diagnosisCardAction_binded.addOrUpdateDiagnosisCard(formProps,this.props.params.diagnoseId)
    .then(()=>{
      if(buttonType ==="publish"){
        this.props.diagnosisCardAction_binded.publishDiagnosisCard(formProps.diagnosisCardId)
        .then(()=>{
          //this.setState({published: true})
          //disableOrEnableDiagnosisCard(true)
          hashHistory.push('diagnosis')
        })
      }
      this.props.reset();
      this.props.diagnosisCardAction_binded.getCurrentDiagnosisCard(this.props.params.diagnoseId)
      // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
    })
    .catch((error)=>{
      showAlert("Error submitting Diagnosis Card!!", "error");
      //this.setState({updateProfileResult: error.message}); //this will cause render to be called
    })
  }

  editPublishedDiagnosisCard(diagnosisCardId){
    //console.log(diagnosisCardId)
    if(confirm('Are you sure you want to edit? Editing will create a copy of published version for edits')){
        this.props.diagnosisCardAction_binded.editPublishedDiagnosisCard(diagnosisCardId)
        .then(()=>{
          disableOrEnableDiagnosisCard(false)
          this.props.diagnosisCardAction_binded.getCurrentDiagnosisCard(this.props.params.diagnoseId)
          this.props.diagnosisCardAction_binded.getLastPublishedDiagnosisCard(this.props.params.diagnoseId)
        })
      }
  }


  createBacteriaMultiSelectDropdown(bacteriaList){
    var buffer =[];
     bacteriaList.map((bl,index) =>{
       var temp={}
       temp.label = bl.name
       temp.value = bl._id
       buffer.push (temp)
    })
    return buffer;
  }

  createDrugListDropdown(drugList,type){
    var buffer =[];
    buffer.push(<option key={"rec"+type} value="">Select a Drug</option>)
     drugList.map((dl,index) =>{
        buffer.push (<option key={index} value={dl._id}>{dl.name}</option>)
    })
    buffer.push (<option key={"other"+type} value="Other">Other</option>)
    return buffer;
  }

  handleDrug1Change(event) {
    if(event.target.value === "Other"){
      this.setState({otherDrug1: true})
    }else{
      this.setState({otherDrug1: false})
    }
  }

  handleDrug2Change(event) {
    if(event.target.value === "Other"){
      this.setState({otherDrug2: true})
    }else{
      this.setState({otherDrug2: false})
    }
  }

  handleDrug3Change(event) {
    if(event.target.value === "Other"){
      this.setState({otherDrug3: true})
    }else{
      this.setState({otherDrug3: false})
    }
  }

  formatLastPublishedDateColumn(publishedDoc){
    if(publishedDoc.updatedDt){
      var dateTime = moment.utc(publishedDoc.updatedDt).local().format("MMM DD, YYYY H:mm A");
      return dateTime;
    }
    else{
      return "---"
    }
  }

  openLastPublished(){
    var url = '#/diagnosiscard/' + String(this.props.diagnose._id) + '?previewLastPublished=true'
    var win = window.open(url,'LastPublished','height=800,width=1250')
    win.location.reload()
  }

  render() {
      const handleSubmit = this.props.handleSubmit;

      var editPermission = this.currentUserHasEditPermissions();

      const otherDrug1 = this.state.otherDrug1
      ? <div><br></br><Field name="otherDrug1" type="textarea" component={BasicFieldOnlyValidation}  placeholder="Other"></Field></div>
      : null;

      const otherDrug2 = this.state.otherDrug2
      ? <div><br></br><Field name="otherDrug2" type="textarea" component={BasicFieldOnlyValidation}  placeholder="Other"></Field></div>
      : null;

      const otherDrug3 = this.state.otherDrug3
      ? <div><br></br><Field name="otherDrug3" type="textarea" component={BasicFieldOnlyValidation}  placeholder="Other"></Field></div>
      : null;

      var header=""
      var buttonSet=""
      if(this.props.location.query.previewLastPublished === 'true'){
        header = <div className="pull-right">
                    <span className="bg-success">Published Date: {this.formatLastPublishedDateColumn(this.props.lastPublishedDiagnosiscard_binded)}</span>
                 </div>
      }else{

          header = this.state.published
          ? <div className="pull-right">
              <span className="bg-success">Last Published: {this.formatLastPublishedDateColumn(this.props.lastPublishedDiagnosiscard_binded)}</span>
            </div>
          : <div className="pull-right">
              <span className="bg-success">Last Published: {this.formatLastPublishedDateColumn(this.props.lastPublishedDiagnosiscard_binded)}</span>
              <input type="button" id="View" className="btn btn-warning btn-space btn-xs" value="View" onClick={()=>{this.openLastPublished(this)}}/>
            </div>

          var buttonPublish = this.currentUserHasPublishPermissions()
              ?  <input type="button" className="btn btn-Info btn-space" value="Publish" onClick = { handleSubmit((values) => this.onSubmit({ values,buttonType:'publish'}))}></input>
              : null

          buttonSet = this.state.published
           ? <div className="form-group">
               <div className="col-md-offset-3 col-md-9">
                  {editPermission && <input type="button" className="btn btn-Info btn-space" id="Edit" value="Create New Draft" onClick={()=>{this.editPublishedDiagnosisCard(this.props.diagnosiscard_binded._id)}}/>}
                  <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
               </div>
             </div>

           : <div className="form-group">
               <div className="col-md-offset-3 col-md-9">
                 {/*The handleSubmit handles all the validation checking and whatnot, and if everything is valid,
                   it will call the function given to it with the form values.
                   So we give it a function that appends extra information and calls onSubmit().*/}
                 {editPermission && <input type="button" className="btn btn-Success btn-space" value="Save" onClick = { handleSubmit((values) => this.onSubmit({ values,buttonType:'submit'}))}></input>}
                 {buttonPublish}
                 <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
               </div>
              </div>
        }

       var publishedOrDraft = "";
       if(this.props.diagnosiscard_binded !=null){
         if(this.props.diagnosiscard_binded.published || this.props.location.query.previewLastPublished ==='true'){
           publishedOrDraft = <span className="space bg-success"> Published </span>
         }else{
           publishedOrDraft = <span className="space purple"> Draft </span>
         }
       }


      return(
        <div>
            {/*ROW 1*/}
            <div  className="row">
                <div  className="col-md-12">
                    <h1  className="page-header">
                      {this.props.diagnose.name} {publishedOrDraft}
                    </h1>
                </div>
            </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row">{/* AddForm */}
                <div className="col-md-12">{/*col-md-12*/}
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           Recommendation Information
                           {header}
                           {/*<a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>*/}
                      </div>
                      <div className="panel-body">{/* panel-body*/}

                      <form className="form-horizontal form-border"> {/*onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                        <fieldset id="DiagnosisCardFields">
                          <input type="hidden" name="diagnosisCardId" value=""/>

                          <div className="form-group form-bottom-padding">
                              <label className="col-md-3 control-label">List of Pathogens*</label>
                              <div className="col-md-6">
                              <div className="section">
                                  <Field name="bacterias" component={BasicMultiSelectField} valueField="value" textField="label" placeholder="Select Pathogen(s)"
                                  data={this.createBacteriaMultiSelectDropdown(this.props.bacteriaList)}/>
                              </div>
                              </div>
                          </div>

                          <div className="form-bottom-padding">
                              <Field name="generalComments" type="textarea" component={BasicField} placeholder="General Comments" label="General Comments" />
                          </div>

                          <div className="row">{/*row*/}
                              <div className="col-md-offset-1 col-md-10">{/*col-md-12*/}
                                    <table className="table table-striped table-bordered">

                                       <thead>
                                         <tr>
                                           <th className="col-md-1">Recommendation</th>
                                           <th className="col-md-3">Drug</th>
                                           <th className="col-md-3">Duration</th>
                                           <th className="col-md-3">Comment</th>
                                         </tr>
                                       </thead>

                                       <tbody>

                                           <tr>
                                              <td> <b>1st Line</b> </td>
                                              <td>
                                                  <Field name="drug1" component={BasicSelectField} className="form-control" onChange={ this.handleDrug1Change }>
                                                   {this.createDrugListDropdown(this.props.drugsList,"dld1")}
                                                  </Field>
                                                  {otherDrug1}
                                              </td>
                                              <td> <Field name="duration1" type="textarea" component={BasicFieldOnlyValidation} cssClass="diagnosiscard-textbox" placeholder="Add Duration"></Field>
                                              </td>
                                              <td> <Field name="comment1" type="textarea" component={BasicFieldOnlyValidation} cssClass="diagnosiscard-textbox" placeholder="Add Comments"></Field>
                                              </td>
                                           </tr>

                                           <tr><td colSpan="4"></td></tr>

                                           <tr>
                                              <td> <b>2nd Line</b> </td>
                                              <td>
                                                  <Field name="drug2" component={BasicSelectField} className="form-control" onChange={ this.handleDrug2Change }>
                                                   {this.createDrugListDropdown(this.props.drugsList,"dld2")}
                                                  </Field>
                                                  {otherDrug2}
                                              </td>
                                              <td> <Field name="duration2" type="textarea" component={BasicFieldOnlyValidation} cssClass="diagnosiscard-textbox" placeholder="Add Duration"></Field>
                                              </td>
                                              <td> <Field name="comment2" type="textarea" component={BasicFieldOnlyValidation} cssClass="diagnosiscard-textbox" placeholder="Add Comments"></Field>
                                              </td>
                                           </tr>

                                           <tr><td colSpan="4"></td></tr>

                                           <tr>
                                              <td> <b>3rd Line</b> </td>
                                              <td>
                                                  <Field name="drug3" component={BasicSelectField} className="form-control" onChange={ this.handleDrug3Change }>
                                                   {this.createDrugListDropdown(this.props.drugsList,"dld3")}
                                                  </Field>
                                                  {otherDrug3}
                                              </td>
                                              <td> <Field name="duration3" type="textarea" component={BasicFieldOnlyValidation} cssClass="diagnosiscard-textbox" placeholder="Add Duration"></Field>
                                              </td>
                                              <td> <Field name="comment3" type="textarea" component={BasicFieldOnlyValidation} cssClass="diagnosiscard-textbox" placeholder="Add Comments"></Field>
                                              </td>
                                           </tr>

                                       </tbody>
                                     </table>
                                  </div>{/*col-md-12*/}
                              </div>{/*row*/}
                            </fieldset>
                          {buttonSet}

                         </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>{/*col-md-12*/}
           </div>{/* AddForm*/}
          {/*ROW 2*/}

      </div>
      )
    }
}

function validateDiagnosisCardForm(values){
  const errors = {};

  if(!values.bacterias){// && values.bacterias.length ===0
    errors.bacterias = 'Please select pathogen(s)';
  }
  else if(values.bacterias.length ===0){
    errors.bacterias = 'Please select pathogen(s)';
  }

  if(!values.drug1){
      errors.drug1 = 'Please select a drug';
  }else if(values.drug1 ==="Other" && !values.otherDrug1){
    errors.otherDrug1 = "Please add other drug"
  }

  if(values.drug2 ==="Other" && !values.otherDrug2){
    errors.otherDrug2 = "Please add other drug"
  }

  if(values.drug3 ==="Other" && !values.otherDrug3){
    errors.otherDrug3 = "Please add other drug"
  }

  return errors;
}

function mapStateToProps(state,ownProps){
    var diagnose_binded = state.diagnoseState.diagnose
    var diagnosiscard_binded = state.diagnoseState.diagnosisCard
    var lastPublishedDiagnosiscard_binded = state.diagnoseState.lastPublishedDiagnosisCard
    var bacteria_binded =_.orderBy(state.bacteriaState.bacteriaList, [function (item) { return item.name; }], ["asc"])
    var drug_binded =_.orderBy(state.drugState.drugList, [function (item) { return item.name; }], ["asc"])
    var diagnosisrecommendationcards=state.diagnoseState.diagnosisCards

    if(ownProps.location.query.previewLastPublished === 'true'){ //this is to repaint screen with published version in case query string previewLastPublished=true is present
      diagnosiscard_binded = lastPublishedDiagnosiscard_binded
    }

    var initialValues ={}
    var cardBactList=[]

    if(diagnosiscard_binded != null){

      if(diagnosiscard_binded.bacterias!=null){
          diagnosiscard_binded.bacterias.map((bact,index)=>{//[{label: 'Grapes', value: '5' }]
            var bacteria={}
            bacteria.label=bact.name
            bacteria.value=bact._id
            cardBactList.push(bacteria)
          })
        }

        if(diagnosiscard_binded.recommendations!=null){
            diagnosiscard_binded.recommendations.map((rec,index)=>{
                if(rec.priority ==="1"){//1st line
                    if(rec.customDrug){
                      initialValues.drug1="Other"
                      initialValues.otherDrug1=rec.customDrug
                    }else{
                      if(rec.drug){
                        initialValues.drug1=rec.drug._id
                      }
                    }
                    initialValues.duration1=rec.duration
                    initialValues.comment1=rec.comment
                }else if(rec.priority ==="2"){//2nd line
                    if(rec.customDrug){
                      initialValues.drug2="Other"
                      initialValues.otherDrug2=rec.customDrug
                    }else{
                      if(rec.drug){
                        initialValues.drug2=rec.drug._id
                      }
                    }
                    initialValues.duration2=rec.duration
                    initialValues.comment2=rec.comment
                }else if(rec.priority ==="3"){//3rd line
                    if(rec.customDrug){
                      initialValues.drug3="Other"
                      initialValues.otherDrug3=rec.customDrug
                    }else{
                      if(rec.drug){
                        initialValues.drug3=rec.drug._id
                      }
                    }
                    initialValues.duration3=rec.duration
                    initialValues.comment3=rec.comment
                }
            })
        }

        initialValues.diagnosisCardId=diagnosiscard_binded._id
        initialValues.generalComments=diagnosiscard_binded.comment
        initialValues.bacterias= cardBactList

    }

    return{
      diagnose:diagnose_binded,
      bacteriaList:bacteria_binded,
      drugsList:drug_binded,
      diagnosiscards:diagnosisrecommendationcards,
      diagnosiscard_binded:diagnosiscard_binded,
      lastPublishedDiagnosiscard_binded:lastPublishedDiagnosiscard_binded,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function mapDispatchToProps(dispatch){
  return {
    diagnoseAction_binded: bindActionCreators(DiagnoseActions, dispatch),
    diagnosisCardAction_binded: bindActionCreators(DiagnosisCardActions, dispatch),
    bacteriaAction_binded: bindActionCreators(BacteriaActions, dispatch),
    drugAction_binded: bindActionCreators(DrugActions, dispatch),
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(DrugsView);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'diagnosiscardForm',  // a unique name for the form
    validate: validateDiagnosisCardForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(DiagnosisCardView));
