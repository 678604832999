import React from 'react'
// import PropTypes from 'prop-types';
import Header from  './Header';
import Footer from  './Footer';
import NavBar from  './NavBar';
import {bindActionCreators} from 'redux'
import { Link, browserHistory, hashHistory } from 'react-router'
import { connect } from 'react-redux'
import * as userApi from '../api/user-api'
import * as configRefApi from '../api/configRef-api';

class TemplateCore extends React.Component {

  constructor (props) {
    super(props)
  }

  render() {
    return(
      <div id="wrapper">
          <Header/>
          <NavBar/>
          <div id="page-wrapper">
              <div id="page-inner">
                <div id="app-alert" className="alert hideAlert text-center col-md-offset-3 col-md-4">
                    <button type="button" className="close">x</button>
                    <span><strong id="alert-message"></strong></span>
                </div>
                <main>
                  {this.props.children} {/*any routes from routes.js will render here*/}
                </main>
                <Footer/>
              </div>
          </div>
      </div>
    )
  }

  componentWillMount() {
    userApi.isAuthenticated(this)
  }

  componentDidMount () {
    if(sessionStorage.getItem('accessToken') !=null){
      configRefApi.getConfigRefList()
    }
  }

  isLoggedIn(message, isLoggedIn, loggedInUserId) {
      if (!isLoggedIn) {
        console.log('User has not logged in. sending to login page')
        hashHistory.push('login')
      } else {
        console.log("loggedInUserId: " +loggedInUserId)
        userApi.getUserById(loggedInUserId)
        .then((response)=>{
          console.log("Loggedin UserName: "+ response.enterpriseId)
        })
      }
  }

}
// TemplateCore.propTypes = {
//   children: PropTypes.object.isRequired
// };

const mapStateToProps = function (store) {
  return {
    //user: store.userState.user,
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCore)
