import * as types from '../actions/action-types';
import initialState from './initialState';

const drugReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_DRUGLIST_SUCCESS:
      return {...state, drugList:action.drugList};  //whatever our current state is, add on "drugList"

    case types.GET_DRUG_SUCCESS:
      return {...state, drug:action.drug};  //whatever our current state is, add on "drug"

    case types.GET_DRUGBYID_SUCCESS:
      return {...state, drug:action.drug};  //whatever our current state is, add on "drug"

    case types.GET_DRUGDOSAGE_SUCCESS:
      return {...state, drugDosage:action.drugDosage};  //whatever our current state is, add on "drug"

    case types.GET_DRUGDOSING_SUCCESS:
      return {...state, drugDosing:action.drugDosing};  //whatever our current state is, add on "drug"

    case types.GETLASTPUBLISHED_DRUGDOSING_SUCCESS:
      return {...state, lastPublishedDrugDosing:action.lastPublishedDrugDosing};
  }
  return state;
}

export default drugReducer
