import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router, browserHistory, hashHistory } from 'react-router'
import configureStore from './configureStore'
import {Routes} from './routes'
import $ from 'jquery'

const store = configureStore();

const Routing = () => (
  <Provider store={store}>
    <Router history={hashHistory}>
      {Routes()}
    </Router>
  </Provider>
)

render(<Routing />, document.getElementById('app'))
// render(<div />, document.getElementById('app'))

//https://css-tricks.com/learning-react-router/  [FOLLOWING IS FOR EXPRESS SERVER RENDERING STATIC REACT assets]
//here's a caveat though on the server when browserHistory is used on the front-end.
// If the user starts their visit at example.com and then navigates to /users and /widgets, React Router handles
// this scenario as expected. However, if the user starts their visit by typing example.com/widgets directly into the browser,
// or if they refresh on example.com/widgets, then the browser must make at least one request TO THE SERVER (EXPRESS APP SERVER) for /widgets.
// If there isn't a server-side router though, this will deliver a 404:

//USE THE FOLLOWING ON EXPRESS APP SERVER
// serve static assets normally
// app.use(express.static(__dirname + '/public'))
//
// // Handles all routes so you do not get a not found error
// app.get('*', function (request, response){
//     response.sendFile(path.resolve(__dirname, 'public', 'index.html'))
// })


//https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writting-manually
// With Hash History i.s.o Browser History, your URL for the about page would look something like this: http://example.com/#/about
//The part after the hash (#) symbol is not sent to the server. So the server only sees http://example.com/ and sends the index page as expected.
//React-Router will pick up the #/about part and show the correct page.
//
// Downsides:
//
// 'ugly' URLs
// Server-side rendering is not possible with this approach. As far as SEO is concerned, your website consists of a single page with hardly any content on it.
// .
//
// Catch-all
// With this approach you do use Browser History, but just set up a catch-all on the server that sends /* to index.html, effectively giving you much the
// same situation as with Hash History. You do have clean URLs however and you could improve upon this scheme later without having to invalidate
//all your user's favorites.
//
// Downsides:
//
// More complex to set up
// Still no good SEO
