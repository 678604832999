import axios from 'axios';

export function addSMLineItem(SMLineItem,SMId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix/addLineItem/' + SMId, SMLineItem)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw(error.response.data)
    });
}

export function updateSMLineItem(SMLineItem, lineItemId,SMId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix/' + SMId + '/updateLineItem/' + lineItemId, SMLineItem)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw(error.response.data)
    });
}

export function deleteSMLineItem(lineItemId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'susceptibilityMatrix/deleteLineItem/' + lineItemId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw(error.response.data)
    });
}
