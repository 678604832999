import { combineReducers } from 'redux';
import * as types from '../actions/action-types';
import bacteriaReducer from './bacteria-reducer';
import diagnoseReducer from './diagnose-reducer';
import FormReducer from './form-reducer';
import drugReducer from './drug-reducer';
import userReducer from './user-reducer';
import alertReducer from './alert-reducer';
import configRefReducer from './configRef-reducer';
import susceptibilityMatrixReducer from './susceptibilityMatrix-reducer';

const rootReducer = combineReducers({
  bacteriaState: bacteriaReducer,
  diagnoseState: diagnoseReducer,
  drugState:drugReducer,
  configRefState:configRefReducer,
  susceptibilityMatrixState:susceptibilityMatrixReducer,
  userState:userReducer,
  alertState: alertReducer,
  form: FormReducer

})

export default rootReducer
