import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import { Link, browserHistory, hashHistory } from 'react-router';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import BasicField from '../components/common/BasicField';
import BasicFieldOnlyValidation from '../components/common/BasicFieldOnlyValidation';
import BasicSelectField from '../components/common/BasicSelectField';
import * as DrugActions from '../actions/drug-actions';
import * as DrugDosageActions from '../actions/drugdosage-actions';
import * as DiagnoseActions from '../actions/diagnose-actions';
import * as configRefApi from '../api/configRef-api';
import DrugDosageListComponent from '../components/DrugDosageListComponent'

class DrugDosageView extends BaseComponent {

    constructor(props) {
        super(props)
        this.addClicked = this.addClicked.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);

        this.state = {
          otherDoseUnit: false,
          otherFrequencyUnit:false,
          otherDuration:false,
          otherDurationUnit:false,
          otherMaxDoseUnit:false,
          age1Fields:false,
          age2Fields:false,
          age1FieldsNeo:false,
          age2FieldsNeo:false
         };
         this.handleDoseUnitChange = this.handleDoseUnitChange.bind(this);
         this.handleFrequencyUnitChange = this.handleFrequencyUnitChange.bind(this);
         this.handleDurationUnitChange = this.handleDurationUnitChange.bind(this);
         this.handleDurationChange = this.handleDurationChange.bind(this);
         this.handleMaxDoseUnitChange = this.handleMaxDoseUnitChange.bind(this);
         this.onEditSetState = this.onEditSetState.bind(this);
         this.handleAgeInclusionChange = this.handleAgeInclusionChange.bind(this);
         this.handleNeoAgeInclusionChange = this.handleNeoAgeInclusionChange.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
      //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
      //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.drugAction_binded.getDrugById(this.props.params.drugId)
      this.props.drugDosageAction_binded.getDosingByDrugById(this.props.params.drugId)
      this.props.drugDosageAction_binded.getlastPublishedDosingDrugById(this.props.params.drugId)
      this.props.diagnoseActions_binded.getDiagnoseList()
    }

    addClicked(e) {
      showAddForm()
      //this.props.reset();
        this.setState({otherDoseUnit: false,otherFrequencyUnit:false,otherDuration:false,
          otherDurationUnit:false,otherMaxDoseUnit:false,age1Fields:false,age2Fields:false,
          age1FieldsNeo:false,age2FieldsNeo:false})
      this.props.drugDosageAction_binded.resetDrugDosageInfo(
        this.props.adminRouteList,this.props.costLevelList,
        this.props.doseUnitList, this.props.ageUnitList, this.props.frequencyUnitList,
        this.props.durationList, this.props.durationUnitList, this.props.maxDoseUnitList)
    }

    onEditSetState(drugDosage){
      if(drugDosage.dosage!= null){
          if(isNonOtherSelection(drugDosage.dosage.unit,this.props.doseUnitList)){
            this.setState({otherDoseUnit: false})
          }else{
            this.setState({otherDoseUnit: true})
          }
      }else{
        this.setState({otherDoseUnit: false})
      }

      if(drugDosage.frequency!= null){
          if(isNonOtherSelection(drugDosage.frequency.unit,this.props.frequencyUnitList)){
            this.setState({otherFrequencyUnit: false})
          }else{
            this.setState({otherFrequencyUnit: true})
          }
      }else{
        this.setState({otherFrequencyUnit: false})
      }

      if(drugDosage.duration!= null){
          if(isNonOtherSelection(drugDosage.duration.time,this.props.durationList)){
            this.setState({otherDuration: false})
          }else{
            this.setState({otherDuration: true})
          }
          if(isNonOtherSelection(drugDosage.duration.unit,this.props.durationUnitList)){
            this.setState({otherDurationUnit: false})
          }else{
            this.setState({otherDurationUnit: true})
          }
      }else{
        this.setState({otherDuration: false})
        this.setState({otherDurationUnit: false})
      }

      if(drugDosage.maxDose!= null){
          if(isNonOtherSelection(drugDosage.maxDose.unit,this.props.maxDoseUnitList)){
            this.setState({otherMaxDoseUnit: false})
          }else{
            this.setState({otherMaxDoseUnit: true})
          }
      }else{
        this.setState({otherMaxDoseUnit: false})
      }

      if(drugDosage.ageRange!= null){
        if(drugDosage.ageRange.from && drugDosage.ageRange.to){
          this.setState({age1Fields: true,age2Fields: true})
        }else{
          this.setState({age1Fields: true,age2Fields: false})
        }
      }
      else{
          this.setState({age1Fields: false,age2Fields: false})
      }

      if(drugDosage.neoNatal!= null){
        if(drugDosage.neoNatal.from && drugDosage.neoNatal.to){
          this.setState({age1FieldsNeo: true,age2FieldsNeo: true})
        }else{
          this.setState({age1FieldsNeo: true,age2FieldsNeo: false})
        }
      }
      else{
          this.setState({age1FieldsNeo: false,age2FieldsNeo: false})
      }

    }

    cancelClicked(e) {
      this.props.reset();//VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
      hideAddForm();
    }

    onSubmit(formProps) {
      //console.log(formProps)
      this.props.drugDosageAction_binded.addOrUpdateDrugDosingItem(formProps,this.props.params.drugId,this.props.drugDosing._id)
      .then(()=>{
        hideAddForm()
        this.props.reset();
        //this.props.drugAction_binded.getDrugById(this.props.params.drugId)
        this.props.drugDosageAction_binded.getDosingByDrugById(this.props.params.drugId)
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
          console.log("Error submitting drug dosage!!", error);
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

    createCostLevelRadio(costLeveList){
        return costLeveList.map((cost,index) =>{
            return(
                <div className="radio-inline control-label form-right-padding" key={index}>
                  <label className="form-right-padding">
                      <Field name="cost" type="radio" component="input" value={cost.code}/> {cost.typeName}
                  </label>
                </div>
            )
        })
    }

    createAdminRouteDropdown(adminRouteList){
      return adminRouteList.map((admnRtType,index) =>{
          return(
              <option key={index} value={admnRtType.code}>{admnRtType.typeName}</option>
          )
      })
    }

    createDoseUnitDropdown(doseUnitList){
      var buffer =[];
      buffer.push(<option key="dud1" value="">Select a Unit</option>)
       doseUnitList.map((du,index) =>{
          buffer.push (<option key={index} value={du.code}>{du.typeName}</option>)
      })
      return buffer;
    }

    createAgeUnitDropdown(ageUnitList,type){
      var buffer =[];
      buffer.push(<option key={"aud"+type} value="">Select a Unit</option>)
       ageUnitList.map((au,index) =>{
          buffer.push (<option key={index} value={au.code}>{au.typeName}</option>)
      })
      return buffer;
    }

    createAgeInclusionDropdown(ageInclusionList){
      var buffer =[];
      buffer.push(<option key={"aid"} value="">Select a Range</option>)
       ageInclusionList.map((ai,index) =>{
          buffer.push (<option key={index} value={ai.code}>{ai.display} ({ai.code})</option>)
      })
      return buffer;
    }


    createFrequencyUnitDropdown(freqUnitList){
      var buffer =[];
      buffer.push(<option key="fud" value="">Select a Frequency</option>)
       freqUnitList.map((fu,index) =>{
          buffer.push (<option key={index} value={fu.code}>{fu.typeName}</option>)
      })
      return buffer;
    }

    createDurationDropdown(durationList){
      var buffer =[];
      buffer.push(<option key="dd1" value="">Select a Duration</option>)
       durationList.map((dd,index) =>{
          buffer.push (<option key={index} value={dd.code}>{dd.typeName}</option>)
      })
      return buffer;
    }

    createDurationUnitDropdown(durationUnitList){
      var buffer =[];
      buffer.push(<option key="durd1" value="">Select a Unit</option>)
       durationUnitList.map((dd,index) =>{
          buffer.push (<option key={index} value={dd.code}>{dd.typeName}</option>)
      })
      return buffer;
    }

    createMaxDoseUnitDropdown(maxDoseUnitList){
      var buffer =[];
      buffer.push(<option key="mdud1" value="">Select a Unit</option>)
       maxDoseUnitList.map((mdu,index) =>{
          buffer.push (<option key={index} value={mdu.code}>{mdu.typeName}</option>)
      })
      return buffer;
    }

    createDiagnosisDropdown(diagnosisList){
      diagnosisList = _.orderBy(diagnosisList, [function (item) { return item.name; }], ["asc"]);
      var buffer =[];
      buffer.push(<option key="dd1" value="">Select a Diagnosis</option>)
       diagnosisList.map((dl,index) =>{
          buffer.push (<option key={index} value={dl._id}>{dl.name}</option>)
      })
      return buffer;
    }

    handleDoseUnitChange(event) {
        if(event.target.value == "OTHER"){
          this.setState({
            otherDoseUnit: true
          })
        }else{
          this.setState({
            otherDoseUnit: false
          })
        }
    }

    handleFrequencyUnitChange(event) {
        if(event.target.value == "OTHER"){
          this.setState({
            otherFrequencyUnit: true
          })
        }else{
          this.setState({
            otherFrequencyUnit: false
          })
        }
    }

    handleDurationChange(event) {
        if(event.target.value == "OTHER"){
          this.setState({
            otherDuration: true
          })
        }else{
          this.setState({
            otherDuration: false
          })
        }
    }

    handleDurationUnitChange(event) {
        if(event.target.value == "OTHER"){
          this.setState({
            otherDurationUnit: true
          })
        }else{
          this.setState({
            otherDurationUnit: false
          })
        }
    }

    handleMaxDoseUnitChange(event) {
        if(event.target.value == "OTHER"){
          this.setState({
            otherMaxDoseUnit: true
          })
        }else{
          this.setState({
            otherMaxDoseUnit: false
          })
        }
    }

    handleAgeInclusionChange(event) {
        if(event.target.value == "<" || event.target.value == ">="){
          this.setState({age1Fields: true,age2Fields: false})
        }else if(event.target.value == ">= to <"){
          this.setState({age1Fields: true,age2Fields: true})
        }else{
          this.setState({age1Fields: false,age2Fields: false})
        }
    }

    handleNeoAgeInclusionChange(event) {
        if(event.target.value == "<" || event.target.value == ">="){
          this.setState({age1FieldsNeo: true,age2FieldsNeo: false})
        }else if(event.target.value == ">= to <"){
          this.setState({age1FieldsNeo: true,age2FieldsNeo: true})
        }else{
          this.setState({age1FieldsNeo: false,age2FieldsNeo: false})
        }
    }

  render() {
      //const myDrugDosageList = this.props.drug.dosings;
      const handleSubmit = this.props.handleSubmit; //injected by reduxform

      const otherDoseUnit = this.state.otherDoseUnit
      ? <div className="col-md-4 form-no-left-padding form-right-padding"><Field name="otherDoseUnit1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Unit"></Field></div>
      : null;

      const otherFrequencyUnit = this.state.otherFrequencyUnit
      ? <div className="col-md-5 form-no-left-padding form-right-padding"><Field name="otherFrequencyUnit1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Unit"></Field></div>
      : null;

      const otherDuration = this.state.otherDuration
      ? <div className="col-md-3 form-no-left-padding form-right-padding"><Field name="otherDuration1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Duration"></Field></div>
      : null;

      const otherDurationUnit = this.state.otherDurationUnit
      ? <div className="col-md-3 form-no-left-padding form-right-padding"><Field name="otherDurationUnit1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Unit"></Field></div>
      : null;

      const otherMaxDoseUnit = this.state.otherMaxDoseUnit
      ? <div className="col-md-4 form-no-left-padding form-right-padding"><Field name="otherMaxDoseUnit1" type="text" component={BasicFieldOnlyValidation} placeholder="Other Unit"></Field></div>
      : null;

      const age1Fields = this.state.age1Fields
      ? <div className="col-md-3">
             <div className="col-md-4 form-no-left-padding form-right-padding">
                <Field name="age1" type="text" component={BasicFieldOnlyValidation} placeholder="Age"></Field>
             </div>
             <div className="col-md-8 form-no-left-padding form-right-padding">
                <Field name="age1Unit" component={BasicSelectField}>
               {/*<Field name="fromAgeUnit" component="select" className="form-control">*/}
                 {this.createAgeUnitDropdown(this.props.ageUnitList,"age1u")}
               </Field>
             </div>
        </div>
      : null;

        const age2Fields = this.state.age2Fields
        ? <div className="col-md-3">
             <div className="col-md-4 form-no-left-padding form-right-padding">
                <Field name="age2" type="text" component={BasicFieldOnlyValidation} placeholder="Age"></Field>
             </div>
             <div className="col-md-8 form-no-left-padding form-right-padding">
                <Field name="age2Unit" component={BasicSelectField}>
               {/*<Field name="toAgeUnit" component="select" className="form-control">*/}
                 {this.createAgeUnitDropdown(this.props.ageUnitList,"age2u")}
               </Field>
             </div>
         </div>
        : null;

        const age1FieldsNeo = this.state.age1FieldsNeo
        ? <div className="col-md-3">
               <div className="col-md-4 form-no-left-padding form-right-padding">
                  <Field name="age1Neo" type="text" component={BasicFieldOnlyValidation} placeholder="Age"></Field>
               </div>
               <div className="col-md-8 form-no-left-padding form-right-padding">
                  <Field name="age1UnitNeo" component={BasicSelectField}>
                 {/*<Field name="fromAgeUnit" component="select" className="form-control">*/}
                   {this.createAgeUnitDropdown(this.props.ageUnitList,"age1uNeo")}
                 </Field>
               </div>
          </div>
        : null;

          const age2FieldsNeo = this.state.age2FieldsNeo
          ? <div className="col-md-3">
               <div className="col-md-4 form-no-left-padding form-right-padding">
                  <Field name="age2Neo" type="text" component={BasicFieldOnlyValidation} placeholder="Age"></Field>
               </div>
               <div className="col-md-8 form-no-left-padding form-right-padding">
                  <Field name="age2UnitNeo" component={BasicSelectField}>
                 {/*<Field name="toAgeUnit" component="select" className="form-control">*/}
                   {this.createAgeUnitDropdown(this.props.ageUnitList,"age2uNeo")}
                 </Field>
               </div>
           </div>
          : null;

          var publishedOrDraft = "";
          if(this.props.drugDosing !=null){
            if(this.props.drugDosing.published || this.props.location.query.previewLastPublished ==='true'){
              publishedOrDraft = <span className="space bg-success"> Published </span>
            }else{
              publishedOrDraft = <span className="space purple"> Draft </span>
            }
          }

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                          {this.props.drug.name} {publishedOrDraft}
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm */}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           Dosing Information
                           <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                      </div>
                      <div className="panel-body">{/* panel-body*/}
                        <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                          <input type="hidden" name="drugDosageId" value=""/>
                          <div className="form-group form-bottom-padding">
                              <label className="col-md-3 control-label">Administration Route*</label>
                              <div className="col-md-3">
                                <Field name="adminRoute" component="select" className="form-control">
                                  {this.createAdminRouteDropdown(this.props.adminRouteList)}
                                </Field>
                              </div>
                          </div>

                          <div className="form-group form-bottom-padding">
                              <label className="col-md-3 control-label">Diagnosis</label>
                              <div className="col-md-3">
                                  <Field name="diagnosis" component="select" className="form-control">
                                    {this.createDiagnosisDropdown(this.props.diagnosisList)}
                                  </Field>
                              </div>
                          </div>

                          <div className="form-group form-bottom-padding">
                               <label className="col-md-3 control-label">Prefix</label>
                               <div className="col-md-4 form-right-padding">
                                  <Field name="prefix" type="text" component={BasicFieldOnlyValidation} placeholder="Prefix"></Field>
                               </div>
                          </div>

                          <div className="form-group form-bottom-padding">
                               <label className="col-md-3 control-label">Neonatal Age</label>
                               <div className="col-md-3">
                                  {/*<Field name="ageInclusion" component="select" className="form-control">*/}
                                  <Field name="ageInclusionNeo" onChange={ this.handleNeoAgeInclusionChange } component={BasicSelectField}>
                                     {this.createAgeInclusionDropdown(this.props.ageInclusionList)}
                                   </Field>
                               </div>
                                {age1FieldsNeo}
                                {age2FieldsNeo}
                          </div>

                          <div className="form-group form-bottom-padding">
                               <label className="col-md-3 control-label">Age</label>
                               <div className="col-md-3">
                                  {/*<Field name="ageInclusion" component="select" className="form-control">*/}
                                  <Field name="ageInclusion" onChange={ this.handleAgeInclusionChange } component={BasicSelectField}>
                                     {this.createAgeInclusionDropdown(this.props.ageInclusionList)}
                                   </Field>
                               </div>
                                {age1Fields}
                                {age2Fields}
                          </div>

                          <div className="form-group form-bottom-padding">
                               <label className="col-md-3 control-label">Dose*</label>
                               <div className="col-md-6">
                                   <div className="col-md-3 form-no-left-padding form-right-padding">
                                      <Field name="dose" type="text" component={BasicFieldOnlyValidation} placeholder="Dose"></Field>
                                   </div>
                                   <div className="col-md-5 form-no-left-padding  form-right-padding">
                                     <Field name="doseUnit" onChange={ this.handleDoseUnitChange } component={BasicSelectField}>
                                     {/*<Field name="doseUnit" onChange={ this.handleDoseUnitChange } component="select" className="form-control">*/}
                                       {this.createDoseUnitDropdown(this.props.doseUnitList)}
                                     </Field>
                                   </div>
                                   {otherDoseUnit}
                                </div>
                          </div>

                          <div className="form-group form-bottom-padding">
                               <label className="col-md-3 control-label">Frequency</label>
                               <div className="col-md-5">
                                   {/*<div className="col-md-3 form-no-left-padding form-right-padding">
                                      <Field name="frequency" type="text" component={BasicFieldOnlyValidation} placeholder="Freq"></Field>
                                   </div>*/}
                                   <div className="col-md-7 form-no-left-padding  form-right-padding">
                                     <Field name="frequencyUnit" onChange={ this.handleFrequencyUnitChange } component="select" className="form-control">
                                       {this.createFrequencyUnitDropdown(this.props.frequencyUnitList)}
                                     </Field>
                                   </div>
                                   {otherFrequencyUnit}
                                </div>
                          </div>

                          <div className="form-group form-bottom-padding">
                               <label className="col-md-3 control-label">Max Dose</label>
                               <div className="col-md-6">
                                   <div className="col-md-3 form-no-left-padding form-right-padding">
                                      <Field name="maxDose" type="text" component={BasicFieldOnlyValidation} placeholder="Dose"></Field>
                                   </div>
                                   <div className="col-md-5 form-no-left-padding  form-right-padding">
                                      <Field name="maxDoseUnit" onChange={ this.handleMaxDoseUnitChange } component={BasicSelectField}>
                                     {/*<Field name="maxDoseUnit" onChange={ this.handleMaxDoseUnitChange } component="select" className="form-control">*/}
                                       {this.createMaxDoseUnitDropdown(this.props.maxDoseUnitList)}
                                     </Field>
                                   </div>
                                   {otherMaxDoseUnit}
                              </div>
                          </div>

                          <div className="form-group form-bottom-padding">
                              <label className="col-md-3 control-label">Cost*</label>
                              <div className="col-md-6">
                                {this.createCostLevelRadio(this.props.costLevelList)}
                              </div>
                          </div>

                          <div className="form-group form-bottom-padding">
                               <label className="col-md-3 control-label">Duration</label>
                               <div className="col-md-9">
                                   <div className="col-md-3 form-no-left-padding form-right-padding">
                                      <Field name="duration" onChange={ this.handleDurationChange } component={BasicSelectField}>
                                     {/*<Field name="duration" onChange={ this.handleDurationChange } component="select" className="form-control">*/}
                                       {this.createDurationDropdown(this.props.durationList)}
                                     </Field>
                                   </div>
                                    {otherDuration}
                                   <div className="col-md-3 form-no-left-padding form-right-padding">
                                      <Field name="durationUnit" onChange={ this.handleDurationUnitChange } component={BasicSelectField}>
                                     {/*<Field name="durationUnit" onChange={ this.handleDurationUnitChange } component="select" className="form-control">*/}
                                       {this.createDurationUnitDropdown(this.props.durationUnitList)}
                                     </Field>
                                   </div>
                                   {otherDurationUnit}
                                </div>
                          </div>

                          <Field name="notes" type="textarea" component={BasicField} placeholder="Notes" label="Notes" />

                          <div className="form-group">
                             <div className="col-md-offset-3 col-md-9">
                               <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                               {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                               <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                             </div>
                           </div>
                        </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>
           </div>{/* AddForm*/}
          {/*ROW 2*/}

          {/*ROW 3*/}
          <DrugDosageListComponent drug={this.props.drug} drugDosing={this.props.drugDosing}   adminRouteList={this.props.adminRouteList}
              costLevelList={this.props.costLevelList} doseUnitList={this.props.doseUnitList} ageUnitList={this.props.ageUnitList}
              frequencyUnitList={this.props.frequencyUnitList}  durationList={this.props.durationList}
              durationUnitList={this.props.durationUnitList} maxDoseUnitList={this.props.maxDoseUnitList}
              addClicked={this.addClicked} onEditSetState={this.onEditSetState} lastPublishedDrugDosing={this.props.lastPublishedDrugDosing}
              previewLastPublished = {this.props.location.query.previewLastPublished}/>
          {/*ROW 3*/}

      </div>
      )
    }
}

function validateDrugDosageForm(values){
  const errors = {};

  if(values.ageInclusionNeo == "<" || values.ageInclusionNeo == ">="){
      if(isNaN(Number(values.age1Neo))){
        errors.age1Neo = 'Must be a number'
      }
      else if(!values.age1UnitNeo){
        errors.age1UnitNeo = 'Please select a unit'
      }
    }else if(values.ageInclusionNeo == ">= to <"){
      if(isNaN(Number(values.age1Neo))){
        errors.age1Neo = 'Must be a number'
      }
      else if(!values.age1UnitNeo){
        errors.age1UnitNeo = 'Please select a unit'
      }

      if(isNaN(Number(values.age2Neo))){
        errors.age2Neo = 'Must be a number'
      }
      else if(!values.age2UnitNeo){
        errors.age2UnitNeo = 'Please select a unit'
      }
  }

  if(values.ageInclusion == "<" || values.ageInclusion == ">="){
      if(isNaN(Number(values.age1))){
        errors.age1 = 'Must be a number'
      }
      else if(!values.age1Unit){
        errors.age1Unit = 'Please select a unit'
      }
    }else if(values.ageInclusion == ">= to <"){
      if(isNaN(Number(values.age1))){
        errors.age1 = 'Must be a number'
      }
      else if(!values.age1Unit){
        errors.age1Unit = 'Please select a unit'
      }

      if(isNaN(Number(values.age2))){
        errors.age2 = 'Must be a number'
      }
      else if(!values.age2Unit){
        errors.age2Unit = 'Please select a unit'
      }
  }

  if(!values.dose){
    errors.dose = 'Please enter dosage';
  }

  if(!values.doseUnit){
    errors.doseUnit = 'Please select a unit'
  }
  else if(values.doseUnit == "OTHER" && !values.otherDoseUnit1){
    errors.otherDoseUnit1 = 'Please enter other unit';
  }

  if(values.frequencyUnit == "OTHER" && !values.otherFrequencyUnit1){
    errors.otherFrequencyUnit1 = 'Please enter other unit';
  }

  if(values.maxDose){
    // if(isNaN(Number(values.maxDose))){
    //   errors.maxDose = 'Must be a number'
    // }
    //else
    if(!values.maxDoseUnit){
      errors.maxDoseUnit = 'Please select a unit'
    }
    else if(values.maxDoseUnit == "OTHER" && !values.otherMaxDoseUnit1){
      errors.otherMaxDoseUnit1 = 'Please enter other unit';
    }
  }

  if(values.duration){
    if(values.duration == "OTHER" && !values.otherDuration1){
      errors.otherDuration1 = 'Please enter other duration';
    }
    else if(!values.durationUnit){
      errors.durationUnit = 'Please select a unit'
    }

    if(values.durationUnit == "OTHER" && !values.otherDurationUnit1){
      errors.otherDurationUnit1 = 'Please enter other unit';
    }
  }

    return errors;
}

function isNonOtherSelection(str,list) {
  var ret = false;

  if(str.length === 0){//first option value will be empty string
    ret= true;
  }else{
    for(var i=0; i<list.length -1; i++){//skip the last item as "Other" is ALWAYS the last item in reference service
      if(list[i].code === str){
        ret = true;
        break;
      }
    }
  }

  return ret;
}

function mapStateToProps(state){

    var adminRoute = {}, adminRouteList = [], costLevel = {}, costLevelList = [], doseUnit = {}, doseUnitList = [],
        ageUnit = {},ageUnitList = [],frequencyUnit = {},frequencyUnitList = [],duration = {},durationList = [],
        durationUnit = {},durationUnitList = [],maxDoseUnit = {},maxDoseUnitList = [], ageInclusion = {}, ageInclusionList = []

    var drug_binded = state.drugState.drug
    var drugdosage_binded = state.drugState.drugDosage
    var drugDosing_binded = state.drugState.drugDosing
    var diagnoseList_binded= state.diagnoseState.diagnoseList
    var lastPublishedDrugDosing_binded = state.drugState.lastPublishedDrugDosing

      if(state.configRefState.configRef != null){
      adminRoute = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Administration Route";});
      adminRouteList = adminRoute[0].list;
      costLevel = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Drug Cost Level";});
      costLevelList = costLevel[0].list;
      doseUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Dose Unit";});
      doseUnitList = doseUnit[0].list;
      ageUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Age Unit";});
      ageUnitList = ageUnit[0].list;
      frequencyUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Frequency Units";});
      frequencyUnitList = frequencyUnit[0].list;
      duration = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Dosage Duration";});
      durationList = duration[0].list;
      durationUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Duration Unit";});
      durationUnitList = durationUnit[0].list;
      maxDoseUnit = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Max Dose Unit";});
      maxDoseUnitList = maxDoseUnit[0].list;
      ageInclusion = state.configRefState.configRef.filter(function( obj ) {return obj.name === "Age Inclusion";});
      ageInclusionList = ageInclusion[0].list;
    }

    var ageRange = {},ageRangeFrom = {}

    if(drugdosage_binded != null){//drugdosage_binded != null
        var initialValues = {
          drugDosageId:drugdosage_binded._id,
          adminRoute:drugdosage_binded.routeOfAdministration,
          cost:drugdosage_binded.costLevel,
          notes:drugdosage_binded.notes,
          prefix:drugdosage_binded.prefix
        }

        //setting initial vlaues for neonatal Age fields
        if(drugdosage_binded.neoNatal!= null){
          initialValues.neonatalAge = drugdosage_binded.neoNatal.age
          initialValues.neonatalAgeUnit = drugdosage_binded.neoNatal.unit
        }

        //setting initial vlaues for NeonatalAge fields
        if(drugdosage_binded.neoNatal!= null){
          if(drugdosage_binded.neoNatal.from && drugdosage_binded.neoNatal.to){
              initialValues.ageInclusionNeo = ">= to <"
              initialValues.age1Neo = drugdosage_binded.neoNatal.from.age
              initialValues.age1UnitNeo = drugdosage_binded.neoNatal.from.unit
              initialValues.age2Neo = drugdosage_binded.neoNatal.to.age
              initialValues.age2UnitNeo = drugdosage_binded.neoNatal.to.unit
          }
          else if(drugdosage_binded.neoNatal.from){
            initialValues.ageInclusionNeo = ">="
            initialValues.age1Neo = drugdosage_binded.neoNatal.from.age
            initialValues.age1UnitNeo = drugdosage_binded.neoNatal.from.unit
          }
          else{
            initialValues.ageInclusionNeo = "<"
            initialValues.age1Neo = drugdosage_binded.neoNatal.to.age
            initialValues.age1UnitNeo = drugdosage_binded.neoNatal.to.unit
          }
        }

        //setting initial vlaues for Age fields
        if(drugdosage_binded.ageRange!= null){
          if(drugdosage_binded.ageRange.from && drugdosage_binded.ageRange.to){
              initialValues.ageInclusion = ">= to <"
              initialValues.age1 = drugdosage_binded.ageRange.from.age
              initialValues.age1Unit = drugdosage_binded.ageRange.from.unit
              initialValues.age2 = drugdosage_binded.ageRange.to.age
              initialValues.age2Unit = drugdosage_binded.ageRange.to.unit
          }
          else if(drugdosage_binded.ageRange.from){
            initialValues.ageInclusion = ">="
            initialValues.age1 = drugdosage_binded.ageRange.from.age
            initialValues.age1Unit = drugdosage_binded.ageRange.from.unit
          }
          else{
            initialValues.ageInclusion = "<"
            initialValues.age1 = drugdosage_binded.ageRange.to.age
            initialValues.age1Unit = drugdosage_binded.ageRange.to.unit
          }
        }

        //setting initial vlaues for dose fields
        if(drugdosage_binded.dosage!= null){
          initialValues.dose = drugdosage_binded.dosage.dose
          if(isNonOtherSelection(drugdosage_binded.dosage.unit,doseUnitList)){
            initialValues.doseUnit = drugdosage_binded.dosage.unit
          }else{
            initialValues.doseUnit = "OTHER"
            initialValues.otherDoseUnit1 = drugdosage_binded.dosage.unit
          }
        }
        //setting initial vlaues for frequency fields
        if(drugdosage_binded.frequency!= null){
          //initialValues.frequency = drugdosage_binded.frequency.amount
          if(isNonOtherSelection(drugdosage_binded.frequency.unit,frequencyUnitList)){
            initialValues.frequencyUnit = drugdosage_binded.frequency.unit
          }else{
            initialValues.frequencyUnit = "OTHER"
            initialValues.otherFrequencyUnit1 = drugdosage_binded.frequency.unit
          }
        }
        //setting initial vlaues for duration fields
        if(drugdosage_binded.duration!= null){
          if(isNonOtherSelection(drugdosage_binded.duration.time,durationList)){
            initialValues.duration = drugdosage_binded.duration.time
          }else{
            initialValues.duration = "OTHER"
            initialValues.otherDuration1 = drugdosage_binded.duration.time
          }

          if(isNonOtherSelection(drugdosage_binded.duration.unit,durationUnitList)){
            initialValues.durationUnit = drugdosage_binded.duration.unit
          }else{
            initialValues.durationUnit = "OTHER"
            initialValues.otherDurationUnit1 = drugdosage_binded.duration.unit
          }
        }
        //setting initial vlaues for maxdose fields
        if(drugdosage_binded.maxDose!= null){
          initialValues.maxDose = drugdosage_binded.maxDose.dose
          if(isNonOtherSelection(drugdosage_binded.maxDose.unit,maxDoseUnitList)){
            initialValues.maxDoseUnit = drugdosage_binded.maxDose.unit
          }else{
            initialValues.maxDoseUnit = "OTHER"
            initialValues.otherMaxDoseUnit1 = drugdosage_binded.maxDose.unit
          }
        }
        //setting initialValues for diagnosis
        if(drugdosage_binded.diagnosis!= null){
          initialValues.diagnosis = drugdosage_binded.diagnosis._id
        }

    }//drugdosage_binded != null

    return{
      drug:drug_binded,
      drugDosing:drugDosing_binded,
      adminRouteList : adminRouteList,
      costLevelList: costLevelList,
      doseUnitList:doseUnitList,
      ageUnitList: ageUnitList,
      frequencyUnitList:frequencyUnitList,
      durationList:durationList,
      durationUnitList:durationUnitList,
      maxDoseUnitList:maxDoseUnitList,
      ageInclusionList:ageInclusionList,
      diagnosisList:diagnoseList_binded,
      lastPublishedDrugDosing:lastPublishedDrugDosing_binded,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function mapDispatchToProps(dispatch){
  return {
    drugAction_binded: bindActionCreators(DrugActions, dispatch),
    drugDosageAction_binded: bindActionCreators(DrugDosageActions, dispatch),
    diagnoseActions_binded: bindActionCreators(DiagnoseActions, dispatch),
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(DrugsView);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'drugdosageForm',  // a unique name for the form
    validate: validateDrugDosageForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(DrugDosageView));
