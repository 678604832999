import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
// import { Fa } from 'mdbreact';

class SMLegendComponent extends BaseComponent {

    constructor(props) {
        super(props)
        this.state = {
          legendOpened: false
        };
    }

    componentDidMount(){
    }

    toggleLegend = () => {
      if(this.state.legendOpened){
        this.setState({legendOpened: false})
      }else{
        this.setState({legendOpened: true})
      }
    }

    render() {

      return(
        <div onClick={this.toggleLegend} className={"SMLegend mt-4 " + (!this.state.legendOpened ? "legend-closed" : "")}>
          <div className="d-flex sm-legend-group clearfix">
            <div className="legend-square range1 text-center legend-text"></div>
            <div className="align-self-center ml-2 legend-text">90-100% susceptible</div>
          </div>
          <div className="d-flex sm-legend-group ml-4 clearfix">
            <div className="legend-square range1 text-center"></div>
            <div className="align-self-center ml-2 legend-text">Susceptible</div>
          </div>
          <div className="d-flex sm-legend-group ml-4 clearfix">
            <div className="legend-square range2 text-center"></div>
            <div className="align-self-center ml-2 legend-text">80-89% susceptible</div>
          </div>
          <div className="d-flex sm-legend-group ml-4 clearfix">
            <div className="legend-square range3 text-center"></div>
            <div className="align-self-center ml-2 legend-text">70-79% susceptible</div>
          </div>
          <div className="d-flex sm-legend-group ml-4 clearfix">
            <div className="legend-square range4 text-center"></div>
            <div className="align-self-center ml-2 legend-text">&#60;70% susceptible</div>
          </div>
          <div className="d-flex sm-legend-group ml-4 clearfix">
            <div className="legend-square range-nottested text-center">-</div>
            <div className="align-self-center ml-2 legend-text">Drug not tested</div>
          </div>
          <div className="d-flex sm-legend-group ml-4 clearfix">
            <div className="legend-square range-r text-center">R</div>
            <div className="align-self-center ml-2 legend-text">Resistant</div>
          </div>
          <div className="sm-fade"></div>
        </div>
      )
    }
 }


export default SMLegendComponent;
