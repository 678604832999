import initialState from './initialState';
import {reducer as formReducer} from 'redux-form';
import * as types from '../actions/action-types';

//USE THE FOLLOWING TO RESET FORM AFTER SUCCESS SUBMIT.
const FormReducer = formReducer.plugin({
  // SMForm: (state, action) => {   //---Name of the form
  //     switch(action.type) {
  //       case types.ADD_SM_SUCCESS:
  //         return undefined;       // <--- blow away form data
  //       default:
  //         return state;
  //     }
  //   },
  //   bacteriaForm: (state, action) => {   //---Name of the form
  //       switch(action.type) {
  //         case types.ADD_BACTERIA_SUCCESS:
  //           return undefined;       // <--- blow away form data
  //         default:
  //           return state;
  //       }
  //     }
})

export default FormReducer
