import axios from 'axios';

export function getDrugsList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'drugs')
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getDrugById(drugId) {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'drug/'+ drugId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}


export function addDrug(drug) {
  return axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'drug', drug)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function updateDrug(drug, drugId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'drug/' + drugId, drug)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function deleteDrug(drugId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'drug/delete/' + drugId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}
