import axios from 'axios';

export function getDiagnoseList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'diagnoses')
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getDiagnoseById(diagnoseId) {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'diagnosis/'+ diagnoseId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function addDiagnose(diagnose) {
  return axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'diagnosis', diagnose)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function updateDiagnose(diagnose, diagnoseId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'diagnosis/' + diagnoseId, diagnose)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function deleteDiagnose(diagnoseId) {
console.log(diagnoseId);
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'delete/diagnosis/' + diagnoseId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}
