import * as types from '../actions/action-types';
import initialState from './initialState';

const bacteriaReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_BACTERIALIST_SUCCESS:
      return {...state, bacteriaList:action.bacteriaList};  //whatever our current state is, add on "bacteriaList"

    case types.GET_BACTERIA_SUCCESS:
      return {...state, bacteria:action.bacteria};  //whatever our current state is, add on "bacteria"

  }
  return state;
}

export default bacteriaReducer
