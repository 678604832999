import * as types from '../actions/action-types';
import initialState from './initialState';

const userReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_USERLIST_SUCCESS:
      return {...state, userList:action.userList};  //whatever our current state is, add on "userList"

    case types.GET_USER_SUCCESS:
      return {...state, user:action.user};  //whatever our current state is, add on "user"

    case 'user/loggedInUser':
      return {...state, loggedInUser:action.loggedInUser}; 

  }
  return state;
}

export default userReducer
