import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import BasicField from '../components/common/BasicField';
import BasicDateTimeField from '../components/common/BasicDateTimeField';
import * as configRefApi from '../api/configRef-api';
import * as AlertActions from '../actions/alert-actions';
import AlertListComponent from '../components/AlertListComponent';
//import moment from 'moment';
//import 'react-widgets/dist/css/react-widgets.css'
//let momentLocalizer = require('react-widgets-moment');
//momentLocaliser(moment)
import momentLocalizer from 'react-widgets-moment';
import * as DrugActions from '../actions/drug-actions';

class AlertView extends BaseComponent {

    constructor(props) {
        super(props)
        momentLocalizer();
        this.cancelClicked = this.cancelClicked.bind(this);
        this.addClicked = this.addClicked.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
      //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
      //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.alertAction_binded.getAlertList()
      this.props.drugAction_binded.getDrugList()
    }

    onSubmit (formProps) {
      //console.log(formProps)
      this.props.alertAction_binded.addOrUpdateAlert(formProps)
      .then(()=>{
        hideAddForm()
        this.props.reset();
        this.props.alertAction_binded.getAlertList()
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
        console.log("Error submitting alert!!", error);
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

    cancelClicked(e) {
      this.props.reset(); //VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
      hideAddForm();
    }

    addClicked(e) {
      //this.props.reset();
      this.props.alertAction_binded.resetAlertInfo(this.props.faceupAlertCategoryList)
    }

    createFaceUpAlertRadio(faceupAlertCategoryList){
        return faceupAlertCategoryList.map((faceupAlertCat,index) =>{
            return(
                <div className="radio" key={index}>
                  <label>
                      <Field name="faceup" type="radio" component="input" value={faceupAlertCat.code}/> {faceupAlertCat.typeName}
                  </label>
                </div>
            )
        })
    }

    getDrugTypeName(drugTypeCode,drugTypeList){
      var drugType = drugTypeList.filter(function( obj ) {
        return obj.code === drugTypeCode;
      });
      return drugType[0].typeName;
    }

    createDrugDropdown(drugList){
      var buffer =[];
      buffer.push(<option key="ad1" value="">Select a Drug</option>)
       drugList.map((drug,index) =>{
          buffer.push (<option key={index} value={drug._id}>{drug.name} - {this.getDrugTypeName(drug.drugType,this.props.drugTypeList)}</option>)
      })
      return buffer;
    }

    render() {
      const myAlertList = this.props.alertList_binded;
      const handleSubmit = this.props.handleSubmit; //injected by reduxform

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                          Alert <small></small>
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm*/}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           Alert Information
                           <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                      </div>
                      <div className="panel-body">{/* panel-body*/}
                        <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                          <input type="hidden" name="alertId" value=""/>
                          <Field name="alertText" type="text" component={BasicField} placeholder="Name" label="Alert Text*" />

                          <div className="form-group">
                              <label className="col-md-3 control-label">Faceup Alert*</label>
                              <div className="col-md-6">
                                {this.createFaceUpAlertRadio(this.props.faceupAlertCategoryList)}
                              </div>
                          </div>

                          <div className="form-group">
                              <label className="col-md-3 control-label">Activation Date*</label>
                              <div className="col-md-4">
                                <Field name="activationDate" showTime={false} component={BasicDateTimeField} placeholder="Activation Date" label="Activation Date" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-3 control-label">Expiration Date*</label>
                                <div className="col-md-4">
                                  <Field name="expirationDate" showTime={false} component={BasicDateTimeField} placeholder="Expiration Date" label="Expiration Date" />
                                  </div>
                              </div>

                              <div className="form-group">
                                  <label className="col-md-3 control-label">Drug</label>
                                  <div className="col-md-6">
                                    <Field name="drug" component="select" className="form-control">
                                      {this.createDrugDropdown(this.props.drugList_binded)}
                                    </Field>
                                  </div>
                              </div>

                            <div className="form-group">
                               <div className="col-md-offset-3 col-md-9">
                                 <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                                 {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                                 <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                               </div>
                             </div>

                        </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>
           </div>{/* AddForm*/}
          {/*ROW 2*/}

          {/*ROW 3*/}
          <AlertListComponent  alertList={myAlertList}  faceupAlertCategoryList={this.props.faceupAlertCategoryList} addClicked={this.addClicked}/>
          {/*ROW 3*/}

      </div>
      )
    }
}

function validateAlertForm(values){
  const errors = {};
    if(!values.alertText){
      errors.alertText = 'Please enter a text for alert';
    }

    if(!values.activationDate){
      errors.activationDate = 'Please enter activation date for alert';
    }
    else if(moment(values.activationDate).isBefore(new Date(),'day')){
      errors.activationDate = 'Activation date cannot be in the past';
    }

    if(!values.expirationDate){
      errors.expirationDate = 'Please enter expiration date for alert';
    }
    else if(moment(values.expirationDate).isBefore(new Date(),'day')){
      errors.expirationDate = 'Expiration date cannot be in the past';
    }

    if(moment(values.expirationDate).isBefore(values.activationDate,'day')){
      errors.expirationDate = 'Expiration date cannot be before Activation date';
    }

    return errors;
}

function mapStateToProps(state){

    var alert_binded = state.alertState.alert
    var initialValues={};

    var faceupAlertCategory = {}, faceupAlertCategoryList = [], drugType = {},drugTypeList = [];
    if(state.configRefState.configRef != null){
      faceupAlertCategory = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Faceup Alert Category";
      });
      faceupAlertCategoryList = faceupAlertCategory[0].list;

      drugType = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Drug Category";
      });
      drugTypeList = drugType[0].list;
    }

    if(alert_binded != null){

      var drugId
      if(alert_binded.drug != null){
        drugId = alert_binded.drug
      }

      initialValues = {
        alertId:alert_binded._id,
        alertText:alert_binded.alertText,
        faceup:alert_binded.faceup,
        activationDate:alert_binded.activationDate,
        expirationDate:alert_binded.expirationDate,
        drug:drugId
      }
    }

    return{
      alertList_binded: state.alertState.alertList,
      faceupAlertCategoryList : faceupAlertCategoryList,
      drugTypeList:drugTypeList,
      drugList_binded: _.orderBy(state.drugState.drugList, [function (item) { return item.name; }], ["asc"]),
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function mapDispatchToProps(dispatch){
  return {
    alertAction_binded: bindActionCreators(AlertActions, dispatch),
    drugAction_binded: bindActionCreators(DrugActions, dispatch)
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(AlertView);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'alertForm',  // a unique name for the form
    validate: validateAlertForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(AlertView));
