import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import {bindActionCreators} from 'redux';
import { Link, browserHistory, hashHistory } from 'react-router';
import BaseComponent from '../components/common/BaseComponent';
import * as configRefApi from '../api/configRef-api';
import BasicField from '../components/common/BasicField';
import * as DiagnoseActions from '../actions/diagnose-actions';


class DiagnosisView extends BaseComponent {

    constructor(props) {
        super(props)
        this.populateForm = this.populateForm.bind(this);
        this.addClicked = this.addClicked.bind(this);
          this.cancelClicked = this.cancelClicked.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.diagnoseAction_binded.getDiagnoseList()
    }

    onSubmit (formProps) {
      this.props.diagnoseAction_binded.addOrUpdateDiagnose(formProps)
      .then(()=>{
        hideAddForm()
        this.props.diagnoseAction_binded.getDiagnoseList()
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
        showAlert("Error submitting Diagnosis!!", "error");
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

populateForm(e,diagnose){
  this.props.diagnoseAction_binded.loadDiagnoseInfo(diagnose);
}

diagnosisCard(e,diagnose) {
  hashHistory.push('diagnosiscard/'+ diagnose._id)
}

deleteRecord(e,diagnose) {
  if(confirm('Are you sure you want to delete?')){
    this.props.diagnoseAction_binded.deleteDiagnose(diagnose)
    .then(()=>{
      this.props.diagnoseAction_binded.getDiagnoseList()
      // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
    })
    .catch((error)=>{
      showAlert("Error deleting Diagnosis!", "error");
      //this.setState({updateProfileResult: error.message}); //this will cause render to be called
    })
  }
}

addClicked(e) {
  this.props.diagnoseAction_binded.resetDiagnoseInfo(this.props.diagnoseGroupNamesList)
}

cancelClicked(e) {
  //this.props.reset(); //VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
  hideAddForm();
}


getDiagnosisGroupName(diagGroupNameCode,diagnoseGroupNamesList){
  var groupName = diagnoseGroupNamesList.filter(function( obj ) {
    return obj.code === diagGroupNameCode;
  });
  if(groupName && groupName.length > 0 && groupName[0]){
    return groupName[0].typeName;
  }
  return "Other"

}

createDiagnosisGroupNameDropdown(diagnoseGroupNamesList){
  return diagnoseGroupNamesList.map((groupName,index) =>{
      return(
          <option key={index} value={groupName.code}>{groupName.typeName}</option>
      )
  })
}

getDiagnosisCardStatus(diagnose){
  if(!_.isEmpty(diagnose.latestDiagnosisCard)){
      if(diagnose.latestDiagnosisCard.published){
        return "Published"
      }else{
        return "Draft"
      }
  }else{
    return "---"
  }
}

getDiagnosisCardEditorInfo(diagnose){
  if(!_.isEmpty(diagnose.latestDiagnosisCard)){
    if(diagnose.latestDiagnosisCard.published){
      return "Published by: " + diagnose.latestDiagnosisCard.updatedBy.fullName + " on " + moment.utc(diagnose.latestDiagnosisCard.updatedDt).local().format("MMM DD, YYYY H:mm A");
    }else{
      if(diagnose.latestDiagnosisCard.updatedBy){
        return "Last edited by: " + diagnose.latestDiagnosisCard.updatedBy.fullName +" on " + moment.utc(diagnose.latestDiagnosisCard.updatedDt).local().format("MMM DD, YYYY H:mm A");
      }else{
        return "Created by: " + diagnose.latestDiagnosisCard.createdBy.fullName+ " on " + moment.utc(diagnose.latestDiagnosisCard.createdDt).local().format("MMM DD, YYYY H:mm A");
      }
    }

  }else{
    return "---"
  }
}

getLastPublished(diagnose){
  if(!_.isEmpty(diagnose.latestPublishedDiagnosisCard)){
      return "Published by: " + diagnose.latestPublishedDiagnosisCard.updatedBy.fullName + " on " + moment.utc(diagnose.latestPublishedDiagnosisCard.updatedDt).local().format("MMM DD, YYYY H:mm A");
  }else{
    return "---"
  }
}

    render() {

      //This will sort first ascending by groupName, and for objects that have the same value for property groupName, will sort them ascending by name.
      const myDiagnoseList = _.orderBy(this.props.diagnoseList_binded, [
          function (item) { return item.groupName; },
          function (item) { return item.name; }
        ], ["asc", "asc"]);
      const handleSubmit = this.props.handleSubmit;

      var editPermission = this.currentUserHasEditPermissions();
      var buttonAdd = editPermission
            ? <input type="button" id="Add" className="btn btn-Info btn-space" value="Add" onClick={()=>{this.addClicked(this)}}/>
            : null

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                          Diagnosis <small></small>
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm*/}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                    <div className="panel-heading">
                         Diagnosis Information
                         <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                    </div>
                      <div className="panel-body">{/* panel-body*/}
                      <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                        <input type="hidden" name="diagnoseId" value=""/>
                        <Field name="diagnoseName" type="text" component={BasicField} placeholder="Name" label="Name*" />
                        <Field name="synonyms" type="text" component={BasicField} placeholder="Comma separated list of synonyms" label="Synonyms" />
                        <div className="form-group">
                            <label className="col-md-3 control-label">GroupName*</label>
                            <div className="col-md-6">
                              <Field name="groupName" component="select" className="form-control">
                                {this.createDiagnosisGroupNameDropdown(this.props.diagnoseGroupNamesList)}
                              </Field>
                            </div>
                        </div>

                        <div className="form-group">
                           <div className="col-md-offset-3 col-md-9">
                             <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                             {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                             <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                           </div>
                         </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/*ROW 2*/}


                <div className="row">
                    <div className="col-md-12">

                    <div className="panel panel-default"> {/* panel-default*/}
                        <div className="panel-heading">
                             Diagnoses List{buttonAdd}
                        </div>

                        <div className="panel-body">{/* panel-body*/}
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered table-hover" id="dataTables-example">
                                    <thead>
                                      <tr>
                                        <th className="col-md-2">Group Name</th>
                                        <th className="col-md-2">Diagnosis Name</th>
                                        <th className="col-md-1">Last Published Recommendations</th>
                                        <th className="col-md-1">Recommendations Status</th>
                                        <th className="col-md-2">Recommendations</th>
                                        <th className="col-md-3"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        {
                                          myDiagnoseList && myDiagnoseList.map((diagnose,index) => {
                                          return(
                                            <tr key={index}>
                                              <td>{this.getDiagnosisGroupName(diagnose.groupName,this.props.diagnoseGroupNamesList)}</td>
                                              <td>{diagnose.name}</td>
                                              <td className="user-status">{this.getLastPublished(diagnose)}</td>
                                              <td>{this.getDiagnosisCardStatus(diagnose)}</td>
                                              <td className="user-status">{this.getDiagnosisCardEditorInfo(diagnose)}</td>
                                              <td>
                                                {editPermission
                                                    ?<span>
                                                        <input type="button" className="btn btn-Warning btn-space btn-xs" id="Edit" value="Edit Diagnosis" onClick={()=>{this.populateForm(this,diagnose)}}/>
                                                        <input type="button" className="btn btn-Danger btn-space btn-xs" id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,diagnose)}}/>
                                                    </span>
                                                    : null
                                                  }
                                                <input type="button" className="btn btn-Primary btn-space btn-xs" id="Diagnosis card " value="Recommendation" onClick={()=>{this.diagnosisCard(this,diagnose)}}/>
                                              </td>
                                            </tr>
                                            )
                                          })
                                        }
                                    </tbody>
                                  </table>
                              </div>
                          </div>{/* panel-body*/}
                      </div> {/* panel-default*/}
                    </div>
                </div>


              </div>
      )
    }
}


function validateDiagnoseForm(values){
  const errors = {};

    if(!values.diagnoseName){
      errors.diagnoseName = 'Please enter a name for Diagnosis';
    }

    return errors;
}


function mapStateToProps(state){
    var diagnose_binded = state.diagnoseState.diagnose
    var diagnosiscards_binded=state.diagnoseState.diagnosisCards
    var initialValues={};
    var groupName={};
    var diagnoseGroupNames = [];
    var stringyfiedSynonyms="";

    if(state.configRefState.configRef != null){
      groupName = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Diagnosis Groups";
      });
      diagnoseGroupNames = groupName[0].list;
    }

    if(diagnose_binded != null){

      if(diagnose_binded.synonyms != null){
        stringyfiedSynonyms = diagnose_binded.synonyms.toString();
      }

      var initialValues = {
        diagnoseId:diagnose_binded._id,
        diagnoseName:diagnose_binded.name,
        groupName: diagnose_binded.groupName,
        synonyms: stringyfiedSynonyms,
        diagnosiscards: diagnosiscards_binded
      }
    }

    return{
      diagnoseList_binded: state.diagnoseState.diagnoseList,
      diagnoseGroupNamesList: diagnoseGroupNames,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }

}

function mapDispatchToProps(dispatch){
  return {
    diagnoseAction_binded: bindActionCreators(DiagnoseActions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'diagnosisForm',  // a unique name for the form
    validate: validateDiagnoseForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(DiagnosisView));
