import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link, browserHistory, hashHistory } from 'react-router';
import BaseComponent from '../components/common/BaseComponent';
import * as DrugActions from '../actions/drug-actions';
import { createDosingString } from '../components/common/Utility';

class DrugsListComponent extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){

    }

    populateForm (e,drug) {
      this.props.onEditSetState(drug)
      this.props.drugAction_binded.loadDrugInfo(drug)
    }

    editDosage(e,drug) {
      hashHistory.push('drugdosage/'+ drug._id)
    }

    deleteRecord(e,drug) {
      if(confirm('Are you sure you want to delete?')){
        this.props.drugAction_binded.deleteDrug(drug)
        .then(()=>{
          this.props.drugAction_binded.getDrugList()
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
          console.log("Error deleting drug!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    getDrugTypeName(drugTypeCode,drugTypeList){
      var drugType = drugTypeList.filter(function( obj ) {
        return obj.code === drugTypeCode;
      });
      return drugType[0].typeName;
    }

    getDosingStatus(drug){
      if(!_.isEmpty(drug.latestDosing)){
          if(drug.latestDosing.published){
            return "Published"
          }else{
            return "Draft"
          }
      }else{
        return "---"
      }
    }

    getDosingEditorInfo(drug){
      if(!_.isEmpty(drug.latestDosing)){
        if(drug.latestDosing.published){
          return "Published by: " + drug.latestDosing.updatedBy.fullName + " on " + moment.utc(drug.latestDosing.updatedDt).local().format("MMM DD, YYYY H:mm A");
        }else{
          if(drug.latestDosing.updatedBy){
            return "Last edited by: " + drug.latestDosing.updatedBy.fullName +" on " + moment.utc(drug.latestDosing.updatedDt).local().format("MMM DD, YYYY H:mm A");
          }else{
            return "Created by: " + drug.latestDosing.createdBy.fullName+ " on " + moment.utc(drug.latestDosing.createdDt).local().format("MMM DD, YYYY H:mm A");
          }
        }

      }else{
        return "---"
      }
    }

    getLastPublished(drug){
      if(!_.isEmpty(drug.latestPublishedDosing)){
          return "Published by: " + (drug.latestPublishedDosing.updatedBy?drug.latestPublishedDosing.updatedBy.fullName:"") + " on " + moment.utc(drug.latestPublishedDosing.updatedDt).local().format("MMM DD, YYYY H:mm A");
      }else{
        return "---"
      }
    }

    getSpecialConsideration(drug){
      if(!_.isEmpty(drug.specialConsiderations)){
        var specialConsideration = ""
        if(drug.specialConsiderations){
          drug.specialConsiderations.forEach(element => {
            if(element == "THERAP_DRUG_MONIT"){
              specialConsideration = specialConsideration + "(TDM)"
            }else if(element == "STANDARDIZED_DOSING"){
              specialConsideration = specialConsideration + "(SD)"
            }else if(element == "ID_CONSULT"){
              specialConsideration = specialConsideration + "(ID)"
            }
          });
        }
        return specialConsideration;
        
      }else{
        return ""
      }
    }

  // createAdminRouteColumnAndDosage(dosingList){
  //     var sortedDosingList = _.sortBy(dosingList, "routeOfAdministration")
  //     return sortedDosingList.map((dosage,index) =>{
  //         var adminRoute = this.props.adminRouteList.filter(function( obj ) {
  //           return obj.code === dosage.routeOfAdministration;
  //         });
  //
  //         var buffer = []
  //         var header = <div className="label label-info">{adminRoute[0].typeName}</div>
  //         var dosingdata = <div key ={index} className="dosagepadding">{createDosingString(dosage,'true',this.props.costLevelList,this.props.doseUnitList,
  //               this.props.ageUnitList,this.props.frequencyUnitList,this.props.durationList,this.props.durationUnitList,
  //               this.props.maxDoseUnitList)}</div>
  //         if(index > 0){
  //           if(sortedDosingList[index-1].routeOfAdministration != dosage.routeOfAdministration){
  //             //buffer.push(<hr></hr>)
  //             buffer.push(header);
  //           }
  //         }
  //         else{
  //           buffer.push(header);
  //         }
  //         buffer.push(dosingdata);
  //         return(buffer);
  //     })
  //   }

    render() {
      const myDrugList = _.orderBy(this.props.drugList, [function (item) { return item.name; }], ["asc"]);

      var editPermission = this.currentUserHasEditPermissions();
      var buttonAdd = null;
      if(editPermission){
        buttonAdd = <input type="button" id="Add" className="btn btn-Info btn-space" value="Add" onClick={()=>{this.props.addClicked(this)}}/>
      }

      return(

          <div>
          {/*ROW 3*/}
          <div className="row">
              <div className="col-md-12">

              <div className="panel panel-default"> {/* panel-default*/}
                  <div className="panel-heading">
                       Drug List{buttonAdd}
                  </div>

                  <div className="panel-body">{/* panel-body*/}
                      <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover" id="dataTables-example">
                              <thead>
                                <tr>
                                  <th className="col-md-2">Drug</th>
                                  <th className="col-md-1">Drug Type</th>
                                  <th className="col-md-2">Last Published Dosing</th>
                                  <th className="col-md-1">Dosing Status</th>
                                  <th className="col-md-2">Dosing</th>
                                  {/*<th>Administration Route & Dosage</th>*/}
                                  <th className="col-md-4"></th>
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    myDrugList && myDrugList.map((drug,index) => {
                                    return(
                                      <tr key={index}>
                                        <td>{drug.name} {this.getSpecialConsideration(drug)}</td>
                                        <td>{this.getDrugTypeName(drug.drugType,this.props.drugTypeList)}</td>
                                        <td className="user-status">{this.getLastPublished(drug)}</td>
                                        <td>{this.getDosingStatus(drug)}</td>
                                        <td className="user-status">{this.getDosingEditorInfo(drug)}</td>
                                        {/*<td>{this.createAdminRouteColumnAndDosage(drug.dosings)}</td>*/}
                                        <td>
                                          { editPermission
                                            ? <span>
                                                <input type="button" className="btn btn-Warning btn-space btn-xs" id="Edit" value="Edit Drug Description" onClick={()=>{this.populateForm(this,drug)}}/>
                                                <input type="button" className="btn btn-Danger btn-space btn-xs" id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,drug)}}/>
                                              </span>
                                            : null
                                          }
                                          <input type="button" className="btn btn-Primary btn-space btn-xs" id="EditDosage" value="Dosing" onClick={()=>{this.editDosage(this,drug)}}/>
                                        </td>
                                      </tr>
                                      )
                                    })
                                  }
                              </tbody>
                            </table>
                        </div>
                    </div>{/* panel-body*/}
                </div> {/* panel-default*/}
              </div>
          </div>
          {/*ROW 3*/}

      </div>
      )
    }
}

function mapDispatchToProps(dispatch){
  return {
    drugAction_binded: bindActionCreators(DrugActions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(DrugsListComponent);
