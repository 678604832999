import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import { Link, browserHistory, hashHistory } from 'react-router';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import BasicField from '../components/common/BasicField';
import BasicFieldOnlyValidation from '../components/common/BasicFieldOnlyValidation';
import BasicSelectField from '../components/common/BasicSelectField';
import * as SMLineItemsActions from '../actions/smlineitems-actions';
import * as configRefApi from '../api/configRef-api';
import * as SMActions from '../actions/suscpetibilityMatrix-actions';
import SMLineItemsListComponent from '../components/SMLineItemsListComponent';
import SMDisplayComponent from '../components/SMDisplayComponent';
import * as BacteriaActions from '../actions/bacteria-actions';
import * as DrugActions from '../actions/drug-actions';
import _ from 'lodash';

class SMLineItemsView extends BaseComponent {

    constructor(props) {
        super(props)
        this.addClicked = this.addClicked.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
        this.state = {
          percentSusceptible: false
         };
         this.handleResistanceValueChange = this.handleResistanceValueChange.bind(this);
         this.onEditSetState = this.onEditSetState.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
            showHideSMPreview();
      //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
      //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.SMActions_binded.getSMById(this.props.params.SMId)
      this.props.bacteriaAction_binded.getBacteriaList()
      this.props.drugAction_binded.getDrugList()
    }

    addClicked(e) {
      //this.props.reset();
      this.state = {
        percentSusceptible: false
       }
      this.props.SMLineItemsAction_binded.resetLineItemInfo(this.props.cultureSourceList,
        this.props.bacteriaList_binded, this.props.drugList_binded,
        this.props.recommendedCategoryList,this.props.resistanceCategoryList)
    }

    cancelClicked(e) {
      this.props.reset();//VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
      hideAddForm();
    }

    onEditSetState(lineItem){
      if(lineItem.resistanceValue!= null){
          if(isNonOtherSelection(lineItem.resistanceValue,this.props.resistanceCategoryList)){
            this.setState({percentSusceptible: false})
          }else{
            this.setState({percentSusceptible: true})
          }
      }else{
        this.setState({percentSusceptible: false})
      }
    }

    onSubmit(formProps) {
      //console.log(formProps)
      this.props.SMLineItemsAction_binded.addOrUpdateSMLineItems(formProps,this.props.params.SMId)
      .then(()=>{
        hideAddForm()
        this.props.reset();
        this.props.SMActions_binded.getSMById(this.props.params.SMId)
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
        console.log("Error submitting susceptibility matrix line item!!",error);
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

    createCultureSourceRadio(cultureSourceList){
        return cultureSourceList.map((cultureSource,index) =>{
            return(
                <div className="radio-inline control-label" key={index}>
                  <label>
                      <Field name="cultureSource" type="radio" component="input" value={cultureSource.code}/> {cultureSource.typeName}
                  </label>
                </div>
            )
        })
    }

    getBacteriaCategoryName(bactCatCode,bacteriaCategoryList){
      var bacteriaCategory = bacteriaCategoryList.filter(function( obj ) {
        return obj.code === bactCatCode;
      });
      return bacteriaCategory[0].typeName;

    }

    createBacteriaDropdown(bacteriaList){
        return bacteriaList.map((bacteria,index) =>{
            return(
                <option key={index} value={bacteria._id}>{bacteria.name} - {this.getBacteriaCategoryName(bacteria.category,this.props.bactCategoryList)}</option>
            )
        })
    }

    createDrugDropdown(drugList){
        return drugList.map((drug,index) =>{
            return(
                <option key={index} value={drug._id}>{drug.name} - {this.getDrugTypeName(drug.drugType,this.props.drugTypeList)}</option>
            )
        })
    }

    getDrugTypeName(drugTypeCode,drugTypeList){
      var drugType = drugTypeList.filter(function( obj ) {
        return obj.code === drugTypeCode;
      });
      return drugType[0].typeName;
    }

    createPatientTypeDropdown(patientTypeList){
      return patientTypeList.map((ptType,index) =>{
          return(
              <option key={index} value={ptType.code}>{ptType.typeName}</option>
          )
      })
    }

    createRecommendedRadio(recommendedCategoryList){
        return recommendedCategoryList.map((recCat,index) =>{
            return(
                <div className="radio-inline control-label" key={index}>
                  <label>
                      <Field name="recommended" type="radio" component="input" value={recCat.code}/> {recCat.typeName}
                  </label>
                </div>
            )
        })
    }

    createResistanceValueDropDown(resistanceCategoryList){
      var buffer =[];
      buffer.push(<option key="rvd1" value="">Select a Value</option>)
       resistanceCategoryList.map((rc,index) =>{
          buffer.push (<option key={index} value={rc.code}>{rc.typeName}</option>)
      })
      return buffer;
    }

    handleResistanceValueChange(event) {
        if(event.target.value == "%SUSCEPTIBLE"){
          this.setState({
            percentSusceptible: true
          })
        }else{
          this.setState({
            percentSusceptible: false
          })
        }
    }


  render() {
      const handleSubmit = this.props.handleSubmit; //injected by reduxform
      const percentSusceptible = this.state.percentSusceptible
      ? <div className="col-md-2 form-no-left-padding form-right-padding"><Field name="percentSusceptible1" type="text" component={BasicFieldOnlyValidation} placeholder="Value"></Field></div>
      : null;

      var publishedOrDraft = "";
      if(this.props.SusMa !=null){
        if(this.props.SusMa.published){
            publishedOrDraft = <span className="space bg-success"> Published </span>
        }else{
            publishedOrDraft = <span className="space purple"> Draft </span>
        }
      }

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                           {this.props.SusMa.name} {publishedOrDraft}
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm*/}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           Susceptibilty Matrix Bug-Drug Combo Information
                           <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                      </div>
                      <div className="panel-body">{/* panel-body*/}
                        <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                          <input type="hidden" name="SMLineItemId" value=""/>
                          <div className="form-group">
                              <label className="col-md-3 control-label">Bacteria*</label>
                              <div className="col-md-6">
                                <Field name="bacteria" component="select" className="form-control">
                                  {this.createBacteriaDropdown(this.props.bacteriaList_binded)}
                                </Field>
                              </div>
                          </div>
                          <div className="form-group">
                              <label className="col-md-3 control-label">Drug*</label>
                              <div className="col-md-6">
                                <Field name="drug" component="select" className="form-control">
                                  {this.createDrugDropdown(this.props.drugList_binded)}
                                </Field>
                              </div>
                          </div>
                          <Field name="isolates" type="text" component={BasicField} placeholder="Isolates" label="Number of Isolates Tested" />

                          <div className="form-group form-bottom-padding">
                              <label className="col-md-3 control-label">Resistance Value*</label>
                              <div className="col-md-7">
                                  <div className="col-md-4 form-no-left-padding form-right-padding">
                                      {/*<Field name="resistanceValue" onChange={ this.handleResistanceValueChange } component="select" className="form-control">*/}
                                      <Field name="resistanceValue" onChange={this.handleResistanceValueChange} component={BasicSelectField}>
                                      {this.createResistanceValueDropDown(this.props.resistanceCategoryList)}
                                    </Field>
                                  </div>
                                  {percentSusceptible}
                              </div>
                          </div>

                          <div className="form-group">
                              <label className="col-md-3 control-label">Culture Source*</label>
                              <div className="col-md-6">
                                {this.createCultureSourceRadio(this.props.cultureSourceList)}
                              </div>
                          </div>
                          {/*}<div className="form-group">
                              <label className="col-md-3 control-label">Patient Type*</label>
                              <div className="col-md-6">
                                <Field name="patientType" component="select" className="form-control">
                                  {this.createPatientTypeDropdown(this.props.patientTypeList)}
                                </Field>
                              </div>
                          </div>*/}
                          <div className="form-group">
                              <label className="col-md-3 control-label">Recommended*</label>
                              <div className="col-md-6">
                                {this.createRecommendedRadio(this.props.recommendedCategoryList)}
                              </div>
                          </div>
                          <div className="form-group">
                             <div className="col-md-offset-3 col-md-9">
                               <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                               {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                               <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                             </div>
                           </div>
                        </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>
           </div>{/* AddForm*/}
          {/*ROW 2*/}

          {/*ROW 3*/}
          <SMDisplayComponent SusMa={this.props.SusMa}/>
          {/*ROW 3*/}

          {/*ROW 4*/}
          <SMLineItemsListComponent SusMa={this.props.SusMa} cultureSourceList={this.props.cultureSourceList} addClicked={this.addClicked}
            bacteriaCategoryList={this.props.bactCategoryList} recommendedCategoryList={this.props.recommendedCategoryList}
            onEditSetState={this.onEditSetState} resistanceCategoryList={this.props.resistanceCategoryList}/>
          {/*ROW 4*/}

      </div>
      )
    }
}

function validateSMLineItemForm(values){
    const errors = {};
    if(!values.resistanceValue){
      errors.resistanceValue = 'Please select a resistance value'
    }
    else if(values.resistanceValue == "%SUSCEPTIBLE"){
      if(!values.percentSusceptible1){
        errors.percentSusceptible1 = 'Please enter % Susceptible value';
      }else if(isNaN(Number(values.percentSusceptible1))){
        errors.percentSusceptible1 = 'Must be a number'
      }
    }

    return errors;
}

function mapStateToProps(state){
    var SM_binded = state.susceptibilityMatrixState.SM
    if(SM_binded==undefined){SM_binded={};}
    var SMLineItem_binded = state.susceptibilityMatrixState.smLineItem

    var cultureSource = {}, cultureSourceList = [],bacteriaCategory = {},bactCategoryList = [],drugType = {},drugTypeList = []
    var recommendedCategory = {}, recommendedCategoryList = [], resistanceCategory={},resistanceCategoryList =[];

    if(state.configRefState.configRef != null){
      cultureSource = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Culture Source";
      });
      cultureSourceList = cultureSource[0].list;

      bacteriaCategory = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Bacteria Category";
      });
      bactCategoryList = bacteriaCategory[0].list;

      drugType = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Drug Category";
      });
      drugTypeList = drugType[0].list;

      recommendedCategory = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "Recommended Category";
      });
      recommendedCategoryList = recommendedCategory[0].list;

      resistanceCategory = state.configRefState.configRef.filter(function( obj ) {
        return obj.name === "SM Resistance Value Category";
      });
      resistanceCategoryList = resistanceCategory[0].list;
    }

    if(SMLineItem_binded != null){

      var bacteriaId
      if(SMLineItem_binded.bacteria != null){
        bacteriaId = SMLineItem_binded.bacteria._id
      }

      var drugId
      if(SMLineItem_binded.drug != null){
        drugId = SMLineItem_binded.drug._id
      }

      var initialValues = {
        SMLineItemId:SMLineItem_binded._id,
        bacteria:bacteriaId,
        drug:drugId,
        isolates:SMLineItem_binded.isolates,
        resistanceValue:SMLineItem_binded.resistanceValue,
        //resistanceCategory:SMLineItem_binded.resistanceCategory,
        cultureSource:SMLineItem_binded.cultureSource,
        recommended:SMLineItem_binded.recommended
      }

      //setting initial vlaues for dose fields
      if(SMLineItem_binded.resistanceValue!= null){
        if(isNonOtherSelection(SMLineItem_binded.resistanceValue,resistanceCategoryList)){
          initialValues.resistanceValue = SMLineItem_binded.resistanceValue
        }else{
          initialValues.resistanceValue = "%SUSCEPTIBLE"
          initialValues.percentSusceptible1 = SMLineItem_binded.resistanceValue
        }
      }
    }

    return{
      SusMa:SM_binded,
      bacteriaList_binded:_.orderBy(state.bacteriaState.bacteriaList, [function (item) { return item.name; }], ["asc"]),
      bactCategoryList:bactCategoryList,
      drugList_binded: _.orderBy(state.drugState.drugList, [function (item) { return item.name; }], ["asc"]),
      drugTypeList:drugTypeList,
      cultureSourceList : cultureSourceList,
      recommendedCategoryList:recommendedCategoryList,
      resistanceCategoryList:resistanceCategoryList,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function isNonOtherSelection(str,list) {
  var ret = false;

  if(str.length === 0){//first option value will be empty string
    ret= true;
  }else{
    for(var i=0; i<list.length -1; i++){//skip the last item as "Other" is ALWAYS the last item in reference service
      if(list[i].code === str){
        ret = true;
        break;
      }
    }
  }

  return ret;
}

function mapDispatchToProps(dispatch){
  return {
    SMLineItemsAction_binded: bindActionCreators(SMLineItemsActions, dispatch),
    SMActions_binded: bindActionCreators(SMActions, dispatch),
    bacteriaAction_binded: bindActionCreators(BacteriaActions, dispatch),
    drugAction_binded: bindActionCreators(DrugActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'SMLineItemForm',  // a unique name for the form
    validate: validateSMLineItemForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(SMLineItemsView));
