import axios from 'axios';

export function getAlertList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'alerts')
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}


export function addAlert(alert) {
  return axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'alert', alert)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function updateAlert(alert, alertId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'alert/' + alertId, alert)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function deleteAlert(alertId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'alert/delete/' + alertId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}
