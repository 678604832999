export const GET_BACTERIALIST_SUCCESS = 'GET_BACTERIALIST_SUCCESS';
export const GET_BACTERIA_SUCCESS= 'GET_BACTERIA_SUCCESS';
export const UPDATE_BACTERIA_SUCCESS= 'UPDATE_BACTERIA_SUCCESS';
export const ADD_BACTERIA_SUCCESS= 'ADD_BACTERIA_SUCCESS';
export const DELETE_BACTERIA_SUCCESS='DELETE_BACTERIA_SUCCESS';


export const GET_DRUGLIST_SUCCESS = 'GET_DRUGLIST_SUCCESS';
export const GET_DRUGBYID_SUCCESS = 'GET_DRUGBYID_SUCCESS';
export const GET_DRUG_SUCCESS= 'GET_DRUG_SUCCESS';
export const UPDATE_DRUG_SUCCESS= 'UPDATE_DRUG_SUCCESS';
export const ADD_DRUG_SUCCESS= 'ADD_DRUG_SUCCESS';
export const DELETE_DRUG_SUCCESS='DELETE_DRUG_SUCCESS';

export const GET_DRUGDOSAGE_SUCCESS = 'GET_DRUGDOSAGE_SUCCESS';
export const UPDATE_DRUGDOSAGE_SUCCESS = 'UPDATE_DRUGDOSAGE_SUCCESS';
export const ADD_DRUGDOSAGE_SUCCESS = 'ADD_DRUGDOSAGE_SUCCESS';
export const DELETE_DRUGDOSAGE_SUCCESS = 'DELETE_DRUGDOSAGE_SUCCESS';
export const GET_DRUGDOSING_SUCCESS = 'GET_DRUGDOSING_SUCCESS';
export const PUBLISH_DRUGDOSING_SUCCESS='PUBLISH_DRUGDOSING_SUCCESS';
export const EDITPUBLISHED_DRUGDOSING_SUCCESS='EDITPUBLISHED_DRUGDOSING_SUCCESS';
export const GETLASTPUBLISHED_DRUGDOSING_SUCCESS='GETLASTPUBLISHED_DRUGDOSING_SUCCESS';
export const SAVE_SORTEDDRUGDOSAGE_SUCCESS='SAVE_SORTEDDRUGDOSAGE_SUCCESS';

export const GET_CONFIGREFERENCE_SUCCESS = 'GET_CONFIGREFERENCE_SUCCESS';

export const GET_DIAGNOSESLIST_SUCCESS = 'GET_DIAGNOSESLIST_SUCCESS';
export const GET_DIAGNOSEBYID_SUCCESS ='GET_DIAGNOSEBYID_SUCCESS'
export const GET_DIAGNOSE_SUCCESS= 'GET_DIAGNOSE_SUCCESS';
export const UPDATE_DIAGNOSE_SUCCESS= 'UPDATE_DIAGNOSE_SUCCESS';
export const ADD_DIAGNOSE_SUCCESS= 'ADD_DIAGNOSE_SUCCESS';
export const DELETE_DIAGNOSE_SUCCESS='DELETE_DIAGNOSE_SUCCESS';

export const GET_DIAGNOSISCARD_SUCCESS = 'GET_DIAGNOSISCARD_SUCCESS';
export const UPDATE_DIAGNOSISCARD_SUCCESS = 'UPDATE_DIAGNOSISCARD_SUCCESS';
export const ADD_DIAGNOSISCARD_SUCCESS = 'ADD_DIAGNOSISCARD_SUCCESS';
export const DELETE_DIAGNOSISCARD_SUCCESS = 'DELETE_DIAGNOSISCARD_SUCCESS';
export const GET_ALL_DIAGNOSISCARDS_SUCCESS = 'GET_ALL_DIAGNOSISCARDS_SUCCESS';
export const GET_CURRENT_DIAGNOSISCARD_SUCCESS = 'GET_CURRENT_DIAGNOSISCARD_SUCCESS';
export const GET_LASTPUBLISHED_DIAGNOSISCARD_SUCCESS = 'GET_LASTPUBLISHED_DIAGNOSISCARD_SUCCESS';
export const PUBLISH_DIAGNOSISCARD_SUCCESS = 'PUBLISH_DIAGNOSISCARD_SUCCESS';
export const EDITPUBLISHED_DIAGNOSISCARD_SUCCESS='EDITPUBLISHED_DIAGNOSISCARD_SUCCESS';

export const GET_SMLIST_SUCCESS = 'GET_SMLIST_SUCCESS';
export const GET_SMLISTRETIRED_SUCCESS = 'GET_SMLISTRETIRED_SUCCESS';
export const GET_SMBYID_SUCCESS = 'GET_SMBYID_SUCCESS';
export const GET_SM_SUCCESS = 'GET_SM_SUCCESS';
export const UPDATE_SM_SUCCESS = 'UPDATE_SM_SUCCESS';
export const ADD_SM_SUCCESS='ADD_SM_SUCCESS';
export const DELETE_SM_SUCCESS='DELETE_SM_SUCCESS';
export const PUBLISH_SM_SUCCESS='PUBLISH_SM_SUCCESS';

export const GET_SMLINEITEM_SUCCESS='GET_SMLINEITEM_SUCCESS';
export const UPDATE_SMLINEITEM_SUCCESS='UPDATE_SMLINEITEM_SUCCESS';
export const ADD_SMLINEITEM_SUCCESS='ADD_SMLINEITEM_SUCCESS';
export const DELETE_SMLINEITEM_SUCCESS='DELETE_SMLINEITEM_SUCCESS';

export const GET_USERLIST_SUCCESS = 'GET_USERLIST_SUCCESS';
export const GET_USER_SUCCESS='GET_USER_SUCCESS';
export const UPDATE_USER_SUCCESS='UPDATE_USER_SUCCESS';
export const ADD_USER_SUCCESS='ADD_USER_SUCCESS';
export const DELETE_USER_SUCCESS='DELETE_USER_SUCCESS';

export const GET_ALERTLIST_SUCCESS='GET_ALERTLIST_SUCCESS';
export const GET_ALERT_SUCCESS='GET_ALERT_SUCCESS';
export const UPDATE_ALERT_SUCCESS='UPDATE_ALERT_SUCCESS';
export const ADD_ALERT_SUCCESS='ADD_ALERT_SUCCESS';
export const DELETE_ALERT_SUCCESS='DELETE_ALERT_SUCCESS';

export const UPDATE_NAV_LOCATION='UPDATE_NAV_LOCATION';
