import React from 'react';

const BasicField = ({input, label, type, placeholder, meta: {touched, error}}) => (
  <div className="form-group">
    <label className="col-md-3 control-label">{label}</label>
    <div className="col-md-6">
    {/* If type=textarea, show textarea else shoe input field*/}
      {type=='textarea' ?
        <textarea {...input} placeholder={placeholder} type={type} className="form-control"/>
        : <input {...input} placeholder={placeholder} type={type} className="form-control"/>}
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
)

export default BasicField
