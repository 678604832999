import React from 'react'
import { Link, browserHistory, hashHistory } from 'react-router'


class BaseComponent extends React.Component {

  componentDidMount() {
    //console.log("BaseComponent didmount")
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("BaseComponent didupdate")
  }

  currentUserHasEditPermissions(){
    var editPermission = false;
    var loggedInUserObj = JSON.parse(sessionStorage.getItem('loggedInUserObject'))
    if(loggedInUserObj && loggedInUserObj.permissions && loggedInUserObj.permissions.length > 0){
      loggedInUserObj.permissions.map((perm,index)=>{
        if(perm === "EDIT"){
          editPermission = true;
        }
      })
    }
    return editPermission;
  }

  currentUserHasPublishPermissions(){
    var publishPermission = false;
    var loggedInUserObj = JSON.parse(sessionStorage.getItem('loggedInUserObject'))
    if(loggedInUserObj && loggedInUserObj.permissions && loggedInUserObj.permissions.length > 0){
      loggedInUserObj.permissions.map((perm,index)=>{
        if(perm === "PUBLISH"){
          publishPermission = true;
        }
      })
    }
    return publishPermission;
  }

  currentUserHasUserMgmtPermissions(){
    var userMgmtPermission = false;
    var loggedInUserObj = JSON.parse(sessionStorage.getItem('loggedInUserObject'))
    if(loggedInUserObj && loggedInUserObj.permissions && loggedInUserObj.permissions.length > 0){
      loggedInUserObj.permissions.map((perm,index)=>{
        if(perm === "USER_MANAGEMENT"){
          userMgmtPermission = true;
        }
      })
    }
    return userMgmtPermission;
  }

}
export default BaseComponent
