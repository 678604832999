import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link, browserHistory, hashHistory } from 'react-router';
import BaseComponent from '../components/common/BaseComponent';
import * as SMActions from '../actions/suscpetibilityMatrix-actions';

class SusceptibilityMatrixListComponent extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){

    }

    populateForm (e,SMObject) {
      this.props.SMActions_binded.loadSMInfo(SMObject)
    }

    editSMLineItems(e,SMObject) {
      hashHistory.push('smlineitem/'+ SMObject._id)
    }

    deleteRecord(e,SMObject) {
      if(confirm('Are you sure you want to delete?')){
        this.props.SMActions_binded.deleteSM(SMObject)
        .then(()=>{
          this.props.SMActions_binded.getSMList()
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
          console.log("Error deleting suscpetibility matrix!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    publishRecord(e,SMObject) {
      if(confirm('Are you sure you want to publish?')){
        this.props.SMActions_binded.publishSM(SMObject)
        .then(()=>{
          this.props.SMActions_binded.getSMList()
          this.props.SMActions_binded.getRetiredSMList()
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
          console.log("Error publishing suscpetibility matrix!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    createButtons(SusMa){
      if(!SusMa.published){
        var buttonPublish = this.currentUserHasPublishPermissions()
            ?  <input type="button" className="btn btn-Success btn-space btn-xs" id="publish" value="Publish" onClick={()=>{this.publishRecord(this,SusMa)}}/>
            : null
        return(
          <div>
            {/*<input type="button" className="btn btn-Danger btn-space btn-xs" id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,SusMa)}}/>*/}
            <input type="button" className="btn btn-Primary btn-space btn-xs" id="EditSMLineItems" value="Bug-Drug Combo" onClick={()=>{this.editSMLineItems(this,SusMa)}}/>
            {buttonPublish}
          </div>
        )
      }else{
        return(
            <input type="button" className="btn btn-Primary btn-space btn-xs" id="View" value="View" onClick={()=>{this.viewPublishedSM(this,SusMa)}}/>
        )
      }
    }

    createEditButtons(SusMa){
      if(!SusMa.published){
        var buttonEditName = this.currentUserHasEditPermissions()
          ? <input type="button" className="btn btn-Warning btn-space btn-xs" id="Edit" value="Edit Name" onClick={()=>{this.populateForm(this,SusMa)}}/>
          : null
        return(
            <span>
              {buttonEditName}
            </span>
        )
      }
    }

    viewPublishedSM(e,SMObject){
      var url = '#/smonlyview/' + String(SMObject._id)
      var win = window.open(url,'LastPublished','height=800,width=1250')
      win.location.reload()
    }

    getSMStatus(published){
      if(published){
        if(this.props.isRetiredList){
          return"Retired"
        }else{
          return "Published"
        }
      }
      else{
        return "Draft"
      }
    }

    getSMEditorInfo(SusMa){
      if(SusMa.published){
        return "Published by: " + SusMa.updatedBy.fullName + " on " + moment.utc(SusMa.updatedDt).local().format("MMM DD, YYYY H:mm A");
      }else{
        if(SusMa.updatedBy){
          return "Last edited by: " + SusMa.updatedBy.fullName +" on " + moment.utc(SusMa.updatedDt).local().format("MMM DD, YYYY H:mm A");
        }else{
          return "Created by: " + SusMa.createdBy.fullName+ " on " + moment.utc(SusMa.createdDt).local().format("MMM DD, YYYY H:mm A");
        }
      }
    }

    render() {
      const SMList = this.props.SMList;

      var buttonAdd = this.currentUserHasEditPermissions()
          ? <input type="button" id="Add" className="btn btn-Info btn-space" value="Create New Version" onClick={()=>{this.props.addClicked(this)}}/>
          : null

      const header = this.props.isRetiredList
          ? <div>
              Retired Susceptibility Matrix List
              <a id="CloseRetiredSM" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
            </div>
          : <div>
              Susceptibility Matrix List
              {buttonAdd}
              <input type="button" id="Retired Versions" className="btn btn-Primary btn-space" value="Show Retired Versions" onClick={()=>{this.props.showRetiredVersions(this)}}/>
            </div>


      return(

          <div>
          {/*ROW 3*/}
          <div className="row">
              <div className="col-md-12">

              <div className="panel panel-default"> {/* panel-default*/}
                  <div className="panel-heading">
                       {header}
                  </div>

                  <div className="panel-body">{/* panel-body*/}
                      <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover" id="dataTables-example">
                              <thead>
                                <tr>
                                  <th className="col-md-3">Name</th>
                                  <th className="col-md-1">Status</th>
                                  <th className="col-md-2">Editor</th>
                                  <th className="col-md-3"></th>
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    SMList && SMList.map((SusMa,index) => {
                                    return(
                                      <tr key={index}>
                                        <td>{SusMa.name} {this.createEditButtons(SusMa)}</td>
                                        <td>{this.getSMStatus(SusMa.published)}</td>
                                        <td className="user-status">{this.getSMEditorInfo(SusMa)}</td>
                                        <td>{this.createButtons(SusMa)}</td>
                                      </tr>
                                      )
                                    })
                                  }
                              </tbody>
                            </table>
                        </div>
                    </div>{/* panel-body*/}
                </div> {/* panel-default*/}
              </div>
          </div>
          {/*ROW 3*/}

      </div>
      )
    }
}

function mapDispatchToProps(dispatch){
  return {
    SMActions_binded: bindActionCreators(SMActions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(SusceptibilityMatrixListComponent);
