import React from  'react';
import PropTypes from  'prop-types';
import {connect} from  'react-redux';
import Header from  './Header';
import Footer from  './Footer';
import NavBar from  './NavBar';

 class App extends React.Component {
  render() {
    return (
        <div id='appHome'>
          <main>
            {this.props.children}{/*any routes from routes.js will render here*/}
          </main>
        </div>
    )
  }

  componentDidMount () {

  }

  componentDidUpdate () {

  }
}

App.propTypes = {
  children: PropTypes.object.isRequired
};

export default App


// <Header/>
// <NavBar/>
//   <main>
//     {this.props.children}
//   </main>
//   <Footer/>



// import React from 'react'
// import PropTypes from 'prop-types';
// import {connect} from 'react-redux';
// import Header from './Header';
// import Footer from './Footer';
// import NavBar from './NavBar';
//
// class App extends React.Component {
//   render() {
//     return (
//       <div>
//
//         <div className="row">
//             <div className="col-lg-12">
//               <Header/>
//             </div>
//         </div>
//
//         <div className="row">
//             <div className="col-lg-12">
//               <NavBar/>
//             </div>
//         </div>
//
//         <div className="row">
//             <div className="col-lg-12">
//               <main>
//                 {this.props.children}
//               </main>
//            </div>
//         </div>
//
//           <div className="row">
//               <div className="col-lg-offset-4">
//                 <Footer/>
//               </div>
//           </div>
//
//       </div>
//     );
//   }
//
//   componentDidMount () {
//
//   }
//
//   componentDidUpdate () {
//
//   }
// }
//
// App.propTypes = {
//   children: PropTypes.object.isRequired
// };
//
// export default App
//
//
// // <Header/>
// // <NavBar/>
// //   <main>
// //     {this.props.children}
// //   </main>
// //   <Footer/>
