export default {
  bacteriaList: [],
  bacteria:{},
  drugList: [],
  drug:{},
  drugDosage:{},
  drugDosing:{},
  configReference:[],
  currentSM:{},
  diagnoseList:[],
  diagnose:{},
  diagnosisCard:{},
  lastPublishedDiagnosisCard:{},
  lastPublishedDrugDosing:{},
  SMList:[],
  SMListRetired:[],
  SM:{},
  smLineItem:{},
  userList: [],
  user:{},
  loggedInUser:{},
  alertList:[],
  alert:{},
  SMBugs:[],
  SMDrugs:[]
}
