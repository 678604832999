import * as types from '../actions/action-types';
import initialState from './initialState';

const diagnoseReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_DIAGNOSESLIST_SUCCESS:
      return {...state, diagnoseList:action.diagnoseList};  //whatever our current state is, add on "diagnosesList"

    case types.GET_DIAGNOSE_SUCCESS:
      return {...state, diagnose:action.diagnose};  //whatever our current state is, add on "diagnose"

    case types.GET_DIAGNOSEBYID_SUCCESS:
        return {...state, diagnose:action.diagnose};  //whatever our current state is, add on "diagnose"

    case types.GET_DIAGNOSISCARD_SUCCESS:
        return {...state, diagnosisCard:action.diagnosisCard};  //whatever our current state is, add on "diagnose"

    case types.GET_ALL_DIAGNOSISCARDS_SUCCESS:
            return {...state, diagnosisCards:action.diagnosisCards};

      case types.GET_CURRENT_DIAGNOSISCARD_SUCCESS:
            return {...state, diagnosisCard:action.diagnosisCard};

    case types.GET_LASTPUBLISHED_DIAGNOSISCARD_SUCCESS:
          return {...state, lastPublishedDiagnosisCard:action.diagnosisCard};
  }
  return state;
}

export default diagnoseReducer
