import axios from 'axios';
import configureStore from '../configureStore'
import { Link, browserHistory, hashHistory } from 'react-router'
const store = configureStore();

export function getUserList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'users')
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}


export function addUser(user) {
  return axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'user', user)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function updateUser(user, userId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'user/' + userId, user)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

export function deleteUser(userId) {
  return axios.put(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'user/delete/' + userId)
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });
}

//get user by id
export function getUserById (userId) {

    //intercept responses before they are handled by then or catch.
    // Add a COMMON response interceptor, to redirect to login on authentication error 401
    axios.interceptors.response.use(function (response) {
        return response;
        }, function (error) {
        // Do something with response error
        if(error.response.status === 401){
          hashHistory.push('logout')
        }else{
          throw(error);
      }
    });

    //sets global header for axios
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('accessToken')
    return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'user/'+userId)
    .then(response => {
      sessionStorage.setItem('loggedInUserObject', JSON.stringify(response.data));
      store.dispatch({type: 'user/loggedInUser', loggedInUser: response.data})
      return response.data;
    }).catch(function (error){
      console.log(error);
      throw (error.response.data);
    });

}

//user login
export function login (username, password) {
  if(username){
    username = username.toUpperCase()
  }
  return new Promise((resolve, reject) => {
    axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'login', {username:username,password:password})
      .then(response => {
        console.log('User Authenticated')
        var userId = String(response.data._id)
        var token = String(response.data.token)
        sessionStorage.setItem('accessToken', token);
        sessionStorage.setItem('loggedInUserId', userId);
        resolve(response.data)
      }).catch(function (error){
        console.log('Authentication failed')
        console.log(error);
        reject(error)
      });
  })
}

//user authentication
export function isAuthenticated (callback) {
    if (callback == null)
        throw("Callback in isAuthenticated() cannot be null");

    var accessToken = sessionStorage.getItem('accessToken')
    console.log('AccessToken: '+ accessToken)
    if(accessToken == null){
        console.log("There is no current session. User needs to login ");
        callback.isLoggedIn(null, false, null);
    }else {
        console.log("Current session found.");
        callback.isLoggedIn(null, true, sessionStorage.getItem('loggedInUserId'));
    }
}

// logout user
export function logout () {
  return new Promise((resolve, reject) => {
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('loggedInUserId');
      sessionStorage.removeItem('loggedInUserObject');
      delete axios.defaults.headers.common["Authorization"]
      resolve();
  })
}
