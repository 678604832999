import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, resetForm, change, arrayPush } from 'redux-form';
import {bindActionCreators} from 'redux';
import BaseComponent from '../components/common/BaseComponent';
import BasicField from '../components/common/BasicField';
import * as SMActions from '../actions/suscpetibilityMatrix-actions';
import * as configRefApi from '../api/configRef-api';
import SusceptibilityMatrixListComponent from '../components/SusceptibilityMatrixListComponent';


class SusceptibilityMatrixView extends BaseComponent {

    constructor(props) {
        super(props)
        this.cancelClicked = this.cancelClicked.bind(this);
        this.addClicked = this.addClicked.bind(this);
        this.showRetiredVersions = this.showRetiredVersions.bind(this);
    }

    componentDidMount(){
      showHideAddForm();
      showHideRetiredSM();
      //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
      //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
      configRefApi.getConfigRefListFromSessionStorage()
      this.props.SMActions_binded.getSMList()
      //this.props.SMActions_binded.getRetiredSMList()
    }

    onSubmit (formProps) {
      // //console.log(formProps)
      this.props.SMActions_binded.addOrUpdateSM(formProps)
      .then(()=>{
        hideAddForm()
        this.props.reset();
        this.props.SMActions_binded.getSMList()
        // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
      })
      .catch((error)=>{
        console.log("Error submitting susceptibility matrix!!", error);
        //this.setState({updateProfileResult: error.message}); //this will cause render to be called
      })
    }

    cancelClicked(e) {
      this.props.reset(); //VAlidation not working after reset OPEN ISSUE- https://github.com/erikras/redux-form/issues/2971
      hideAddForm();
    }

    addClicked(e) {
      //this.props.reset();
      this.props.SMActions_binded.resetSMInfo()
    }

    showRetiredVersions(e){
      this.props.SMActions_binded.getRetiredSMList()
      .then(()=>{
        showRetiredSM();
      })
    }



    render() {

      const mySMList = this.props.SMList_binded;
      const mySMListRetired = this.props.SMListRetired_binded;
      const handleSubmit = this.props.handleSubmit; //injected by reduxform

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                          Susceptibility Matrix <small></small>
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}

              {/*ROW 2*/}
              <div id="AddForm" className="row hideAddForm">{/* AddForm*/}
                <div className="col-md-12">
                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           Susceptibility Matrix Information
                           <a id="CloseAdd" href="javascript:void{}"><i className="fa fa-times actions pull-right"></i></a>
                      </div>
                      <div className="panel-body">{/* panel-body*/}
                        <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                          <input type="hidden" name="SMId" value=""/>
                          <Field name="SMName" type="text" component={BasicField} placeholder="Name" label="Name*" />

                          <div className="form-group">
                             <div className="col-md-offset-3 col-md-9">
                               <input type="submit" className="btn btn-Success btn-space" value="Save"></input>
                               {/*<input type="reset" className="btn btn-default btn-space" value="Reset" onClick={this.props.reset}></input>*/}
                               <input type="button" className="btn btn-default btn-space" value="Cancel" onClick={()=>{this.cancelClicked(this)}}></input>
                             </div>
                           </div>
                        </form>{/* form*/}
                      </div>{/* panel-body*/}
                  </div> {/* panel-default*/}
              </div>
           </div>{/* AddForm*/}
          {/*ROW 2*/}

          {/*ROW 3*/}
          <SusceptibilityMatrixListComponent SMList={mySMList}  isRetiredList={false} addClicked={this.addClicked}
                  showRetiredVersions={this.showRetiredVersions}/>
          {/*ROW 3*/}

          {/*ROW 3*/}
          <div id="RetiredSM" className="hideRetiredSM">
            <SusceptibilityMatrixListComponent SMList={mySMListRetired} isRetiredList={true}/>
          </div>
          {/*ROW 3*/}

      </div>
      )

    }
}

function validateSMForm(values){
  const errors = {};
    if(!values.SMName){
      errors.SMName = 'Please enter a name for susceptibility matrix';
    }

    return errors;
}

function mapStateToProps(state){

    var SM_binded = state.susceptibilityMatrixState.SM
    var initialValues={};

    if(SM_binded != null){
      initialValues = {
        SMId:SM_binded._id,
        SMName:SM_binded.name,
        published: SM_binded.published
      }
    }

    return{
      SMList_binded: state.susceptibilityMatrixState.SMList,
      SMListRetired_binded: state.susceptibilityMatrixState.SMListRetired,
      initialValues: initialValues //this automatically causes REDUX to load the form from state
    }
}

function mapDispatchToProps(dispatch){
  return {
    SMActions_binded: bindActionCreators(SMActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'SMForm',  // a unique name for the form
    validate: validateSMForm, //function to be called for validation
    enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(SusceptibilityMatrixView));
