import React from 'react'
import { Link, browserHistory, hashHistory } from 'react-router'
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../components/common/BaseComponent';
import BasicField from '../../components/common/BasicField';
import BasicFieldOnlyValidation from '../../components/common/BasicFieldOnlyValidation';
import * as userApi from '../../api/user-api'



class Login extends BaseComponent {

  onSubmit (formProps) {
    return userApi.login(formProps.username, formProps.password)
      .then(data => {
          userApi.getUserById(data._id).then((response)=>{
              hashHistory.push('/')
          })
      })
      .catch(e => {
          showAlert("Login Unsuccessful", "failure");
      })
  }

  render () {
    const handleSubmit = this.props.handleSubmit; //injected by reduxform

    return (

      <div className="loginPage">
          <div className="row">{/* Form*/}

            <div id="app-alert" className="alert hideAlert text-center col-md-offset-4 col-md-5">
                <button type="button" className="close">x</button>
                <span><strong id="alert-message"></strong></span>
            </div>

              <div className="col-md-offset-4 col-md-5">

                  <div className="panel panel-default"> {/* panel-default*/}
                      <div className="panel-heading">
                           BCH Antibiogram Login
                      </div>
                      <div className="panel-body">{/* panel-body*/}

                          <form className="form-horizontal form-border" onSubmit = {handleSubmit(this.onSubmit.bind(this))}>{/* form*/}
                              <Field name="username" type="text" component={BasicField} placeholder="CHxxxxx" label="Username" />
                              <Field name="password" type="password" component={BasicField} placeholder="Password" label="Password" />
                              <div className="form-group">
                                 <div className="col-md-offset-4">
                                   <input type="submit" className="btn btn-Success btn-space" value="Login"></input>
                                 </div>
                               </div>
                          </form>{/* form*/}

                       </div>{/* panel-body*/}
                    </div> {/* panel-default*/}

                 </div>

              </div>{/* Form*/}
        </div>
    )
  }
}

function validateLoginForm(values){
  const errors = {};
  if(!values.username){
    errors.username = "Please enter a Username"
  }

  if (!values.password) {
    errors.password = 'Please enter a password'
  }

  return errors;
}

export default reduxForm({
  form: 'LoginForm',
  validate:validateLoginForm,
  enableReinitialize : true  //If your initialValues prop gets updated, your form will update too.
})(Login)
