import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import * as BacteriaActions from '../actions/bacteria-actions';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

class BacteriaListComponent extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){

    }

    populateForm (e,bacteria) {
      this.props.bacteriaAction_binded.loadBacteriaInfo(bacteria)
    }

    deleteRecord(e,bacteria) {
      if(confirm('Are you sure you want to delete?')){
        this.props.bacteriaAction_binded.deleteBacteria(bacteria)
        .then(()=>{
          this.props.bacteriaAction_binded.getBacteriaList()
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
          console.log("Error deleting bacteria!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    getBacteriaCategoryName(bactCatCode,bacteriaCategoryList){
      var bacteriaCategory = bacteriaCategoryList.filter(function( obj ) {
        return obj.code === bactCatCode;
      });
      return bacteriaCategory[0].typeName;

    }

    render() {
      const myBacteriaList = _.orderBy(this.props.bactList, [function (item) { return item.name; }], ["asc"]);;

      var editPermission = this.currentUserHasEditPermissions();
      var buttonAdd = null;
      if(editPermission){
        buttonAdd = <input type="button" id="Add" className="btn btn-Info btn-space" value="Add" onClick={()=>{this.props.addClicked(this)}}/>
      }

      return(

          <div>
          {/*ROW 3*/}
          <div className="row">
              <div className="col-md-12">

              <div className="panel panel-default"> {/* panel-default*/}
                  <div className="panel-heading">
                       Bacteria List{buttonAdd}
                  </div>

                  <div className="panel-body">{/* panel-body*/}
                      <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover" id="dataTables-example">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Type</th>
                                  { editPermission
                                    ? <th></th>
                                    : null
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    myBacteriaList && myBacteriaList.map((bacteria,index) => {
                                    return(
                                      <tr key={index}>
                                        <td className="col-md-3">{bacteria.name}</td>
                                        <td className="col-md-2">{this.getBacteriaCategoryName(bacteria.category,this.props.bacteriaCategoryList)}</td>
                                        { editPermission
                                          ? <td className="col-md-2">
                                              <input type="button" className="btn btn-Warning btn-space btn-xs" id="Edit" value="Edit" onClick={()=>{this.populateForm(this,bacteria)}}/>
                                              <input type="button" className="btn btn-Danger btn-space btn-xs" id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,bacteria)}}/>
                                            </td>
                                          : null
                                        }
                                      </tr>
                                      )
                                    })
                                  }
                              </tbody>
                            </table>
                        </div>
                    </div>{/* panel-body*/}
                </div> {/* panel-default*/}
              </div>
          </div>
          {/*ROW 3*/}

      </div>
      )
    }
}

function mapDispatchToProps(dispatch){
  return {
    bacteriaAction_binded: bindActionCreators(BacteriaActions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(BacteriaListComponent)
