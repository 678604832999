import * as types from '../actions/action-types';
import initialState from './initialState';

const susceptibilityMatrixReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_SMLIST_SUCCESS:
      return {...state, SMList:action.SMList};  //whatever our current state is, add on "SMList"

    case types.GET_SMLISTRETIRED_SUCCESS:
      return {...state, SMListRetired:action.SMListRetired};  //whatever our current state is, add on "GET_SMLISTRETIRED_SUCCESS"

    case types.GET_SM_SUCCESS:
      var drugs = [];
      var bugs = [];
      if(action.SM && action.SM.lineItems){
        action.SM.lineItems.map(lineItem => {
          drugs.push(lineItem.drug)
          bugs.push(lineItem.bacteria)
        })

        drugs = _.uniqBy(drugs, "_id")
        drugs = _.orderBy(drugs, 'name')

        bugs = _.uniqBy(bugs, "_id")
        bugs = _.orderBy(bugs, 'name')
      }
      return {...state, SM:action.SM, SMDrugs:drugs, SMBugs:bugs};  //whatever our current state is, add on "SMList"

    case types.GET_SMBYID_SUCCESS:
      var drugs = [];
      var bugs = [];
      if(action.SM && action.SM.lineItems){
        action.SM.lineItems.map(lineItem => {
          drugs.push(lineItem.drug)
          bugs.push(lineItem.bacteria)
        })

        drugs = _.uniqBy(drugs, "_id")
        drugs = _.orderBy(drugs, 'name')

        bugs = _.uniqBy(bugs, "_id")
        bugs = _.orderBy(bugs, 'name')
      }

      return {...state, SM:action.SM, SMDrugs:drugs, SMBugs:bugs};  //whatever our current state is, add on "SMList"

    case types.GET_SMLINEITEM_SUCCESS:
        return {...state, smLineItem:action.smLineItem};  //whatever our current state is, add on "smLineItem"
  }
  return state;
}

export default susceptibilityMatrixReducer
