import * as ActionTypes from './action-types';
import * as userApi from '../api/user-api';

export function getUserList() {
  return function(dispatch) {
      return userApi.getUserList()
      .then((userList) => {
        dispatch({type: ActionTypes.GET_USERLIST_SUCCESS, userList});
      })
      .catch(error => {
        throw(error);
      })
  }
}


export function loadUserInfo(user) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_USER_SUCCESS, user});
  }
}

export function resetUserInfo() {
  var resetSelectedUser = {role:"",permissions:["VIEW"]}
  return function(dispatch){
    dispatch({type: ActionTypes.GET_USER_SUCCESS, user:resetSelectedUser});
  }
}

export function addOrUpdateUser(formProps,userPermissionsList) {
    if(formProps.userId != null){ //Update
        var userInfo = createUserInfoObject(formProps,userPermissionsList)
        return function(dispatch) {
          return userApi.updateUser(userInfo,formProps.userId)
          .then(updatedUser => {
            dispatch({type: ActionTypes.UPDATE_USER_SUCCESS, updatedUser});
            showAlert("User updated successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
          })
        }
    }
    else //Add
    {
      var userInfo = createUserInfoObject(formProps,userPermissionsList)
      return function(dispatch) {
        return userApi.addUser(userInfo)
        .then(user => {
          dispatch({type: ActionTypes.ADD_USER_SUCCESS, user});
          showAlert("User created successfully!", "success");
        }).catch(error => {
          showAlert(error.error, "error");
          throw(error);
          //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
        })
      }
   }
}

function createUserInfoObject(formProps,userPermissionsList){

  var userInfo={}

  //fullName
  userInfo.fullName = formProps.fullName

  //email
  userInfo.email = formProps.email

  //enterpriseId
  userInfo.enterpriseId = formProps.enterpriseId

  //role
  userInfo.role = formProps.role

  //permissions
  var permissions = []
  userPermissionsList.map((upl,index)=>{
    if(upl.code ==="VIEW"){
      permissions.push(upl.code)
    }
    else if(formProps[upl.code] == true){
        permissions.push(upl.code)
      }
  })
  userInfo.permissions=permissions

  return userInfo;
}

export function deleteUser(user) {
  return function(dispatch) {
    return userApi.deleteUser(user._id).then(res => {
      dispatch({type: ActionTypes.DELETE_USER_SUCCESS, res});
      showAlert("User deleted successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}
