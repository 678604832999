

export function createDosingString(dosing, isAddCost,costLevelList,doseUnitList,ageUnitList,
        frequencyUnitList,durationList,durationUnitList,maxDoseUnitList){

  var dosingStr = "";

  if(dosing.diagnosis){
    dosingStr = dosingStr + dosing.diagnosis.name + ": ";
  }

  if(dosing.prefix){
    dosingStr = dosingStr + dosing.prefix + ": ";
  }

  if(dosing.ageRange){
    if(dosing.ageRange.from && dosing.ageRange.to){
      dosingStr = dosingStr + dosing.ageRange.from.inclusion + " "+ dosing.ageRange.from.age + " "+ getName(dosing.ageRange.from.unit,ageUnitList)
      dosingStr = dosingStr + " to ";
      dosingStr = dosingStr + dosing.ageRange.to.inclusion + " "+ dosing.ageRange.to.age + " "+ getName(dosing.ageRange.to.unit,ageUnitList)
    }
    else if(dosing.ageRange.from){
      dosingStr = dosingStr + dosing.ageRange.from.inclusion + " "+ dosing.ageRange.from.age + " "+ getName(dosing.ageRange.from.unit,ageUnitList)
    }
    else{
      dosingStr = dosingStr + dosing.ageRange.to.inclusion + " "+ dosing.ageRange.to.age + " "+ getName(dosing.ageRange.to.unit,ageUnitList)
    }
    dosingStr = dosingStr + ": "
  }

  if(dosing.dosage){
    dosingStr = dosingStr +  dosing.dosage.dose + " " + getName(dosing.dosage.unit,doseUnitList) + " ";
  }

  if(dosing.frequency){
    dosingStr = dosingStr +  getName(dosing.frequency.unit,frequencyUnitList);
  }

  if(dosing.duration){
    dosingStr = dosingStr + " x " + getName(dosing.duration.time,durationList) + " " +getName(dosing.duration.unit,durationUnitList)
  }

  if(dosing.maxDose){
    dosingStr = dosingStr + " ( " + dosing.maxDose.dose +getName(dosing.maxDose.unit,maxDoseUnitList) + " ) ";
  }

  if(isAddCost == 'true'){
    if(dosing.costLevel){
        dosingStr = dosingStr + " - " + getName(dosing.costLevel,costLevelList);
    }
  }

  return dosingStr;
}


function getName(code,list){
  var entity = list.filter(function( obj ) {
    return obj.code === code;
  });

  if(entity.length > 0){
    return entity[0].typeName;
  }else{
    return code;
  }
}
