import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import * as UserActions from '../actions/user-actions';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

class UserListComponent extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){

    }

    populateForm (e,user) {
      this.props.onEditSetState(user)
      this.props.userAction_binded.loadUserInfo(user)
    }

    deleteRecord(e,user) {
      if(confirm('Are you sure you want to delete?')){
        this.props.userAction_binded.deleteUser(user)
        .then(()=>{
          this.props.userAction_binded.getUserList()
          // this.setState({updateProfileResult: 'User Profile updated successfully!!!'});
        })
        .catch((error)=>{
          console.log("Error deleting user!", error);
          //this.setState({updateProfileResult: error.message}); //this will cause render to be called
        })
      }
    }

    getRoleName(userRoleCode,userRoleList){
      var role = userRoleList.filter(function( obj ) {
        return obj.code === userRoleCode;
      });
      return role[0].typeName;

    }

    getPermissionsName(userPermissions,userPermissionsList){
      var finalPerms =""
      userPermissions.map((upl,index) =>{
        var perm = userPermissionsList.filter(function( obj ) {
          return obj.code === upl;
        });
        finalPerms =  finalPerms + ", " + perm[0].typeName;
      })
      return finalPerms.substring(2,finalPerms.length);
    }

    render() {
      const myUserList = _.orderBy(this.props.userList, [function (item) { return item.email; }], ["asc"]);;

      return(

          <div>
          {/*ROW 3*/}
          <div className="row">
              <div className="col-md-12">

              <div className="panel panel-default"> {/* panel-default*/}
                  <div className="panel-heading">
                       User List<input type="button" id="Add" className="btn btn-Info btn-space" value="Add" onClick={()=>{this.props.addClicked(this)}}/>
                  </div>

                  <div className="panel-body">{/* panel-body*/}
                      <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th className="col-md-2">Full Name</th>
                                  <th className="col-md-1">EnterpriseId</th>
                                  {/*<th>Role</th>*/}
                                  <th className="col-md-2">Permissions</th>
                                  <th className="col-md-2"></th>
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    myUserList && myUserList.map((user,index) => {
                                    return(
                                      <tr key={index}>
                                        <td>{user.fullName}</td>
                                        <td>{user.enterpriseId}</td>
                                        {/*<td className="col-md-2">{this.getRoleName(user.role,this.props.userRoleList)}</td>*/}
                                        <td>{this.getPermissionsName(user.permissions,this.props.userPermissionsList)}</td>
                                        <td>
                                          <input type="button" className="btn btn-Warning btn-space btn-xs" id="Edit" value="Edit" onClick={()=>{this.populateForm(this,user)}}/>
                                          <input type="button" className="btn btn-Danger btn-space btn-xs" id="Delete" value="Delete" onClick={()=>{this.deleteRecord(this,user)}}/>
                                        </td>
                                      </tr>
                                      )
                                    })
                                  }
                              </tbody>
                            </table>
                        </div>
                    </div>{/* panel-body*/}
                </div> {/* panel-default*/}
              </div>
          </div>
          {/*ROW 3*/}

      </div>
      )
    }
}

function mapDispatchToProps(dispatch){
  return {
    userAction_binded: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(UserListComponent)
