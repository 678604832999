import React from 'react'
import { Link, browserHistory, hashHistory } from 'react-router'
import BaseComponent from '../components/common/BaseComponent';

class NavBar extends BaseComponent {

    openPreview = () => {
      var url = process.env.ANTIBIOGRAM_CLIENT_URL
      var win = window.open(url,'Client Preview','height=800,width=1250','resizable=1')
      win.location.reload()
    }

    render() {
        var userMgmtLink =  this.currentUserHasUserMgmtPermissions()
          ?   <li>
                  <Link to="/user"><i className="fa fa-users"></i>Users</Link>
              </li>
        : null

        return (
          <nav className="navbar-default navbar-side" role="navigation">
            {/*/. NAV LEFT  */}
              <div id="sideNav" href=""><i  className="fa fa-caret-right"></i></div>
                  <div  className="sidebar-collapse">
                      <ul  className="nav" id="main-menu">
                          <li>
                              <Link to="/bacteria"><i className="fa fa-users"></i>Bacteria</Link>
                          </li>
                          <li>
                              <Link to="/drugs"><i className="fa fa-users"></i>Drugs</Link>
                          </li>
                          <li>
                              <Link to="/susceptibilitymatrix"><i className="fa fa-users"></i>Susceptibility Matrix</Link>
                          </li>
                          {/* <li>
                              <Link to="/diagnosis"><i className="fa fa-users"></i>Diagnosis</Link>
                          </li>
                          <li>
                              <Link to="/alerts"><i className="fa fa-bell"></i>Alerts</Link>
                          </li> */}
                          {userMgmtLink}
                          <li>
                            <button onClick={this.openPreview} className="btn" style={{margin:"20px"}}>Preview in App</button>
                          </li>
                      </ul>
                  </div>
          </nav>
        );
    }
}

export default NavBar

// className="active-menu"

// /* make sidebar nav vertical */
// @media (min-width: 1200px) {
//   .sidebar-nav .navbar .navbar-collapse {
//     padding: 0;
//     max-height: none;
//   }
//   .sidebar-nav .navbar ul {
//     float: none;
//     display: block;
//   }
//   .sidebar-nav .navbar li {
//     float: none;
//     display: block;
//   }
//   .sidebar-nav .navbar li a {
//     padding-top: 12px;
//     padding-bottom: 12px;
//   }
// }

// <div className="sidebar-nav">
//   <div className="navbar navbar-default" role="navigation">
//     <div className="navbar-header">
//       <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".sidebar-navbar-collapse">
//         <span className="sr-only">Toggle navigation</span>
//         <span className="icon-bar"></span>
//         <span className="icon-bar"></span>
//         <span className="icon-bar"></span>
//       </button>
//       <span className="visible-xs navbar-brand">Sidebar menu</span>
//     </div>
//     <div className="navbar-collapse collapse sidebar-navbar-collapse">
//       <ul className="nav navbar-nav">
//         <li><a href="#">Menu Item 1</a></li>
//         <li><a href="#">Menu Item 2</a></li>
//         <li><a href="#">Menu Item 3</a></li>
//         <li><a href="#">Menu Item 4</a></li>
//         <li><a href="#">Reviews <span className="badge">1,118</span></a></li>
//       </ul>
//     </div>
//   </div>
// </div>
