import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../components/common/BaseComponent';
import {bindActionCreators} from 'redux';
import SMDisplayComponent from '../components/SMDisplayComponent';
import * as SMActions from '../actions/suscpetibilityMatrix-actions';

class SMOnlyView extends BaseComponent{

    constructor(props) {
        super(props)
    }

    componentDidMount(){
      showSMPreview()
      this.props.SMActions_binded.getSMById(this.props.params.SMId)
    }

    listBugNotes(bugs){
      var bugs = [];
      {this.props.SMBugs.map((bug, index) => {
        if(bug.notes){
          bugs.push(bug)
        }
      })}

      if(bugs.length > 0){
        return(
          <ul className="list-group">
            <li className="list-group-item">Notes</li>
            {bugs.map((bug, index) => {
              return(
                <li key={index} className="list-group-item"><span className="badge badge-pill notes-badge">{bug.name}</span><br/>{bug.notes}</li>
              )
            })}
          </ul>

        )
      }
    }

    render() {

      var publishedOrDraft = "";
      if(this.props.SusMa !=null){
        if(this.props.SusMa.published){
          publishedOrDraft = "Published"
        }else{
          publishedOrDraft = "Draft"
        }
      }

      return(

          <div>
              {/*ROW 1*/}
              <div  className="row">
                  <div  className="col-md-12">
                      <h1  className="page-header">
                           {this.props.SusMa.name} <span className="space bg-success">{publishedOrDraft}</span>
                      </h1>
                  </div>
              </div>
              {/*ROW 1*/}


              {/*ROW 2*/}
              <SMDisplayComponent SusMa={this.props.SusMa}/>
              {/*ROW 2*/}
              <div className={"SM-notes mt-3"}>
                {this.listBugNotes(this.props.SMBugs)}
              </div>
          </div>
        )
    }
}



function mapStateToProps(state){
    var SM_binded = state.susceptibilityMatrixState.SM
    if(SM_binded==undefined){
      SM_binded={};
    }

    return{
      SMBugs: state.susceptibilityMatrixState.SMBugs,
      SusMa:SM_binded,
    }
}

function mapDispatchToProps(dispatch){
  return {
    SMActions_binded: bindActionCreators(SMActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SMOnlyView);
