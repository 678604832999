import React from 'react'
import { Link } from 'react-router'

const PageNotFound = (props) => (
  <div>
        <h2 className='title'>Sorry</h2>
        <h3 className='subtitle'>The server is Unreachable</h3>
  </div>
)

export default PageNotFound
