import * as ActionTypes from './action-types';
import * as drugApi from '../api/drug-api';

export function getDrugList() {
  return function(dispatch) {
      return drugApi.getDrugsList()
      .then((drugList) => {
        dispatch({type: ActionTypes.GET_DRUGLIST_SUCCESS, drugList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getDrugById(drugId) {
  return function(dispatch) {
      return drugApi.getDrugById(drugId)
      .then((drug) => {
        dispatch({type: ActionTypes.GET_DRUGBYID_SUCCESS, drug});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function loadDrugInfo(drug) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_DRUG_SUCCESS, drug});
  }
}

export function resetDrugInfo(drugTypeList) {
  var resetSelectedDrug = {drugType:drugTypeList[0].code}
  return function(dispatch){
    dispatch({type: ActionTypes.GET_DRUG_SUCCESS, drug:resetSelectedDrug});
  }
}

export function addOrUpdateDrug(formProps,specialConsiderationList) {
    if(formProps.drugId != null){ //Update
        var arrSynonym = [];
        if(formProps.synonyms != null){
          arrSynonym = formProps.synonyms.split(',');
          // formProps.synonyms.map((synonym) =>{
          //   arrSynonym.push(synonym);
          // })
        }

        var arrSCList = [];
        specialConsiderationList.map((scl,index)=>{
          if(scl.code =="OTHER" && formProps.OTHER == true){
            arrSCList.push(formProps.otherSpecialConsideration1)
          }
          else if(formProps[scl.code] == true){
              arrSCList.push(scl.code)
            }
        })

        var drugInfo = {
          name : formProps.drugName, drugType:formProps.drugType,synonyms:arrSynonym, specialConsiderations:arrSCList
        }

        return function(dispatch) {
          return drugApi.updateDrug(drugInfo,formProps.drugId)
          .then(updatedDrug => {
            dispatch({type: ActionTypes.UPDATE_DRUG_SUCCESS, updatedDrug});
            showAlert("Drug updated successfully!", "success");
          }).catch(error => {
            showAlert(error.error, "error");
            throw(error);
          })
        }
    }
    else //Add
    {
      var arrSynonym = [];
      if(formProps.synonyms != null){
        arrSynonym = formProps.synonyms.split(',');
      }

      var arrSCList = [];
      specialConsiderationList.map((scl,index)=>{
        if(scl.code =="OTHER" && formProps.OTHER == true){
          arrSCList.push(formProps.otherSpecialConsideration1)
        }
        else if(formProps[scl.code] == true){
            arrSCList.push(scl.code)
          }
      })

      var drugInfo = {
        name : formProps.drugName, drugType:formProps.drugType,synonyms:arrSynonym, specialConsiderations:arrSCList
      }

      return function(dispatch) {
        return drugApi.addDrug(drugInfo)
        .then(drug => {
          dispatch({type: ActionTypes.ADD_DRUG_SUCCESS, drug});
          showAlert("Drug created successfully!", "success");
        }).catch(error => {
          showAlert(error.error, "error");
          throw(error);
          //return dispatch({type: ActionTypes.ADD_TASKLIST_FAILURE, errorMessage});
        })
      }
   }
}

export function deleteDrug(drug) {
  return function(dispatch) {
    return drugApi.deleteDrug(drug._id).then(res => {
      //      dispatch({type: ActionTypes.DELETE_TASKLIST_SUCCESS, res, taskListId});
      dispatch({type: ActionTypes.DELETE_DRUG_SUCCESS, res});
      showAlert("Drug deleted successfully!", "success");
    }).catch(error => {
      showAlert(error.error, "error");
      throw(error);
    });
  };
}
