import React from 'react';

const BasicSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
  <div>
    {/*<label>{label}</label>*/}
    <div>
      <select {...input} className="form-control"> {/*className="selectredux">*/}
        {children}
      </select>
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
)
export default BasicSelectField
